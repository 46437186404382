import { Component } from '@angular/core';

@Component({
	selector: 'i-religion',
	template: `
		<svg>
			<symbol id="mosque">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M2550 4767 c0 -9 -6 -26 -12 -37 -9 -14 -10 -22 -1 -31 8 -8 6 -16
					-8 -30 -24 -24 -24 -34 -1 -56 17 -17 17 -19 -10 -45 -36 -33 -35 -53 2 -88
					45 -42 40 -66 -15 -73 -61 -9 -118 -40 -149 -80 l-26 -34 0 -291 0 -292 -30 0
					c-27 0 -30 -3 -30 -30 0 -27 -3 -30 -30 -30 -27 0 -30 3 -30 31 0 27 -3 30
					-27 27 l-28 -3 0 -1680 0 -1680 403 -3 402 -2 0 1675 c0 921 1 1678 3 1683 1
					4 -10 8 -25 10 -25 3 -28 0 -28 -27 0 -28 -3 -31 -30 -31 -27 0 -30 3 -30 30
					0 27 -3 30 -30 30 l-30 0 0 285 c0 229 -3 292 -14 314 -21 41 -85 80 -152 94
					-68 14 -75 30 -31 71 15 14 27 35 27 46 0 30 -18 60 -35 60 -19 0 -19 14 0 30
					19 16 19 44 0 60 -10 8 -12 18 -6 29 6 10 2 28 -10 51 -11 21 -19 28 -19 17z"
					/>
					<path
						d="M1312 1975 c-45 -91 -82 -170 -82 -175 0 -7 138 -9 403 -8 l402 3 3
					173 2 172 -322 0 -323 0 -83 -165z"
					/>
					<path
						d="M3082 1968 l3 -173 403 -3 c221 -1 402 0 402 3 0 3 -38 82 -85 175
					l-85 170 -320 0 -320 0 2 -172z"
					/>
					<path
						d="M445 1570 c-47 -93 -85 -172 -85 -175 0 -3 378 -5 840 -5 l840 0 0
					175 0 175 -755 0 -755 0 -85 -170z"
					/>
					<path
						d="M3080 1565 l0 -175 840 0 c462 0 840 3 840 6 0 3 -39 82 -86 175
					l-86 169 -754 0 -754 0 0 -175z"
					/>
					<path
						d="M10 835 l0 -495 129 0 129 0 6 88 c7 113 7 107 0 177 -5 52 -3 64 18
					95 13 19 40 43 61 53 31 16 42 17 72 7 69 -23 112 -117 86 -191 -7 -22 -8 -45
					0 -77 6 -26 9 -69 8 -97 l-3 -50 104 0 c86 0 104 3 102 14 -12 57 -12 182 1
					229 12 46 12 58 0 95 -31 89 2 191 76 238 53 34 88 36 140 9 75 -38 108 -106
					96 -199 -4 -31 -6 -78 -5 -106 5 -97 5 -230 0 -255 l-5 -25 115 0 115 0 -3 51
					c-4 60 14 199 32 251 12 34 11 44 -7 88 -13 34 -20 74 -20 125 -1 169 112 300
					260 300 97 0 172 -51 225 -153 32 -62 37 -194 10 -265 -19 -49 -19 -56 -5
					-105 8 -28 18 -106 21 -172 l7 -120 130 0 130 0 0 490 0 490 -1012 3 -1013 2
					0 -495z"
					/>
					<path
						d="M3082 838 l3 -493 128 0 128 0 4 100 c1 55 11 131 20 168 17 66 17
					71 -1 130 -38 124 -16 248 59 333 52 60 104 84 182 84 186 0 310 -223 235
					-422 -19 -52 -19 -57 -5 -99 17 -46 26 -119 28 -229 l2 -65 110 -3 110 -3 2
					133 c1 73 2 147 3 163 0 17 -2 62 -6 101 -5 60 -3 77 15 112 52 103 158 129
					237 60 64 -56 87 -139 61 -217 -14 -38 -14 -51 0 -119 13 -61 14 -92 6 -147
					-6 -38 -8 -73 -6 -77 2 -5 48 -8 102 -8 l98 0 7 88 c4 48 6 98 5 112 -11 139
					3 178 74 213 48 24 96 10 135 -41 23 -30 27 -44 25 -94 -4 -90 -4 -107 1 -193
					l5 -80 130 -3 131 -3 0 496 0 495 -1015 0 -1015 0 2 -492z"
					/>
				</g>
			</symbol>
		</svg>
	`,
})
export class ReligionIcons {}
