import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
	NgAsyncDirective,
	NgAttributeDirective,
	NgElementRefDirective,
	NgLetDirective,
	NgTemplateRefDirective,
	SpacingDirective,
} from '.';

const declarations = [
	NgAsyncDirective,
	NgAttributeDirective,
	NgElementRefDirective,
	NgLetDirective,
	NgTemplateRefDirective,
	SpacingDirective,
];

@NgModule({
	imports: [CommonModule],
	exports: [...declarations],
	declarations,
})
export class YslmDirectivesModule {}
