import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { RxState, selectSlice } from '@rx-angular/state';
import { filter, map, tap } from 'rxjs/operators';

import { isNil, isNumeric } from '@yslm/utility';
import { TripOptionPrice, TripOptionType, TripPricing, TripType } from 'src/app/core/models';

// @ Component state
interface ComponentState {
	// – inputs
	tripType: TripType;
	selectedOptionsType: TripOptionType;
	pricings: TripPricing[];
	selectedPricing: TripPricing;
}

@Component({
	selector: 'trip-pricing',
	templateUrl: './trip-pricing.component.html',
	styleUrls: ['./trip-pricing.component.scss'],
	providers: [RxState],
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripPricingComponent implements OnInit {
	// •) enumerations

	readonly TripOptionType = TripOptionType;

	// •) component state

	private readonly INITIAL_STATE: ComponentState = {
		// – inputs
		tripType: undefined,
		selectedOptionsType: null,
		pricings: undefined,
		selectedPricing: undefined,
	};

	readonly viewModel$ = this.componentState.select(
		selectSlice(['tripType', 'selectedPricing']),
		map(({ tripType, selectedPricing }) => ({
			tripType,
			additionals: selectedPricing.additionals,
		}))
	);

	readonly untypedViewModel$ = this.componentState.select(
		selectSlice(['tripType', 'selectedPricing']),
		map(({ tripType, selectedPricing }) => {
			if (!isNil(selectedPricing.type)) return null;

			return {
				type: selectedPricing.type,
				perPers: selectedPricing.perPers,
				prices: selectedPricing.prices,
				hasNightPrices: selectedPricing.prices.some(({ nightFare }) => isNumeric(nightFare)),
			};
		})
	);

	readonly privateViewModel$ = this.componentState.select(
		selectSlice(['tripType', 'selectedPricing']),
		map(({ tripType, selectedPricing }) =>
			selectedPricing.type === TripOptionType.private
				? {
						type: selectedPricing.type,
						perPers: selectedPricing.perPers,
						prices: selectedPricing.prices,
				  }
				: null
		)
	);

	readonly sharedViewModel$ = this.componentState.select(
		selectSlice(['tripType', 'selectedPricing']),
		map(({ tripType, selectedPricing }) => {
			if (selectedPricing.type !== TripOptionType.shared) return null;

			const { fare: sharedFare, persThreshold, extras } = selectedPricing.sharedPrice;
			const { remark, prices } = extras ?? {};

			return {
				type: selectedPricing.type,
				perPers: selectedPricing.perPers,
				sharedFare,
				persThreshold,
				remark,
				prices: prices.map(price => ({
					persInterval: price.persInterval,
					fare: price.fare,
				})),
			};
		})
	);

	// •) outputs & inputs

	/** @required */
	@Input() set tripType(value: TripType) {
		this.componentState.set({ tripType: value });
	}

	/** @optional */
	@Input() set selectedOptionsType(value: TripOptionType) {
		this.componentState.set({ selectedOptionsType: value });
	}

	/** @required */
	@Input() set pricings(value: TripPricing[]) {
		this.componentState.set({ pricings: value });
	}

	// {*} Initialization

	constructor(private componentState: RxState<ComponentState>) {
		this.componentState.set(this.INITIAL_STATE);
	}

	ngOnInit(): void {
		this.connectStates();
	}

	private connectStates() {
		// – [computed] selected pricing
		this.componentState.connect(
			'selectedPricing',
			this.componentState.select(selectSlice(['selectedOptionsType', 'pricings'])),
			({ selectedOptionsType, pricings }) => pricings.find(pricing => pricing.type === selectedOptionsType)
		);
	}
}
