import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'wrap',
})
export class WrapPipe implements PipeTransform {
	transform(param: string | number, surround: string): string;
	transform(param: string | number, prefix: string, suffix: string): string;
	transform(param: string | number, surroundOrPrefix: string, suffix?: string): string {
		if (!surroundOrPrefix) {
			return String(param);
		}

		if (!suffix) {
			return `${surroundOrPrefix}${param}${surroundOrPrefix}`;
		}

		return `${surroundOrPrefix}${param}${suffix}`;
	}
}
