import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RxState, selectSlice } from '@rx-angular/state';
import { map } from 'rxjs/operators';

import { Trip } from 'src/app/core/models';
import { AppState } from 'src/app/core/store';
import { TripActions, TripSelectors } from 'src/app/core/store/trips';

// @ Component state
interface ComponentState {
	// – store states
	trips: Trip[];
}

@Component({
	selector: 'trips-page',
	templateUrl: './trips.component.html',
	styleUrls: ['./trips.component.scss', './trips.component.responsive.scss'],
	providers: [RxState],
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripsPage implements OnInit {
	// •) component state

	readonly viewModel$ = this.componentState.select(
		selectSlice(['trips']),
		map(({ trips }) => ({
			trips: trips.map(trip => ({
				slug: trip.slug,
				type: trip.type,
				title: trip.title,
				detail: trip.detail,
				coverImage: trip.coverImage,
				minFare: trip.minFare(),
			})),
		}))
	);

	// {*} Initialization

	constructor(private readonly store: Store<AppState>, private readonly componentState: RxState<ComponentState>) {}

	ngOnInit(): void {
		// – dispatch initial actions
		this.store.dispatch(TripActions.getAllForTripsPage());

		// – connect & hold streams
		this.connectStates();
	}

	private connectStates() {
		// – connect from store
		this.componentState.connect('trips', this.store.select(TripSelectors.getTrips));
	}
}
