import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { HTMLElementAttributes, HTMLElementStyles } from '@yslm/common/typings';
import { isNil, isString, isUndefined } from '@yslm/utility';

@Injectable()
export class HTMLElementHelper {
	private readonly renderer2: Renderer2;

	constructor(readonly rendererFactory2: RendererFactory2) {
		/**
		 * We cannot inject Renderer2 inside a service,
		 * we can run RendererFactory2 to get Renderer2 instance inside @Injectable() service.
		 *
		 * As emphasized by the current implementation (see dom_renderer.ts in github: angular/angular):
		 * If no (concrete) parameters are passed to RendererFactory2,
		 * it will just return the default renderer without creating a new one.
		 */
		this.renderer2 = rendererFactory2?.createRenderer(null, null);
	}

	// •) has

	hasClass(
		htmlElement: HTMLElement,
		className: string,
		options?: { contains?: boolean; startsWith?: boolean; endsWith?: boolean }
	): boolean {
		const { contains, startsWith, endsWith } = options || {};
		const classArray = this.getClasses(htmlElement);

		if (contains) {
			return classArray.some(klassName => klassName.includes(className));
		} else if (startsWith) {
			return classArray.some(klassName => klassName.startsWith(className));
		} else if (endsWith) {
			return classArray.some(klassName => klassName.endsWith(className));
		}
	}

	hasAttribute(
		htmlElement: HTMLElement,
		attr: string,
		value?: string,
		options?: { contains?: boolean; startsWith?: boolean; endsWith?: boolean }
	): boolean {
		if (isUndefined(value)) {
			return htmlElement.hasAttribute(attr);
		}

		const { contains, startsWith, endsWith } = options || {};

		if (contains) {
			return htmlElement.getAttribute(attr)?.includes(value);
		} else if (startsWith) {
			return htmlElement.getAttribute(attr)?.startsWith(value);
		} else if (endsWith) {
			return htmlElement.getAttribute(attr)?.endsWith(value);
		}

		return htmlElement.getAttribute(attr) === value;
	}

	// •) get

	getClasses(htmlElement: HTMLElement): string[] {
		return htmlElement.className.split(/\s+/);
	}

	getNonNgAttributeNames(htmlElement: HTMLElement): string[] {
		return htmlElement.getAttributeNames().filter(name => !name.startsWith('_ng'));
	}
	getNonNgAttributes(htmlElement: HTMLElement): HTMLElementAttributes {
		return this.getNonNgAttributeNames(htmlElement).reduce(
			(accumulator, attributeName) => ({
				...accumulator,
				[attributeName]: htmlElement.getAttribute(attributeName),
			}),
			{}
		);
	}

	// •) set

	addClasse(htmlElement: HTMLElement, classe: string): void {
		this.renderer2.addClass(htmlElement, classe);
	}
	addClasses(htmlElement: HTMLElement, classes: string[]): void {
		classes.forEach(key => {
			this.renderer2.addClass(htmlElement, key);
		});
	}

	setAttribute(htmlElement: HTMLElement, key: string, value: string): void {
		this.renderer2.setAttribute(htmlElement, key, value);
	}
	setAttributes(htmlElement: HTMLElement, attributes: HTMLElementAttributes): void {
		Object.entries(attributes).forEach(([key, value]) => {
			this.renderer2.setAttribute(htmlElement, key, value);
		});
	}

	setStyle(htmlElement: HTMLElement, key: string, value: number | string): void {
		this.renderer2.setStyle(htmlElement, key, value);
	}
	setStyles(htmlElement: HTMLElement, styles: HTMLElementStyles): void {
		Object.entries(styles).forEach(([key, value]) => {
			this.renderer2.setStyle(htmlElement, key, value);
		});
	}

	// •) remove

	removeClass(htmlElement: HTMLElement, key: string): void {
		this.renderer2.removeClass(htmlElement, key);
	}
	removeClasses(htmlElement: HTMLElement, keys: string[]): void {
		keys.forEach(key => {
			this.renderer2.removeClass(htmlElement, key);
		});
	}

	removeAttribute(htmlElement: HTMLElement, key: string): void {
		this.renderer2.removeAttribute(htmlElement, key);
	}
	removeAttributes(htmlElement: HTMLElement, keys: string[]): void {
		keys.forEach(key => {
			this.renderer2.removeAttribute(htmlElement, key);
		});
	}

	removeStyle(htmlElement: HTMLElement, key: string): void {
		this.renderer2.removeStyle(htmlElement, key);
	}
	removeStyles(htmlElement: HTMLElement, keys: string[]): void {
		keys.forEach(key => {
			this.renderer2.removeStyle(htmlElement, key);
		});
	}

	// •) create

	createElement(tag: string, classNames?: string | string[], styles?: HTMLElementStyles): HTMLElement {
		const htmlElement: HTMLElement = this.renderer2.createElement(tag);

		if (!isNil(classNames)) {
			this.addClasses(htmlElement, isString(classNames) ? classNames.split(/\s+/) : classNames);
		}

		if (!isNil(styles)) {
			this.setStyles(htmlElement, styles);
		}

		return htmlElement;
	}
}
