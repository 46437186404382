import { min as ldashMin } from 'lodash-es';

import { isEmpty } from '../lang/is-empty.utility';
import { isArray } from '../type/reference.utility';

/**
 * Iterates over `vector` returning the the minimum value.
 * If array is falsey or empty, undefined is returned.
 *
 * @param {Array} vector The array to iterate over.
 * @param {Function} predicate The callback invoked at each iteration.
 * @returns The minimum value of the array.
 */
export function min<T>(vector: T[] | null | undefined): T {
	if (!isArray(vector)) {
		throw TypeError('Expected `vector` argument to be an array');
	}

	return !isEmpty(vector) ? ldashMin(vector) : undefined;
}
