import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { YslmCommonModule } from '@yslm/common';

import { TripCardComponent } from './components';

const declarations = [TripCardComponent];

@NgModule({
	imports: [CommonModule, RouterModule, YslmCommonModule],
	exports: [...declarations],
	declarations,
})
export class AppCommonModule {}
