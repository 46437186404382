import { isArray, isNumber, isObject, isUndefined } from '../type';

type Inclusivity = '()' | '[)' | '(]' | '[]';

/**
 * Whether or not value / inner range is within the outer range min and max boundaries.
 *
 * @param valueOrRange
 * @param min
 * @param max
 * @param [inclusivity='()']
 * @returns {boolean}
 */
export function inRange(
	valueOrRange: number | [min: number, max: number] | { min: number; max: number },
	min: number,
	max: number,
	inclusivity?: Inclusivity
): boolean {
	inclusivity ??= '()';

	if (!isNumber(min)) throw new TypeError('Expected min boundary to be a number');
	if (!isNumber(max)) throw new TypeError('Expected max boundary to be a number');
	if (min > max) throw new RangeError('Expected min boundary to be lower than max boundary');

	if (isNumber(valueOrRange)) {
		return (
			(inclusivity[0] === '[' ? min <= valueOrRange : min < valueOrRange) &&
			(inclusivity[1] === ']' ? valueOrRange <= max : valueOrRange < max)
		);
	}

	if (isArray(valueOrRange)) {
		if (valueOrRange.length !== 2) throw new TypeError('Expected inner range array to have exactly two numbers');

		const [rangeMin, rangeMax] = valueOrRange;
		if (!isNumber(rangeMin)) throw new TypeError('Expected inner range min boundary to be a number');
		if (!isNumber(rangeMax)) throw new TypeError('Expected inner range max boundary to be a number');
		if (rangeMin > rangeMax) throw new RangeError('Expected inner range min boundary to be lower than max boundary');

		return (
			(inclusivity[0] === '[' ? min <= rangeMin : min < rangeMin) &&
			(inclusivity[1] === ']' ? rangeMax <= max : rangeMax < max)
		);
	}

	if (isObject(valueOrRange)) {
		if (isUndefined(valueOrRange.min) || isUndefined(valueOrRange.max)) {
			throw new TypeError('Expected inner range object to have min and max properties');
		}

		const [rangeMin, rangeMax] = [valueOrRange.min, valueOrRange.max];
		if (!isNumber(rangeMin)) throw new TypeError('Expected inner range min boundary to be a number');
		if (!isNumber(rangeMax)) throw new TypeError('Expected inner range max boundary to be a number');
		if (rangeMin > rangeMax) throw new RangeError('Expected inner range min boundary to be lower than max boundary');

		return (
			(inclusivity[0] === '[' ? min <= rangeMin : min < rangeMin) &&
			(inclusivity[1] === ']' ? rangeMax <= max : rangeMax < max)
		);
	}

	throw new TypeError('Expected argument to be a number, a range array or a range object');
}
