import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import { HTMLElementHelper } from '@yslm/helpers/html-element';
import { isNil } from '@yslm/utility';
import { coerceBoolean } from '@yslm/utility/coercion';

const DEFAULT_SPACING = 'base';
const CONTAINER_TAGS = ['page', 'segment'];

@Directive({
	selector: '[spacing], [margin], [padding]',
})
export class SpacingDirective implements OnInit {
	@Input() set spacing(value: { [key in 'm' | 'p']?: string }) {
		if (isNil(value)) {
			this.cleanupTag();
			return;
		}

		this.addSpacingClasses(value);
	}

	@Input() set margin(value: string) {
		if (isNil(value)) {
			this.cleanupTag();
			return;
		}

		this.addSpacingClasses({ m: value });
	}

	@Input() set padding(value: string) {
		if (isNil(value)) {
			this.cleanupTag();
			return;
		}

		this.addSpacingClasses({ p: value });
	}

	constructor(
		public elementRef: ElementRef<HTMLElement>,
		public renderer2: Renderer2,
		public htmlElementHelper: HTMLElementHelper
	) {}

	ngOnInit(): void {
		if (this.spacing) {
			this.addSpacingClasses(this.spacing);
		}
		if (coerceBoolean(this.margin)) {
			this.addSpacingClasses({ m: this.margin });
		}
		if (coerceBoolean(this.padding)) {
			this.addSpacingClasses({ p: this.padding });
		}
		this.cleanupTag();
	}

	/**
	 * Add spacing classes to the HTMLElement hosting the directive
	 *
	 * @param inputs
	 * e.g. {m: 't:1x, r|l:3x:!last', p: 'children:3x'}
	 */
	addSpacingClasses(inputs: Partial<{ [key in 'm' | 'p']: string }>) {
		/**
		 * @member {Record<string, any>} regString
		 * ↪ children : should value apply to children?
		 * ↪ sides    : property-sides the spacing value should be applied to
		 * ↪ value    : spacing value to apply
		 * ↪ exclLast : should exclude last selected element?
		 */
		const regStrings = {
			children: 'children',
			sides: '[t|b|r|l|v|h](|[t|b|r|l|v|h])*',
			value: '[1-9a-z]+',
			exclLast: '!last',
		};

		const childrenSubRegex = `((${regStrings.children}):?)`;
		const sidesSubRegex = `((${regStrings.sides}):)`;
		const valueSubRegex = `(${regStrings.value})`;
		const exclLastSubRegex = `(:(${regStrings.exclLast}))`;

		const spacingRegExp = new RegExp(`${childrenSubRegex}?${sidesSubRegex}?${valueSubRegex}?${exclLastSubRegex}?`);

		Object.keys(inputs).forEach(spacingType => {
			inputs[spacingType].split(/\s*[,;]\s*/g).forEach(input => {
				input.match(spacingRegExp);
				const matchResult = {
					children: RegExp.$2 === regStrings.children ? regStrings.children : '',
					sides: RegExp.$4,
					value: RegExp.$6,
					exclLast: RegExp.$8 === regStrings.exclLast ? regStrings.exclLast : '',
				};

				const tagName = this.elementRef.nativeElement.tagName.toLowerCase();
				if (!matchResult.value) {
					if (!CONTAINER_TAGS.find(name => name === tagName)) {
						matchResult.value = DEFAULT_SPACING;
					} else {
						matchResult.value = !matchResult.children
							? tagName
							: CONTAINER_TAGS[CONTAINER_TAGS.indexOf(tagName) + 1] ?? DEFAULT_SPACING;
					}
				}

				matchResult.sides.split('|').forEach(side => {
					this.renderer2.addClass(
						this.elementRef.nativeElement,
						`${[matchResult.children, spacingType, side, matchResult.value, matchResult.exclLast]
							.filter(Boolean)
							.join(':')}`
					);
				});
			});
		});
	}

	private cleanupTag() {
		this.htmlElementHelper.removeAttributes(this.elementRef.nativeElement, ['margin', 'padding', 'spacing']);
	}
}
