import { Component } from '@angular/core';

@Component({
	selector: 'i-interfaces',
	template: `
		<svg>
			<symbol id="bars">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M2355 5114 c-589 -21 -1081 -85 -1320 -173 -74 -28 -203 -90 -223
					-107 -9 -8 -47 -36 -83 -62 -133 -94 -200 -155 -268 -246 -158 -209 -251 -386
					-307 -578 -63 -221 -121 -594 -144 -926 -13 -195 -13 -753 0 -942 30 -433 112
					-855 210 -1083 77 -179 254 -432 372 -534 120 -104 299 -218 401 -257 514
					-197 1726 -255 2612 -126 435 64 635 144 900 364 100 83 233 250 306 386 13
					25 27 47 31 50 12 10 79 162 104 240 72 218 136 604 163 992 16 217 14 750 -4
					976 -35 449 -115 842 -216 1059 -22 48 -43 90 -47 93 -4 3 -13 18 -20 35 -14
					29 -86 140 -162 247 -44 61 -195 197 -302 271 -177 122 -369 190 -688 241
					-347 56 -946 93 -1315 80z m1283 -1651 l3 -153 -1076 0 -1075 0 0 154 0 153
					1073 -1 1072 -1 3 -152z m2 -883 l0 -150 -1075 0 -1075 0 0 150 0 150 1075 0
					1075 0 0 -150z m0 -919 c0 -134 -2 -150 -17 -155 -10 -2 -494 -4 -1075 -4
					l-1058 1 0 154 0 153 1075 0 1075 0 0 -149z"
					/>
				</g>
			</symbol>

			<symbol id="information">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M2280 5099 c-278 -29 -573 -115 -845 -249 -787 -385 -1320 -1148
					-1415 -2022 -13 -125 -13 -411 0 -536 111 -1023 820 -1879 1803 -2176 472
					-143 1003 -142 1477 1 566 171 1059 539 1391 1038 559 840 560 1959 4 2804
					-422 642 -1120 1067 -1874 1141 -121 12 -419 11 -541 -1z m590 -454 c442 -66
					861 -278 1178 -594 640 -640 801 -1613 401 -2420 -523 -1056 -1795 -1481
					-2841 -949 -613 311 -1030 891 -1135 1578 -11 78 -17 175 -17 300 0 207 17
					345 64 526 192 742 772 1322 1514 1514 101 26 259 53 376 64 84 8 355 -4 460
					-19z"
					/>
					<path
						d="M2513 4305 c-254 -55 -412 -315 -347 -569 41 -160 170 -289 329 -331
					154 -40 304 0 425 112 191 176 196 480 13 661 -71 70 -141 108 -230 127 -82
					18 -109 18 -190 0z"
					/>
					<path
						d="M1794 3176 c-69 -30 -94 -107 -53 -160 34 -44 93 -67 192 -76 133
					-13 169 -48 170 -164 0 -55 -57 -395 -174 -1041 -66 -365 -68 -476 -12 -592
					37 -77 147 -192 221 -231 113 -60 264 -80 472 -63 214 19 373 83 531 214 116
					98 154 182 109 240 -34 43 -74 37 -198 -29 -91 -49 -115 -57 -171 -61 -84 -5
					-123 16 -153 84 -19 42 -20 60 -15 173 3 73 14 159 26 208 12 46 48 235 81
					420 33 185 83 454 111 597 41 207 50 272 45 318 -7 72 -37 132 -79 157 -31 19
					-53 20 -552 20 -413 -1 -526 -4 -551 -14z"
					/>
				</g>
			</symbol>
		</svg>
	`,
})
export class InterfacesIcons {}
