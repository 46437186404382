import { Pipe, PipeTransform } from '@angular/core';

import { get } from '@yslm/utility';

@Pipe({ name: 'get' })
export class GetPipe implements PipeTransform {
	transform<T extends { [key: string]: any }>(record: T, path: string | string[], metadata?: { fallback: any }) {
		const { fallback } = metadata ?? {};
		return get(record, path, fallback);
	}
}
