import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TripsApiService } from './api/trips-api.service';
import { AppStoreModule } from './store/app-store.module';

@NgModule({
	imports: [CommonModule, AppStoreModule],
	exports: [AppStoreModule],
	providers: [TripsApiService],
})
export class AppCoreModule {}
