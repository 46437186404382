import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
	ArrayFilterPipe,
	ArrayFindPipe,
	ArrayJoinPipe,
	ArrayMapPipe,
} from './array';
import {
	NumberAbsolutePipe,
	NumberSignPipe,
	NumberSignedPipe,
} from './number';
import {
	ObjectGetPipe,
	ObjectKeysPipe,
} from './object';
import {
	ObservableDelayPipe,
} from './observable';
import {
	StringParseTemplatingPipe,
	StringPluralizePipe,
	StringPrefixPipe,
	StringSlugifyPipe,
	StringStringifyPipe,
	StringSuffixPipe,
	StringUnSlugifyPipe,
	StringWrapPipe,
} from './string';
import {
	UtilityFallbackPipe,
	UtilityIfDefinedPipe,
	UtilityIfPipe,
	UtilityIsExistyPipe,
	UtilityIsPipe,
	UtilityIsntExistyPipe,
	UtilityIsntPipe,
	UtilityOfTypePipe,
	UtilityRangePipe,
} from './utility';

const declarations = [
	// • array
	ArrayFilterPipe,
	ArrayFindPipe,
	ArrayMapPipe,
	ArrayJoinPipe,

	// • number
	NumberAbsolutePipe,
	NumberSignPipe,
	NumberSignedPipe,

	// • object
	ObjectGetPipe,
	ObjectKeysPipe,

	// • observable
	ObservableDelayPipe,

	// • string
	StringParseTemplatingPipe,
	StringPluralizePipe,
	StringPrefixPipe,
	StringSlugifyPipe,
	StringStringifyPipe,
	StringSuffixPipe,
	StringUnSlugifyPipe,
	StringWrapPipe,

	// • utility
	UtilityFallbackPipe,
	UtilityIfDefinedPipe,
	UtilityIfPipe,
	UtilityIsExistyPipe,
	UtilityIsPipe,
	UtilityIsntExistyPipe,
	UtilityIsntPipe,
	UtilityOfTypePipe,
	UtilityRangePipe,
];

@NgModule({
	imports: [CommonModule],
	exports: [...declarations],
	declarations,
})
export class YslmPipesModule {}
