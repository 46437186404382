<div *ngIf="viewModel$ | async as $" class="option__pricing">
	<!-- optionType === null -->

	<div *ngIf="untypedViewModel$ | async as untyped$" class="pricing--untyped">
		<ng-container
			*ngTemplateOutlet="pricingTable; context: {
				$implicit: untyped$.prices,
				tripType: $.tripType,
				perPers: untyped$.perPers,
				hasNightPrices: untyped$.hasNightPrices
			}"
		></ng-container>

		<div *ngIf="$.additionals | isnt:'empty'" class="pricing__additionals">
			<ng-container
				*ngTemplateOutlet="pricingAdditionals; context: {
					$implicit: $.additionals,
					tripType: $.tripType,
					perPers: untyped$.perPers,
					signed: true
				}"
			></ng-container>
		</div>
	</div>

	<!-- optionType === TripOptionType.private -->

	<div *ngIf="privateViewModel$ | async as private$" class="pricing--private">
		<ng-container
			*ngTemplateOutlet="pricingTable; context: {
				$implicit: private$.prices,
				tripType: $.tripType,
				perPers: private$.perPers
			}"
		></ng-container>

		<div *ngIf="$.additionals | isnt:'empty'" class="pricing__additionals">
			<ng-container
				*ngTemplateOutlet="pricingAdditionals; context: {
						$implicit: $.additionals,
						tripType: $.tripType,
						perPers: private$.perPers,
						signed: true
					}"
			></ng-container>
		</div>
	</div>

	<!-- optionType === TripOptionType.shared -->

	<div *ngIf="sharedViewModel$ | async as shared$" class="pricing--shared">
		<div class="pricing__value" margin="b:1x">
			<span class="value" margin="r:x2" size="2x">{{ shared$.sharedFare }} € / <svg-use name="person"></svg-use></span>
			<!-- <span class="remark">
				{{ '{' }} ≥ {{ shared$.persThreshold }} people sharing the {{ $.tripType }} {{ '}' }}
				<span class="u-text-superscript u-font-bold" color="danger">(*)</span>
			</span> -->
		</div>

		<!-- <div *ngIf="shared$.prices | isnt:'empty'" class="pricing__extras">
			<p class="extra__remark" color="danger" innerHTML="{{ shared$.remark | parseTemplating }}"></p>
			<ng-container
				*ngTemplateOutlet="pricingTable; context: {
					$implicit: shared$.prices,
					tripType: $.tripType,
					perPers: shared$.perPers
				}"
			></ng-container>
		</div> -->

		<div *ngIf="$.additionals | isnt:'empty'" class="pricing__additionals">
			<ng-container
				*ngTemplateOutlet="pricingAdditionals; context: {
						$implicit: $.additionals,
						tripType: $.tripType,
						perPers: shared$.perPers,
						signed: true
					}"
			></ng-container>
		</div>
	</div>
</div>

<!-- @ templating -->

<!-- pricing table -->

<ng-template
	#pricingTable
	let-prices
	let-hasNightPrices="hasNightPrices"
	let-tripType="tripType"
	let-perPers="perPers"
	let-signed="signed"
>
	<table class="pricing__table">
		<tr>
			<th *ngIf="hasNightPrices" class="empty"></th>
			<th *ngFor="let price of prices; let isFirst = first; let isLast = last">
				<ng-container *ngIf="price.persInterval as interval">
					<ng-container *ngIf="(interval.min | isnt:'nil')">
						<span *ngIf="interval.min === interval.max">Only </span>
						<span *ngIf="(interval.max | is:'nil')">≥ </span>

						<span *ngIf="interval.min === interval.max">{{ interval.min }} </span>
						<span *ngIf="(interval.max | is:'nil')">{{ interval.min }} </span>
						<span *ngIf="interval.min < interval.max">{{ interval.min }} – {{ interval.max }} </span>

						<svg-use name="person"></svg-use>

						<span *ngIf="interval.max === 1"> alone in the car</span>
						<span *ngIf="interval.max > 1"> sharing the car</span>
						<span *ngIf="interval.min > 1 && (interval.max | is:'nil')"> sharing the car</span>
					</ng-container>
				</ng-container>
			</th>
		</tr>

		<tr>
			<td *ngIf="hasNightPrices" class="heading">Day fare</td>
			<td *ngFor="let price of prices">
				<span *ngIf="!signed">{{ price.fare }} €</span>
				<span *ngIf="signed">{{ price.fare | signed: { addSpace: true, zeroSign: '+' } }} €</span>

				<span *ngIf="perPers"> / <svg-use name="person"></svg-use></span>
			</td>
		</tr>

		<tr *ngIf="hasNightPrices">
			<td *ngIf="hasNightPrices" class="heading">Night fare</td>

			<td *ngFor="let price of prices">
				<span *ngIf="!signed">{{ price.nightFare }} €</span>
				<span *ngIf="signed">{{ price.nightFare | signed: { addSpace: true, zeroSign: '+' } }} €</span>

				<span *ngIf="perPers"> / <svg-use name="person"></svg-use></span>
			</td>
		</tr>
	</table>
</ng-template>

<!-- additionals -->

<ng-template #pricingAdditionals let-additionals let-tripType="tripType" let-perPers="perPers" let-signed="signed">
	<div class="additionals__heading u-heading" margin="t:2x">Additionals</div>

	<div *ngFor="let additional of additionals; let isLast = last" margin="b:1x:!last">
		<p clas="additional__remark" innerHTML="{{ additional.remark | parseTemplating }}"></p>
		<ng-container
			*ngTemplateOutlet="pricingTable; context: {
				$implicit: additional.prices,
				tripType: tripType,
				perPers: perPers,
				signed: true
			}"
		></ng-container>
	</div>
</ng-template>
