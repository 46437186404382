import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RxState, selectSlice } from '@rx-angular/state';

// @ Component state
interface ComponentState {
	currentYear: number;
}

@Component({
	selector: 'footer-layout',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	providers: [RxState],
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
	// •) component state

	private readonly INITIAL_STATE: ComponentState = {
		currentYear: new Date().getFullYear(),
	};

	// • view states

	readonly viewModel$ = this.componentState.select(selectSlice(['currentYear']));

	// {*} Initialization

	constructor(private readonly componentState: RxState<ComponentState>) {
		this.componentState.set(this.INITIAL_STATE);
	}
}
