<ng-container *ngIf="viewModel$ | async as $">
	<div class="header header__layout" [ngClass]="{ 'is-home-page': $.isHomePage }">
		<div class="header__top">
			<div class="left-side">
				<div class="follow-us">
					<span margin="r:1x">Follow us:</span>
					<a href="https://www.facebook.com/" margin="r:1x">
						<svg-use name="social-network-facebook"></svg-use>
					</a>
					<a href="https://www.instagram.com/">
						<svg-use name="social-network-instagram"></svg-use>
					</a>
				</div>
			</div>

			<div class="right-side">
				<div class="contact-us">
					<svg-use name="phone" margin="r:x2"></svg-use>
					<span>=</span>
					<svg-use name="social-network-whatsapp" margin="l|r:x2"></svg-use>
					<span>=</span>
					<span margin="l:x2">(+212) 6 63 68 88 47</span>
				</div>
			</div>
		</div>

		<div class="header__middle">
			<div class="logo-box u-inline-block">
				<img class="agency-logo" alt="agency-logo" src="/assets/logo/agency-logo.png" />
			</div>
			<div class="left-side">
				<span nav-menu class="nav-menu">
					<a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Home</a>
					<a routerLink="/about" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">About</a>
					<a routerLink="/trips" routerLinkActive="active">Trips</a>
					<!-- <a name="contact" routerLink="/contact" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
						Contact
					</a> -->
				</span>
				<!-- <yslm-dropdown-img class="languages-menu" [options]="options"></yslm-dropdown-img> -->
			</div>
		</div>

		<div *ngIf="$.isHomePage" class="header__bottom">
			<img alt="banner" src="/assets/images/wallpaper.png" />
		</div>
	</div>
</ng-container>
