import { createReducer, on } from '@ngrx/store';

import * as TripActions from './trip.actions';
import { initialTripState } from './trip.state';

export const tripReducer = createReducer(
	initialTripState,

	on(TripActions.getAllForHomePage, TripActions.getAllForTripsPage, TripActions.getOneForTripPage, state => ({
		...state,
		loader: { pending: true, error: undefined },
	})),

	on(TripActions.getAllSuccess, (state, { payload }) => ({
		...state,
		loader: { pending: false, error: undefined },
		trips: payload.trips,
	})),

	on(TripActions.getOneSuccess, (state, { payload }) => ({
		...state,
		loader: { pending: false, error: undefined },
		trip: payload.trip,
	})),

	on(TripActions.getAllFailure, TripActions.getOneFailure, (state, { payload }) => ({
		...state,
		loader: { pending: false, error: payload.error },
	}))
);
