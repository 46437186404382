import { ElementRef, Injectable } from '@angular/core';

import { MediaQueryHelper } from '@yslm/helpers';

import { CarouselDimensions } from './carousel.model';

interface CarouselComputingParams {
	componentContainer: ElementRef<HTMLElement>;
	carouselItemsCount: number;
	itemsCountPerSlide: number;
	slidingItemsCount: number;
	itemGutter: number;
	hasBorders: boolean;
	currentSlideIndex: number;
}

@Injectable()
export class CarouselService {
	constructor(private mediaQueryHelper: MediaQueryHelper) {}

	// {+} Public methods
	computeDimensions(carouselParams: CarouselComputingParams): CarouselDimensions {
		const {
			componentContainer,
			carouselItemsCount,
			itemsCountPerSlide,
			itemGutter,
			slidingItemsCount,
			currentSlideIndex,
			hasBorders,
		} = carouselParams;

		const carouselNativeWidth = this.computeCarouselNativeWidth(componentContainer);

		// ↓↓↓ Carousel item
		const itemWidth = this.computeItemWidth(carouselNativeWidth, itemsCountPerSlide, itemGutter, hasBorders);

		// ↓↓↓ Carousel slide
		const slidesCount = this.computeSlidesCount(carouselItemsCount, itemsCountPerSlide, slidingItemsCount);
		const slideGutter = this.computeSlideGutter(itemsCountPerSlide, itemGutter, hasBorders);
		const slideWidth = this.computeSlideWidth(itemsCountPerSlide, itemWidth);

		// ↓↓↓ Carousel sliding
		const slidingWidth = this.computeSlidingWidth(itemWidth, itemGutter, slidingItemsCount);
		const slidingOffset = this.computeSlidingOffset(itemWidth, currentSlideIndex);

		// ↓↓↓ Carousel items container
		const itemsContainerWidth = this.computeItemsContainerWidth(
			carouselItemsCount,
			itemsCountPerSlide,
			slideGutter,
			slideWidth
		);

		return {
			hasBorders,
			carouselNativeWidth,
			carouselItemsCount,
			itemsCountPerSlide,
			itemGutter,
			itemWidth,
			slidesCount,
			slideGutter,
			slideWidth,
			slidingItemsCount,
			slidingWidth,
			slidingOffset,
			itemsContainerWidth,
		};
	}

	// {-} Private methods

	// ↓↓↓ Carousel
	private computeCarouselNativeWidth(componentContainer: ElementRef<HTMLElement>): number {
		return componentContainer.nativeElement.getBoundingClientRect().width;
	}

	// ↓↓↓ Carousel items
	private computeItemWidth(
		carouselNativeWidth: number,
		itemsCountPerSlide: number,
		itemGutter: number,
		hasBorders?: boolean
	): number {
		// `(carouselNativeWidth / itemsCountPerSlide)` ; item width per slide, regardless to the gutter
		const carouselItemWidth = Math.round(carouselNativeWidth / itemsCountPerSlide);

		/**
		 * • `- ((1/2 + 1/2) * itemGutter) / itemsCountPerSlide`
		 *  ↪ the gutter is not being added to the first & last item per slide, and must be subtracted
		 *  ↪ `/ itemsCountPerSlide` ; we subtract a fraction from each item of the slide
		 */
		if (!hasBorders) {
			return carouselItemWidth - itemGutter - ((1 / 2 + 1 / 2) * itemGutter) / itemsCountPerSlide;
		}

		// `- itemGutter` ; subtract the gutter from the item's width
		return carouselItemWidth - itemGutter;
	}

	// ↓↓↓ Carousel slides
	private computeSlidesCount(
		carouselItemsCount: number,
		itemsCountPerSlide: number,
		slidingItemsCount: number
	): number {
		/**
		 * • `- (itemsCountPerSlide - 1)`
		 *  ↪ When `itemsCountPerSlide` is > 1, sliding must stop once the last carousel-item becomes visible
		 *  ↪ The last carousel-item becomes visible when the first item of the last slide becomes visible
		 *  ↪ `- (itemsCountPerSlide - 1)` ; We subtract the last-slide's items from the count, except the first
		 */
		return Math.ceil(carouselItemsCount / slidingItemsCount) - (itemsCountPerSlide - 1);
	}
	private computeSlideGutter(itemsCountPerSlide: number, itemGutter: number, hasBorders?: boolean): number {
		if (!hasBorders) {
			/**
			 * • `(itemsCountPerSlide * itemGutter - (1/2 + 1/2))`
			 *  ↪ subtract the gutter related to the first & last item per slide
			 */
			return itemsCountPerSlide * itemGutter - (1 / 2 + 1 / 2) * itemGutter;
		}

		return itemsCountPerSlide * itemGutter;
	}
	private computeSlideWidth(itemsCountPerSlide: number, itemWidth: number): number {
		return itemsCountPerSlide * itemWidth;
	}

	// ↓↓↓ Carousel items container
	private computeItemsContainerWidth(
		carouselItemsCount,
		itemsCountPerSlide,
		slideGutter: number,
		slideWidth: number
	): number {
		// ~ `computedSlideWidth * computedSlidesCount`
		return (slideWidth + slideGutter) * Math.ceil(carouselItemsCount / itemsCountPerSlide);

		// @ToBeRemoved
		// return computedSlideWidth * Math.ceil(this.carouselSlidesTmplRef.length / this.itemsCountPerSlide);
	}

	// ↓↓↓ Carousel sliding
	private computeSlidingWidth(itemWidth: number, itemGutter: number, slidingItemsCount: number): number {
		return (itemWidth + itemGutter) * slidingItemsCount;
	}
	private computeSlidingOffset(itemWidth: number, currentSlideIndex: number): number {
		return itemWidth * currentSlideIndex;
	}
}
