import { Pipe, PipeTransform } from '@angular/core';

import { isString } from '@yslm/utility/type';

@Pipe({
	name: 'parseTemplating',
})
export class ParseTemplatingPipe implements PipeTransform {
	private replacements = [{ symbol: '\n', expression: '<br/>' }];
	private decorations = [
		{ symbol: '{b}', class: 'u-font-bold' },
		{ symbol: '{i}', class: 'u-font-italic' },
		{ symbol: '{u}', class: 'u-text-underlined' },
		{ symbol: '{sup}', class: 'u-text-superscript' },
		{ symbol: '{sub}', class: 'u-text-subscript' },
	];

	transform(param: string): string {
		if (isString(param) && param.length > 0) {
			param = this.doReplacements(param);
			param = this.doDecorations(param);
		}
		return param;
	}

	// {✘} @private methods

	private doReplacements(param: string) {
		this.replacements.forEach(
			replacement => (param = param.replace(new RegExp(replacement.symbol, 'g'), replacement.expression))
		);
		return param;
	}
	private doDecorations(param: string) {
		const decorationRegExp = new RegExp(/\[\[(.+?)\|({.+?})\]\]/);
		const matches = param.match(new RegExp(decorationRegExp, 'g'));

		if (matches) {
			matches.forEach(match => {
				const decorationClasses = [];
				this.decorations.forEach(
					decoration => match.includes(decoration.symbol) && decorationClasses.push(decoration.class)
				);

				param =
					decorationClasses.length > 0
						? param.replace(decorationRegExp, `<span class="${decorationClasses.join(' ')}">$1</span>`)
						: param.replace(decorationRegExp, '$1');
			});
		}

		return param;
	}
}
