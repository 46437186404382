import { NgModule } from '@angular/core';

import {
	ArithmeticSignIcons,
	ArrowIcons,
	AudioAndVideoIcons,
	CommunicationIcons,
	CountryMoroccoIcons,
	CurrencyIcons,
	DateAndTimeIcons,
	InterfacesIcons,
	LocationIcons,
	PeopleIcons,
	ReligionIcons,
	SocialNetworkIcons,
	SvgDefinitionsComponent,
} from './svg-definitions.component';

const declarations = [
	SvgDefinitionsComponent,

	// Definitions
	ArithmeticSignIcons,
	ArrowIcons,
	AudioAndVideoIcons,
	CommunicationIcons,
	CountryMoroccoIcons,
	CurrencyIcons,
	DateAndTimeIcons,
	InterfacesIcons,
	LocationIcons,
	PeopleIcons,
	ReligionIcons,
	SocialNetworkIcons,
];

@NgModule({
	exports: [SvgDefinitionsComponent],
	declarations,
})
export class YslmSvgDefinitionsModule {}
