import { isNull, isUndefined } from '@yslm/utility';

/**
 * checks whether the given value is existy or not and returns the corresponding boolean value.
 * Both `null` and `undefined` are considered "non-existy" values. All other values are "existy".
 */
export function isExisty<T>(param: T | undefined | null): param is T {
	return !isUndefined(param) && !isNull(param);
}
/** @function complement(isExisty) */
export function isntExisty<T>(param: T | undefined | null): param is undefined | null {
	return !isExisty(param);
}
