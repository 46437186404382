import { Component } from '@angular/core';

@Component({
	selector: 'i-location',
	template: `
		<svg>
			<symbol id="address">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M2305 4996 c-642 -113 -1133 -577 -1281 -1211 -26 -112 -28 -135 -29
					-335 0 -238 7 -287 72 -474 100 -290 237 -478 654 -901 315 -320 403 -417 476
					-526 102 -151 149 -274 162 -422 9 -113 30 -160 90 -202 37 -26 51 -30 111
					-30 60 0 74 4 111 30 60 42 81 89 90 203 13 149 58 268 162 421 73 109 161
					206 476 526 351 356 471 506 586 734 52 103 111 288 131 409 25 156 16 406
					-21 567 -69 303 -201 542 -425 766 -192 193 -375 306 -629 389 -242 79 -493
					98 -736 56z m384 -1183 c95 -32 183 -113 229 -211 24 -50 27 -70 27 -157 0
					-91 -3 -106 -31 -165 -75 -159 -251 -253 -416 -222 -133 25 -236 102 -291 220
					-29 61 -32 75 -32 167 0 88 3 107 27 158 87 185 293 274 487 210z"
					/>
					<path
						d="M725 2058 c-54 -19 -103 -76 -134 -158 -201 -521 -591 -1580 -591
					-1604 1 -41 31 -105 66 -138 67 -63 -130 -58 2496 -58 2293 0 2395 1 2433 19
					85 38 137 135 118 216 -10 41 -570 1537 -603 1610 -22 49 -74 98 -119 114 -21
					7 -113 11 -240 11 l-206 0 -192 -197 c-106 -109 -192 -198 -193 -198 0 0 142
					0 316 0 l316 0 214 -570 c118 -314 217 -578 220 -587 5 -17 -98 -18 -2066 -18
					-1654 0 -2071 3 -2067 13 285 764 433 1154 442 1159 5 4 146 8 313 8 l304 0
					-189 195 -188 195 -210 -1 c-118 0 -223 -5 -240 -11z"
					/>
				</g>
			</symbol>

			<symbol id="destination">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M4043 4631 c-217 -35 -409 -154 -539 -337 -141 -196 -180 -463 -102
					-704 11 -36 81 -182 154 -325 134 -263 394 -772 526 -1032 40 -79 75 -143 78
					-143 3 0 54 96 113 213 59 116 169 331 243 477 280 548 385 760 404 814 31 92
					44 239 30 348 -24 183 -91 319 -225 454 -152 153 -321 230 -529 239 -56 2
					-124 0 -153 -4z m197 -321 c196 -31 360 -199 391 -397 39 -262 -141 -506 -401
					-545 -316 -47 -591 229 -542 544 35 227 228 397 462 407 14 0 54 -4 90 -9z"
					/>
					<path
						d="M2239 2827 c-174 -117 -389 -261 -479 -320 l-162 -109 -316 316
					c-173 174 -318 316 -321 316 -3 0 -221 -430 -484 -955 l-477 -955 640 -320
					640 -320 640 320 640 320 640 -320 639 -320 641 320 640 320 -267 535 c-148
					294 -270 535 -273 535 -3 0 -45 -78 -93 -173 l-88 -173 145 -289 c79 -159 144
					-290 143 -291 -12 -11 -730 -364 -734 -361 -5 6 -243 1191 -243 1214 0 9 9 27
					21 39 l21 22 -72 139 c-39 76 -75 142 -79 146 -5 4 -24 -8 -44 -28 l-36 -36
					-288 192 c-158 106 -374 250 -480 320 -106 71 -193 129 -195 128 -2 0 -145
					-96 -319 -212z m157 -1422 c-5 -13 -876 -449 -882 -442 -3 3 46 261 108 574
					l113 568 330 221 330 221 3 -566 c1 -311 0 -571 -2 -576z m664 917 l325 -217
					113 -569 c62 -312 111 -570 108 -573 -3 -3 -202 93 -443 214 l-438 219 -3 572
					c-1 315 1 572 5 572 5 0 154 -98 333 -218z m-1828 -9 c98 -98 178 -185 178
					-194 0 -23 -238 -1210 -243 -1216 -3 -2 -170 78 -370 178 l-366 182 13 26
					c231 466 601 1201 605 1201 3 0 86 -80 183 -177z"
					/>
				</g>
			</symbol>

			<symbol id="location">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M2276 5099 c-555 -98 -1034 -496 -1226 -1021 -110 -300 -129 -613
					-59 -993 109 -591 436 -1351 947 -2196 156 -259 518 -804 575 -867 25 -27 77
					-30 99 -4 26 29 252 393 403 647 662 1118 1063 2043 1140 2633 66 505 -95 990
					-450 1347 -241 243 -537 398 -869 455 -158 27 -405 26 -560 -1z m472 -910
					c253 -64 465 -283 533 -550 22 -87 18 -259 -9 -354 -72 -251 -256 -437 -512
					-517 -62 -19 -97 -23 -200 -23 -103 0 -138 4 -200 23 -210 66 -370 200 -460
					387 -57 118 -74 191 -74 320 -1 217 75 390 239 543 185 173 431 235 683 171z"
					/>
				</g>
			</symbol>
		</svg>
	`,
})
export class LocationIcons {}
