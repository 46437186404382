/**
 *
 * @param value
 * @param metadata
 * @returns
 */
export function sign(value: number, metadata?: { zeroSign?: '+' | '-' }): '+' | '-' | '' {
	if (value === 0) {
		const { zeroSign } = metadata ?? {};
		return zeroSign ?? '';
	}

	return value > 0 ? '+' : '-';
}
