import { Observable } from 'rxjs';

import { Reference } from '../typings';

/**
 *
 */
export function isReference(param: any): param is Reference {
	return isFunction(param) || isObjectLike(param);
}

// {*} Utils

/**
 *
 */
export function isFunction(param: any): param is (...params: any[]) => any {
	return typeof param === 'function' || Object.prototype.toString.call(param) === '[object Function]';
}

/**
 *
 */
export function isArray(param: any): param is any[] {
	return Array.isArray(param) || Object.prototype.toString.call(param) === '[object Array]';
}

/**
 *
 */
export function isObject(param: any): param is Record<string, any> {
	return typeof param === 'object' && Object.prototype.toString.call(param) === '[object Object]';
}

/* eslint-disable-next-line @typescript-eslint/ban-types */
/**
 *
 */
export function isObjectLike(param: any): param is object {
	return typeof param === 'object' && param !== null;
}

/**
 *
 */
export function isMap(param: any): param is Map<any, any> {
	return typeof param === 'object' && Object.prototype.toString.call(param) === '[object Map]';
}

/**
 *
 */
export function isSet(param: any): param is Set<any> {
	return typeof param === 'object' && Object.prototype.toString.call(param) === '[object Set]';
}

/**
 *
 */
export function isDate(param: any): param is Date {
	return typeof param === 'object' && Object.prototype.toString.call(param) === '[object Date]';
}

/**
 *
 */
export function isRegExp(param: any): param is RegExp {
	return typeof param === 'object' && Object.prototype.toString.call(param) === '[object RegExp]';
}

/**
 *
 */
 export function isObservable(param: any): param is Observable<any> {
	return isFunction(param?.subscribe);
}


/**
 *
 */
 export function isPromise(param: any): param is Promise<any> {
	return isFunction(param?.then);
}
