import { Component } from '@angular/core';

@Component({
	selector: 'i-date-and-time',
	template: `
		<svg>
			<symbol id="calendar">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M1642 5103 c-59 -21 -135 -99 -156 -159 -12 -36 -16 -86 -16 -205 l0
					-157 -452 -5 c-485 -4 -497 -5 -604 -58 -75 -38 -149 -112 -186 -187 l-33 -67
					-3 -1998 -2 -1999 26 -59 c30 -67 80 -116 155 -154 112 -57 15 -55 2185 -55
					1947 0 2003 1 2077 19 41 11 94 29 117 41 61 31 128 97 157 155 14 27 30 53
					34 56 6 3 8 851 7 1995 l-3 1989 -29 63 c-52 111 -177 206 -321 242 -35 9
					-184 14 -487 17 l-436 5 -4 166 c-4 187 -11 212 -75 282 -108 121 -298 121
					-406 0 -64 -70 -71 -95 -75 -282 l-4 -168 -544 0 -544 0 0 163 c0 150 -2 166
					-24 214 -31 66 -94 127 -153 147 -58 20 -144 19 -201 -1z m-170 -1035 c3 -124
					5 -136 30 -179 99 -168 333 -191 453 -45 52 64 65 111 65 242 l0 114 545 0
					545 0 0 -103 c0 -146 17 -197 88 -267 62 -60 110 -81 191 -82 81 0 130 21 193
					82 71 72 88 122 88 267 l0 103 450 0 450 0 0 -545 0 -545 -2005 0 -2005 0 0
					545 0 545 454 0 454 0 4 -132z m579 -1443 c297 -66 470 -291 430 -557 -28
					-180 -147 -308 -355 -382 -26 -9 -18 -14 50 -32 258 -67 402 -275 373 -541
					-25 -235 -188 -413 -454 -496 -125 -39 -227 -50 -408 -44 -123 4 -187 11 -268
					30 -114 27 -258 82 -265 101 -4 11 78 332 88 341 2 3 37 -9 77 -26 134 -56
					252 -81 386 -82 100 -1 131 3 186 21 164 56 233 200 163 340 -60 120 -207 171
					-491 172 l-93 0 0 178 0 177 138 3 c164 3 223 15 299 57 170 93 151 308 -32
					372 -37 13 -79 18 -160 17 -124 -1 -195 -16 -323 -71 -46 -19 -85 -34 -86 -32
					-2 2 -22 72 -45 154 -23 83 -44 156 -47 163 -11 35 282 131 460 152 108 12
					286 5 377 -15z m1789 -980 l0 -1005 -225 0 -225 0 -2 780 -3 779 -189 -89
					c-104 -49 -191 -88 -193 -86 -7 6 -77 356 -72 360 2 2 121 63 264 134 l260
					131 193 0 192 1 0 -1005z"
					/>
				</g>
			</symbol>

			<symbol id="calendar-time">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M710 5098 c-18 -13 -43 -36 -54 -51 -20 -28 -21 -39 -21 -406 0 -424
					-3 -403 77 -461 33 -24 51 -30 94 -30 52 0 56 2 109 55 l55 55 0 376 0 376
					-31 39 c-41 52 -76 69 -142 69 -40 0 -63 -6 -87 -22z"
					/>
					<path
						d="M3275 5102 c-16 -11 -41 -32 -55 -46 l-25 -28 -3 -372 c-2 -228 1
					-383 7 -399 6 -14 33 -44 61 -66 44 -34 59 -41 98 -41 64 0 124 37 151 93 20
					41 21 58 21 395 0 389 -3 412 -62 454 -44 31 -152 36 -193 10z"
					/>
					<path
						d="M270 4480 c-113 -27 -181 -79 -231 -176 l-34 -68 0 -1515 0 -1516 33
					-67 c39 -79 93 -129 179 -161 l58 -22 985 0 985 0 0 165 0 165 -957 3 -958 2
					0 1110 0 1110 1755 0 1755 0 0 -320 0 -320 163 2 162 3 0 671 0 671 -38 75
					c-47 93 -83 128 -167 164 -57 24 -78 28 -175 28 l-110 1 -5 -125 c-6 -143 -24
					-196 -88 -255 -64 -60 -133 -88 -215 -88 -100 0 -173 29 -230 93 -57 63 -77
					127 -84 268 l-6 112 -968 0 -969 0 0 -102 c-1 -223 -89 -340 -282 -374 -59
					-10 -137 12 -209 60 -54 37 -71 54 -94 102 -26 54 -29 71 -33 187 l-4 127 -92
					-1 c-50 -1 -107 -5 -126 -9z"
					/>
					<path d="M630 2875 l0 -325 330 0 330 0 0 325 0 325 -330 0 -330 0 0 -325z" />
					<path d="M1590 2875 l0 -325 330 0 330 0 0 325 0 325 -330 0 -330 0 0 -325z" />
					<path d="M2550 2875 l0 -325 330 0 330 0 0 325 0 325 -330 0 -330 0 0 -325z" />
					<path
						d="M3680 2559 c-122 -14 -319 -67 -350 -95 -9 -7 -49 -29 -90 -48 -210
					-96 -447 -335 -540 -544 -18 -39 -36 -72 -41 -72 -4 0 -19 -35 -33 -77 -80
					-247 -95 -471 -45 -715 18 -92 64 -237 78 -248 3 -3 21 -34 38 -70 72 -143
					113 -204 176 -259 21 -19 42 -42 45 -51 9 -21 92 -93 162 -140 29 -19 61 -43
					72 -54 37 -34 222 -114 338 -146 105 -29 132 -32 306 -37 171 -5 203 -3 305
					17 63 12 150 35 194 51 89 33 225 99 225 110 0 4 26 22 58 39 75 43 279 247
					322 323 17 31 35 57 39 57 10 0 78 139 110 225 16 44 39 131 51 194 20 100 22
					135 18 300 -5 168 -8 197 -37 306 -31 115 -110 302 -145 341 -28 31 -86 112
					-86 121 0 8 -53 62 -150 155 -82 78 -133 112 -270 181 -36 17 -67 35 -70 39
					-4 4 -33 17 -65 29 -193 68 -407 91 -615 68z m270 -339 c169 -19 322 -79 446
					-172 145 -110 247 -236 308 -383 17 -39 33 -73 38 -76 4 -3 17 -48 29 -100 18
					-80 20 -116 16 -244 -7 -183 -17 -223 -103 -394 -62 -125 -72 -138 -175 -240
					-102 -103 -115 -113 -240 -175 -171 -86 -211 -96 -394 -103 -128 -4 -164 -2
					-244 16 -52 12 -97 25 -100 30 -3 5 -41 23 -83 40 -148 62 -292 184 -399 339
					-162 235 -200 557 -100 845 25 70 81 187 91 187 4 0 24 26 46 57 58 85 99 128
					167 174 34 23 73 52 85 65 31 30 210 100 304 119 130 26 190 29 308 15z"
					/>
					<path
						d="M3615 1916 c-38 -17 -80 -62 -94 -99 -7 -18 -11 -156 -11 -381 0
					-345 0 -353 23 -396 15 -30 35 -50 62 -64 38 -20 56 -21 410 -21 355 0 371 1
					405 21 102 60 104 222 4 285 -38 23 -45 24 -301 27 l-263 4 0 257 c0 285 -1
					291 -69 347 -40 33 -118 43 -166 20z"
					/>
					<path
						d="M637 2243 c-4 -3 -7 -152 -7 -330 l0 -323 330 0 330 0 -2 328 -3 327
					-321 3 c-176 1 -323 -1 -327 -5z"
					/>
					<path
						d="M1597 2243 c-4 -3 -7 -152 -7 -330 l0 -323 330 0 330 0 -2 328 -3
					327 -321 3 c-176 1 -323 -1 -327 -5z"
					/>
				</g>
			</symbol>
			<symbol id="duration">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M480 5098 c-42 -21 -101 -89 -98 -113 1 -5 -2 -18 -7 -28 -11 -26 0
					-158 14 -167 7 -4 11 -12 9 -18 -5 -20 72 -80 117 -91 24 -6 100 -11 169 -11
					l126 0 0 -32 c0 -127 155 -839 192 -885 6 -7 7 -13 3 -13 -4 0 -2 -7 5 -15 7
					-8 9 -15 5 -15 -4 0 -2 -7 5 -15 7 -8 9 -15 5 -15 -4 0 -3 -6 2 -12 6 -7 24
					-49 41 -93 126 -327 211 -481 372 -680 72 -88 223 -232 284 -271 25 -16 46
					-31 46 -34 0 -3 -24 -21 -53 -40 -184 -123 -419 -421 -544 -690 -44 -93 -52
					-114 -113 -280 -17 -46 -36 -90 -42 -97 -6 -7 -7 -13 -3 -13 4 0 2 -7 -5 -15
					-7 -9 -10 -18 -7 -22 4 -3 3 -11 -2 -17 -41 -53 -168 -632 -196 -899 l-7 -67
					-137 -1 c-146 0 -214 -16 -236 -55 -5 -11 -15 -22 -22 -27 -28 -18 -47 -197
					-23 -212 6 -4 8 -10 5 -15 -9 -15 45 -89 84 -113 l36 -22 2040 -3 c1397 -2
					2051 0 2076 8 47 13 84 43 115 91 21 34 24 50 24 129 0 97 -10 129 -52 166
					-43 40 -72 49 -178 54 -59 3 -105 9 -106 15 0 6 -3 17 -6 25 -4 8 -14 74 -23
					145 -18 139 -82 461 -116 585 -52 188 -128 420 -152 465 -6 11 -12 22 -12 25
					-2 11 -137 281 -175 350 -51 90 -127 200 -141 200 -5 0 -9 4 -9 9 0 5 -12 23
					-27 38 -16 16 -47 53 -71 81 -43 52 -150 142 -207 174 -16 9 -30 21 -30 26 0
					5 36 36 80 70 146 109 299 287 411 478 45 76 155 293 160 314 0 3 12 30 26 60
					24 52 109 310 142 430 29 106 85 376 106 510 12 74 25 153 28 175 l8 40 104 6
					c193 11 240 56 240 227 0 79 -3 91 -29 133 -20 31 -44 53 -73 68 l-44 21
					-2045 0 -2045 0 -44 -22z m3635 -438 c3 -5 1 -17 -4 -27 -5 -10 -17 -67 -26
					-128 -47 -315 -173 -703 -301 -927 -131 -228 -209 -334 -319 -431 -44 -39 -89
					-80 -100 -92 -59 -62 -239 -158 -381 -203 l-84 -26 0 -267 0 -266 78 -22 c120
					-35 328 -141 372 -191 8 -9 57 -54 108 -100 83 -75 192 -196 192 -214 0 -4 17
					-33 38 -64 132 -196 236 -428 311 -692 44 -156 102 -430 92 -430 -3 0 -23 35
					-43 78 -90 188 -163 280 -328 413 -30 24 -59 49 -65 55 -29 34 -262 146 -389
					188 -43 14 -142 38 -220 53 -133 26 -157 28 -426 28 -306 0 -370 -6 -575 -55
					-208 -50 -365 -121 -515 -234 -81 -61 -229 -207 -259 -257 -8 -13 -32 -52 -53
					-87 -21 -34 -52 -95 -68 -135 l-31 -72 6 50 c24 207 142 660 193 740 4 5 7 12
					7 15 3 22 145 283 193 355 193 291 461 494 745 564 l72 17 0 264 0 264 -70 17
					c-300 74 -581 295 -775 609 -59 94 -167 311 -204 408 -71 186 -151 533 -171
					740 l-8 72 1501 0 c947 0 1503 -4 1507 -10z"
					/>
					<path
						d="M1780 3801 c0 -42 64 -183 110 -244 51 -67 116 -118 235 -186 143
					-80 265 -106 495 -106 177 0 277 16 397 61 137 51 301 184 368 299 25 41 75
					167 75 186 0 5 -378 9 -840 9 -821 0 -840 0 -840 -19z"
					/>
				</g>
			</symbol>
		</svg>
	`,
})
export class DateAndTimeIcons {}
