import { Component, Input } from '@angular/core';
import { RxState, selectSlice } from '@rx-angular/state';
import { map } from 'rxjs/operators';

import { filter } from '@yslm/utility';
import { TripOptionType, TripStopsCategory } from 'src/app/core/models/trip.model';

// @ Component state
interface ComponentState {
	// – inputs
	selectedOptionsType: TripOptionType;
	stopsCategory: TripStopsCategory;
}

@Component({
	selector: 'trip-stops-category',
	templateUrl: './trip-stops-category.component.html',
	styleUrls: ['./trip-stops-category.component.scss'],
	providers: [RxState],
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripStopsCategoryComponent {
	// •) component state

	private readonly INITIAL_STATE: ComponentState = {
		// – inputs
		selectedOptionsType: null,
		stopsCategory: undefined,
	};

	// • view states

	readonly viewModel$ = this.componentState.select(
		selectSlice(['selectedOptionsType', 'stopsCategory']),
		map(({ selectedOptionsType, stopsCategory }) => {
			const filteredStops = filter(stopsCategory.stops, stop => !stop.type || stop.type === selectedOptionsType);

			return {
				selectedOptionsType,
				label: stopsCategory.label,
				detail: stopsCategory.detail,
				remark: stopsCategory.remark,
				stops: filteredStops
					.sort((a, b) => a.rank - b.rank)
					.map(stop => ({
						type: stop.type,
						label: stop.label,
						detail: stop.detail,
						remark: stop.remark,
						subStops: stop.subStops,
						subStopsMetadata: stop.subStopsMetadata,
					})),
				stopsMetadata: stopsCategory.stopsMetadata,
			};
		})
	);

	// •) outputs & inputs

	/** @optional */
	@Input() set selectedOptionsType(value: TripOptionType) {
		this.componentState.set({ selectedOptionsType: value });
	}

	/** @required */
	@Input() set stopsCategory(item: TripStopsCategory) {
		this.componentState.set({ stopsCategory: item });
	}

	// {*} Initialization

	constructor(private readonly componentState: RxState<ComponentState>) {
		this.componentState.set(this.INITIAL_STATE);
	}
}
