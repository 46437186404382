import { Component, Input } from '@angular/core';

@Component({
	selector: 'yslm-card-item',
	templateUrl: 'card-item.component.html',
	styleUrls: ['card-item.component.scss'],
})
export class CardItemComponent {
	@Input() imgSrc: string;
	@Input() title: string;
	@Input() detail: string;
	@Input() btnLink: string | any[];
	@Input() btnText: string;
}
