import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { TripsApiService } from '../../api/trips-api.service';
import * as TripActions from './trip.actions';

@Injectable()
export class TripEffects {
	getTrips$ = createEffect(() =>
		this.actions$.pipe(
			ofType(TripActions.getAllForHomePage, TripActions.getAllForTripsPage),
			switchMap(() =>
				this.tripsApi.getTrips().pipe(
					map(trips => TripActions.getAllSuccess({ trips })),
					catchError(() => of(TripActions.getAllFailure({ error: 'Trips could not be loaded' })))
				)
			)
		)
	);

	getTrip$ = createEffect(() =>
		this.actions$.pipe(
			ofType(TripActions.getOneForTripPage),
			switchMap(({ payload }) =>
				this.tripsApi.getTripBySlug(payload.slug).pipe(
					map(trip => TripActions.getOneSuccess({ trip })),
					catchError(() =>
						of(TripActions.getOneFailure({ error: `Trip {slug: ${payload.slug}} could not be loaded.` }))
					)
				)
			)
		)
	);
	constructor(private actions$: Actions, private tripsApi: TripsApiService) {}
}
