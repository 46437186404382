import { Component } from '@angular/core';

export * from './definitions/arithmetic-sign.component';
export * from './definitions/arrow.component';
export * from './definitions/audio-and-video.component';
export * from './definitions/communication.component';
export * from './definitions/country-morocco.component';
export * from './definitions/currency.component';
export * from './definitions/date-and-time.component';
export * from './definitions/interfaces.component';
export * from './definitions/location.component';
export * from './definitions/people.component';
export * from './definitions/religion.component';
export * from './definitions/social-network.component';

@Component({
	selector: 'svg-definitions',
	templateUrl: './svg-definitions.component.html',
})
export class SvgDefinitionsComponent {}
