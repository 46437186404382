import { Component } from '@angular/core';

@Component({
	selector: 'i-communication',
	template: `
		<svg>
			<symbol id="email">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M510 4473 c-45 -9 -181 -65 -178 -74 4 -11 2219 -1949 2228 -1949 9
					0 2224 1938 2228 1949 2 5 -34 24 -80 43 l-83 33 -2045 1 c-1125 1 -2056 -1
					-2070 -3z"
					/>
					<path
						d="M65 4131 c-13 -27 -34 -82 -45 -122 -20 -72 -20 -98 -20 -1447 0
					-1222 2 -1381 16 -1436 19 -73 53 -156 65 -156 10 0 1619 1800 1619 1811 0 11
					-1587 1399 -1600 1399 -5 0 -21 -22 -35 -49z"
					/>
					<path
						d="M4216 3486 c-434 -380 -791 -696 -793 -702 -4 -12 1603 -1814 1616
					-1814 5 0 18 24 30 53 53 130 51 59 51 1537 0 1351 0 1377 -20 1449 -24 86
					-65 171 -83 170 -6 0 -367 -312 -801 -693z"
					/>
					<path
						d="M1115 1658 c-448 -502 -815 -916 -815 -920 0 -13 96 -57 171 -78 73
					-20 95 -20 2089 -20 1994 0 2016 0 2089 20 75 21 171 65 171 78 0 12 -1630
					1832 -1641 1832 -5 0 -123 -100 -262 -221 -138 -122 -265 -230 -282 -240 -39
					-25 -111 -25 -150 0 -16 10 -144 118 -282 240 -139 121 -257 221 -262 221 -6
					0 -377 -411 -826 -912z"
					/>
				</g>
			</symbol>

			<symbol id="phone">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M855 5096 c-128 -27 -284 -78 -335 -109 -87 -54 -191 -175 -296 -348
					-106 -174 -178 -360 -209 -535 -19 -111 -19 -336 0 -446 28 -161 109 -408 268
					-823 196 -508 515 -963 1052 -1500 538 -538 990 -855 1500 -1052 353 -136 561
					-207 725 -249 140 -35 397 -45 537 -20 240 42 488 155 714 327 147 111 194
					182 248 370 61 212 79 400 45 457 -27 45 -86 82 -464 292 -201 111 -417 237
					-481 279 -152 102 -199 124 -263 125 -97 1 -197 -82 -397 -330 -185 -229 -287
					-324 -351 -324 -55 0 -143 41 -346 161 -362 215 -585 383 -817 614 -229 230
					-398 455 -613 816 -121 204 -162 291 -162 347 0 63 95 167 321 349 153 123
					268 236 305 299 22 37 28 60 28 100 -1 64 -23 111 -125 263 -42 64 -168 280
					-279 481 -210 378 -247 437 -292 464 -39 23 -179 20 -313 -8z"
					/>
				</g>
			</symbol>
		</svg>
	`,
})
export class CommunicationIcons {}
