import { Pipe, PipeTransform } from '@angular/core';

import { isNil, isString } from '@yslm/utility/type';

@Pipe({
	name: 'fallback',
})
export class FallbackPipe implements PipeTransform {
	transform<F>(param: any, fallback: any): string {
		return !isNil(param) ? String(param) : String(fallback);
	}
}
