import { Component } from '@angular/core';

@Component({
	selector: 'i-currency',
	template: `
		<svg>
			<symbol id="euro">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M2305 5113 c-671 -72 -1279 -393 -1707 -903 -301 -358 -488 -764
					-571 -1240 -19 -109 -21 -162 -21 -410 0 -248 2 -301 21 -410 94 -540 320
					-987 693 -1374 383 -398 866 -650 1430 -749 109 -19 162 -21 410 -21 313 0
					407 10 653 73 882 228 1600 946 1828 1828 63 246 73 340 73 653 0 313 -10 407
					-73 653 -228 881 -946 1600 -1828 1828 -227 58 -334 71 -613 74 -140 2 -273 1
					-295 -2z m505 -394 c400 -46 807 -217 1115 -469 371 -303 637 -722 749 -1181
					78 -322 78 -696 0 -1018 -75 -306 -230 -618 -424 -856 -362 -443 -862 -719
					-1440 -796 -109 -14 -391 -14 -500 0 -257 34 -488 103 -705 211 -439 217 -780
					558 -995 995 -154 313 -221 603 -221 955 0 352 67 642 221 955 215 437 558
					780 995 995 383 188 783 258 1205 209z"
					/>
					<path
						d="M2430 4524 c-19 -2 -78 -9 -130 -15 -178 -20 -451 -104 -621 -189
					-366 -185 -694 -513 -879 -879 -83 -166 -165 -432 -191 -621 -17 -122 -17
					-398 0 -520 26 -189 108 -455 191 -621 185 -366 513 -694 879 -879 166 -83
					432 -165 621 -191 122 -17 398 -17 520 0 189 26 455 108 621 191 366 185 694
					513 879 879 83 166 165 432 191 621 7 52 13 169 13 260 0 91 -6 208 -13 260
					-26 189 -108 455 -191 621 -185 366 -513 694 -879 879 -161 81 -403 157 -586
					185 -100 15 -359 27 -425 19z m483 -694 c127 -11 206 -28 293 -63 l54 -22 0
					-218 0 -218 -44 30 c-58 40 -147 79 -221 96 -76 19 -235 19 -300 1 -158 -43
					-282 -150 -360 -311 -18 -38 -37 -82 -40 -97 l-7 -28 366 0 366 0 0 -160 0
					-160 -395 0 -395 0 0 -125 0 -125 395 0 395 0 0 -160 0 -160 -370 0 c-203 0
					-370 -1 -370 -2 0 -11 46 -121 72 -171 37 -75 144 -184 211 -216 181 -87 450
					-60 647 64 22 14 42 25 45 25 3 0 5 -92 5 -204 l0 -203 -67 -31 c-111 -50
					-192 -68 -357 -82 -303 -25 -557 52 -747 228 -136 126 -236 305 -288 517 l-17
					70 -107 3 -107 3 0 159 0 160 84 0 83 0 1 125 1 125 -84 0 -85 0 0 160 0 160
					110 0 c80 0 112 3 114 13 60 196 96 280 171 392 104 157 258 286 420 352 114
					46 210 66 395 81 8 1 68 -3 133 -8z"
					/>
				</g>
			</symbol>
		</svg>
	`,
})
export class CurrencyIcons {}
