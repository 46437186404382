import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RxState, selectSlice, stateful } from '@rx-angular/state';
import { filter, map, mapTo, switchMap, tap } from 'rxjs/operators';

import { MediaQueryHelper } from '@yslm/helpers';
import { isUndefined } from '@yslm/utility';

import { ViewTypeEnum } from './app-routing.module';
import { AppContext } from './app.context';

// @ Component state
interface ComponentState {
	// – router
	lastActivatedRoute: ActivatedRoute;

	// – loaded view
	viewType: ViewTypeEnum;
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [RxState],
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
	// •) component state

	readonly viewModel$ = this.componentState.select(selectSlice(['viewType']));

	// {*} Initialization

	constructor(
		private readonly componentState: RxState<ComponentState>,
		private readonly appContext: AppContext,
		private readonly router: Router,
		private readonly activatedRoute: ActivatedRoute,
		private readonly mediaQueryHelper: MediaQueryHelper
	) {
		this.componentState.connect(
			'lastActivatedRoute',
			this.router.events.pipe(
				filter(event => event instanceof NavigationEnd),
				mapTo(this.activatedRoute),
				map(route => {
					while (route.firstChild) {
						route = route.firstChild;
					}

					return route;
				}),
				filter(route => route.outlet === 'primary')
			)
		);
	}

	ngOnInit(): void {
		this.connectStates();
		this.holdStates();
	}

	private connectStates() {
		this.componentState.connect(
			'viewType',
			this.componentState.select('lastActivatedRoute').pipe(
				stateful(
					filter(lastActivatedRoute => !isUndefined(lastActivatedRoute)),
					switchMap(lastActivatedRoute => lastActivatedRoute.data),
					map(data => data.viewType)
				)
			)
		);
	}

	private holdStates() {
		this.componentState.hold(this.componentState.select('viewType'), viewType => this.appContext.setViewType(viewType));
	}

	// {*} Business methods

	// @todo improve?
	@HostListener('window:resize')
	private onHostResize() {
		this.mediaQueryHelper.triggerWindowResized();
	}
}
