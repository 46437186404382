<div class="stop__category" *ngIf="viewModel$ | async as $">
	<div class="category__section" margin="b:1x">
		<div *ngIf="$.label" class="label u-subheading" margin="b:none" innerHTML="{{ $.label | parseTemplating }}"></div>
		<div *ngIf="$.detail" class="detail" innerHTML="{{ $.detail | parseTemplating | wrap:'{':'}' }}"></div>
		<div *ngIf="$.remark" class="remark" innerHTML="{{ $.remark | parseTemplating | wrap:'[':']' }}"></div>
	</div>

	<div class="stops__section">
		<span
			class="stop"
			*ngFor="let stop of $.stops; let i = index; let isLast = last"
			[margin]="$.stopsMetadata?.leftMargin ? 'l:1x' : undefined"
		>
			<ng-container *ngIf="$.stopsMetadata?.numbered">{{ i + 1 }}. </ng-container>

			<span *ngIf="stop.label" class="label" innerHtml="{{ stop.label | parseTemplating }}"></span>
			<ng-container *ngIf="stop.label && (stop.detail || stop.remark)">&nbsp;</ng-container>

			<span
				*ngIf="stop.detail"
				class="detail"
				color="gloomy"
				innerHtml="{{ stop.detail | parseTemplating | wrap:'{':'}' }}"
			></span>
			<ng-container *ngIf="(stop.label || stop.detail) && stop.remark">&nbsp;</ng-container>

			<span
				*ngIf="stop.remark"
				class="remark u-font-bold"
				innerHtml="{{ stop.remark | parseTemplating | wrap:'[':']' }}"
			></span>

			<ng-container *ngIf="$.stopsMetadata?.separator && !isLast">{{ $.stopsMetadata.separator }}</ng-container>

			<br
				*ngIf="
					$.stopsMetadata?.linebreak &&
					(stop.label || stop.detail || stop.remark)
				"
			/>

			<ng-container *ngIf="!$.stopsMetadata?.linebreak && stop.subStops?.length > 0">: </ng-container>

			<span
				[margin]="
					$.stopsMetadata?.leftMargin && stop.subStopsMetadata?.linebreak
						? 'l:2x'
						: stop.subStopsMetadata?.linebreak
							? 'l:1x'
							: undefined
				"
				*ngFor="let subStop of stop.subStops; let i = index; let isLast = last"
			>
				<ng-container *ngIf="stop.subStopsMetadata?.numbered">{{ i + 1 }}. </ng-container>
				{{ subStop }}

				<ng-container *ngIf="stop.subStopsMetadata?.separator && !isLast">
					{{ stop.subStopsMetadata.separator }}
				</ng-container>

				<br *ngIf="stop.subStopsMetadata?.linebreak" />
			</span>

			<br *ngIf="$.stopsMetadata?.linebreak && stop.subStopsMetadata?.linebreak && !isLast" />
		</span>
	</div>
</div>
