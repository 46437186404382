import { has } from '../object/has.utility';
import { isNil } from '../type/primitive.utility';
import { isArray, isFunction, isObject } from '../type/reference.utility';
import { ArrayIteratee, ObjectIteratee, ObjectKeyValues } from '../typings';

// {*} Signatures

/**
 * Iterates over the elements of `vector`, returning the first element `predicate` returns truthy for.
 *
 * @param {Array} vector The array to iterate over.
 * @param {Function} predicate The callback invoked at each iteration.
 * @returns The first element filtered.
 */
export function find<T>(vector: T[], predicate: ArrayIteratee<T, boolean>): T;

/**
 * Iterates over the objects of `vector`, returning the first object matching each entry of `keyValues`.
 *
 * @param {Array} vector The array of objects to iterate over.
 * @param {Object} keyValues An object with `key: value` or `key: value[]` pairs.
 * @returns The first object filtered.
 */
export function find<T extends Record<string, any>>(vector: T[], keyValues: ObjectKeyValues<T>): T;

/**
 * Iterates over the entries of `record`, returning the first entry `predicate` returns truthy for.
 *
 * @param {Object} record The object of entries to iterate over.
 * @param {Function} predicate The callback invoked at each iteration.
 * @returns The first object's entry filtered.
 */
export function find<T extends Record<string, any>>(
	record: T,
	predicate: ObjectIteratee<T, boolean>
): [key: keyof T, value: T[keyof T]];

/**
 * Iterates over the entries of `record`, returning the first entry matching any of the `keyValues` entries.
 *
 * @param {Object} record The object of entries to iterate over.
 * @param {Object} keyValues An object with `key: value` or `key: value[]` pairs
 * @returns Te first object's entry filtered.
 */
export function find<T extends Record<string, any>>(
	obj: T,
	keyValues: ObjectKeyValues<T>
): [key: keyof T, value: T[keyof T]];

// {*} Implementation

/**
 *
 */
export function find<T extends any[] | Record<string, any>>(
	collection: T,
	predicateOrKeyValues: ((item, index?: number) => boolean) | { [k: string]: any }
) {
	if (!isArray(collection) && !isObject(collection)) {
		throw new TypeError('Expected `collection` argument to be an array or an object');
	}
	if (!isFunction(predicateOrKeyValues) && !isObject(predicateOrKeyValues)) {
		throw new TypeError('Expected argument to be a "predicate" function or a "key-values" record');
	}

	const predicate = isFunction(predicateOrKeyValues) ? predicateOrKeyValues : undefined;
	const keyValues = isObject(predicateOrKeyValues) ? predicateOrKeyValues : undefined;

	if (isArray(collection) && predicate) {
		return collection.find((item, index) => predicate(item, index));
	}
	if (isArray(collection) && keyValues) {
		return collection.find((item: { [k: string]: any }) =>
			Object.entries(keyValues).every(([key, values]) =>
				isArray(values) ? values.includes(item[key]) : values === item[key]
			)
		);
	}

	if (isObject(collection) && predicate) {
		return Object.entries(collection).find(([key, value], index) => predicate([key, value], index));
	}
	if (isObject(collection) && keyValues) {
		return Object.entries(collection).find(([key, value]) =>
			has(keyValues, key) && isArray(keyValues[key]) ? keyValues[key].includes(value) : keyValues[key] === value
		);
	}
}
