import { absolute } from './absolute.utility';
import { sign } from './sign.utility';

/**
 * Transforms the number received as arg, into a string concatenating the absolute value of the number with its sign.
 *
 * @param value
 * @param metadata
 * @returns
 */
export function signed(value: number, metadata?: { addSpace?: boolean; zeroSign?: '+' | '-' }): string {
	const { addSpace, zeroSign } = metadata ?? {};

	const space = addSpace ? ' ' : '';
	const absValue = absolute(value);
	const nbrSign = sign(value, { zeroSign });

	return `${nbrSign}${space}${absValue}`;
}
