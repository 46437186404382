import { AnimationFactory, AnimationMetadata, AnimationPlayer } from '@angular/animations';
import { AnimationBuilder } from '@angular/animations';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { HTMLElementStyles } from '@yslm/common/typings';
import { HTMLElementHelper } from '@yslm/helpers/html-element';

@Injectable()
export class AnimationHelper {
	renderer2: Renderer2;
	rootClassName = 'animations__root';
	nodeClassName = 'animation__node';

	constructor(
		public readonly rendererFactory2: RendererFactory2,
		public readonly animationBuilder: AnimationBuilder,
		public readonly htmlElementHelper: HTMLElementHelper
	) {
		this.renderer2 = rendererFactory2?.createRenderer(null, null);
	}

	// {✔} @public methods

	// ↓↓↓ Animation Engine

	build(animation: AnimationMetadata | AnimationMetadata[]): AnimationFactory {
		return this.animationBuilder.build(animation);
	}

	play(animationFactory: AnimationFactory, animationNodeEl: HTMLElement): AnimationPlayer {
		const animationPlayer = this.createPlayer(animationFactory, animationNodeEl);
		animationPlayer.play();

		return animationPlayer;
	}

	// ↓↓↓ Animation Nodes

	createAnimationNode(parentContainerEl: HTMLElement, nodeStyles: HTMLElementStyles): HTMLElement {
		const animationNodeEl = this.htmlElementHelper.createElement('span', this.nodeClassName, nodeStyles);
		const animationsRootEl = this.findOrCreateAnimationsRoot(parentContainerEl);
		this.renderer2.appendChild(animationsRootEl, animationNodeEl);

		return animationNodeEl;
	}

	removeAnimationNode(parentContainerEl: HTMLElement, animationNode: HTMLElement): void {
		const animationsRootEl = this.findAnimationRoot(parentContainerEl);

		if (animationsRootEl && animationNode && animationsRootEl === this.renderer2.parentNode(animationNode)) {
			this.renderer2.removeChild(animationsRootEl, animationNode);
		}

		this.removeAnimationsRoot(parentContainerEl);
	}

	// {✘} @private methods

	// ↓↓↓ Animation Engine

	private createPlayer(animationFactory: AnimationFactory, animationNodeEl: HTMLElement): AnimationPlayer {
		return animationFactory.create(animationNodeEl);
	}

	// ↓↓↓ Animation Nodes

	private findAnimationRoot(parentContainerEl: HTMLElement): HTMLElement {
		return parentContainerEl.querySelector(`.${this.rootClassName}`);
	}

	private findOrCreateAnimationsRoot(parentContainerEl: HTMLElement): HTMLElement {
		let animationsRootEl = this.findAnimationRoot(parentContainerEl);

		if (!animationsRootEl) {
			animationsRootEl = this.htmlElementHelper.createElement('div', this.rootClassName, {
				position: 'absolute',
				top: '0px',
				bottom: '0px',
				left: '0px',
				right: '0px',
			});

			this.htmlElementHelper.setStyles(parentContainerEl, {
				position: 'relative',
				overflow: 'hidden',
			});
			this.renderer2.appendChild(parentContainerEl, animationsRootEl);
		}

		return animationsRootEl;
	}

	private removeAnimationsRoot(parentContainerEl: HTMLElement): void {
		const animationsRootEl = this.findAnimationRoot(parentContainerEl);

		/**
		 * We cannot use `!htmlElement.hasChildren()` or `childNodes.length === 0` while using renderer2.
		 * ↬ renderer2.removeChild() is async, so we need to use `childNodes.length - 1 === 0` instead.
		 */
		if (parentContainerEl && animationsRootEl && animationsRootEl.childNodes.length - 1 === 0) {
			this.renderer2.removeChild(parentContainerEl, animationsRootEl);
			this.htmlElementHelper.removeStyles(parentContainerEl, ['position', 'overflow']);
		}
	}
}
