import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RxState, selectSlice } from '@rx-angular/state';
import { filter, map } from 'rxjs/operators';

import { YslmCarouselConfig } from '@yslm/common/components';
import { isEmpty, isNil, isUndefined } from '@yslm/utility';
import { Trip, TripOptionType } from 'src/app/core/models';
import { AppState } from 'src/app/core/store';
import { TripActions, TripSelectors } from 'src/app/core/store/trips';

// @ Component state
interface ComponentState {
	// – configs
	carouselConfig: YslmCarouselConfig;

	// – store states
	trip: Trip;

	// – computed states
	isThereAnyTypedOption: boolean;

	// – user selections
	selectedOptionsType: TripOptionType;
}

@Component({
	selector: 'trip-page',
	templateUrl: './trip.component.html',
	styleUrls: ['./trip.component.scss'],
	providers: [RxState],
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripPage implements OnInit {
	// •) enumerations

	readonly TripOptionType = TripOptionType;

	// •) component state

	private readonly INITIAL_STATE: ComponentState = {
		// – configs
		carouselConfig: {
			itemsCountPerSlideBreakpoints: {
				mobile: 1,
				tabletPortrait: 1,
				tabletLandscape: 2,
				desktopSmall: 3,
				desktopLarge: 3,
			},
			itemGutterBreakpoints: { all: 40 },
			loopInterval: 15000,
			hasBorders: true,
		},

		// – store states
		trip: undefined,

		// – properties
		isThereAnyTypedOption: undefined,

		// – user selections
		selectedOptionsType: undefined,
	};

	// • view states

	readonly viewModel$ = this.componentState.select(
		selectSlice(['carouselConfig', 'trip', 'isThereAnyTypedOption', 'selectedOptionsType']),
		map(({ carouselConfig, trip, isThereAnyTypedOption, selectedOptionsType }) => ({
			carouselConfig,
			type: trip.type,
			title: trip.title,
			images: trip.images,
			options: trip.options.map(option => ({
				trajectories: option.trajectories
					? option.trajectories.map(trajectory => trajectory.join(' ➞ '))
					: [],
				duration: option.duration,
				programs: option.programs,
				pricings: option.pricings,
				included: option.included,
				excluded: option.excluded,
			})),
			optionsTypesMetadata: [
				{
					type: TripOptionType.shared,
					theme: 'secondary',
				},
				{
					type: TripOptionType.private,
					theme: 'primary',
				},
			],
			isThereAnyTypedOption,
			selectedOptionsType,
		}))
	);

	// {*} Initialization

	constructor(
		private readonly store: Store<AppState>, // @todo use `TripsState` instead of `AppState`
		private readonly componentState: RxState<ComponentState>,
		private readonly activatedRoute: ActivatedRoute,
		private readonly router: Router
	) {
		this.componentState.set(this.INITIAL_STATE);
	}

	ngOnInit(): void {
		// – dispatch initial actions
		this.componentState.hold(
			this.activatedRoute.paramMap.pipe(
				filter(paramMap => paramMap.has('slug')),
				map(paramMap => paramMap.get('slug'))
			),
			tripSlug => {
				const getOneTripAction = TripActions.getOneForTripPage({ slug: tripSlug });
				this.store.dispatch(getOneTripAction);
			}
		);

		// – connect & hold streams
		this.connectStates();
	}

	private connectStates() {
		// – connect trip from the store
		this.componentState.connect('trip', this.store.select(TripSelectors.getSelectedTrip));

		// – [computed] whether or not a trip option is typed
		this.componentState.connect(
			'isThereAnyTypedOption',
			this.componentState.select('trip').pipe(map(trip => trip.options.some(option => option.isTyped)))
		);

		// – [computed] default selected OptionsType
		this.componentState.connect(
			'selectedOptionsType',
			this.componentState.select('isThereAnyTypedOption').pipe(
				filter(isThereAnyTypedOption => !isUndefined(isThereAnyTypedOption)),
				map(isThereAnyTypedOption => (isThereAnyTypedOption ? TripOptionType.shared : null))
			)
		);
	}

	// {*} Event handlers

	handleOptionsTypeSelect(type: TripOptionType): void {
		this.componentState.set({ selectedOptionsType: type });
	}
}
