import { Trip } from 'src/app/core/models';

import { LoaderState } from '../typing.types';

export const tripStateKey = 'tripState';

export interface TripState extends LoaderState {
	trips: Trip[];
	trip: Trip;
	selectedTripId: string;
}

export const initialTripState: TripState = {
	loader: { pending: false, error: undefined },
	trips: [],
	trip: undefined,
	selectedTripId: undefined,
};
