import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { YslmCommonModule } from '@yslm/common';

import { AppCommonModule } from '../common/app-common.module';
import { FooterComponent, HeaderComponent } from './layouts';
import {
	AboutPage,
	HomePage,
	NotFoundPage,
	TripPage,
	TripPricingComponent,
	TripStopsCategoryComponent,
	TripsPage,
} from './pages';

@NgModule({
	imports: [CommonModule, RouterModule, YslmCommonModule, AppCommonModule],
	exports: [HeaderComponent, FooterComponent],
	declarations: [
		// layouts
		HeaderComponent,
		FooterComponent,

		// pages
		HomePage,
		AboutPage,
		TripsPage,
		TripPage,
		TripStopsCategoryComponent,
		TripPricingComponent,
		NotFoundPage,
	],
})
export class AppContentModule {}
