export interface CarouselDimensions {
	hasBorders: boolean;

	// ↓↓↓ Carousel
	carouselNativeWidth: number;
	carouselItemsCount: number;

	// ↓↓↓ Carousel item
	itemsCountPerSlide: number;
	itemGutter: number;
	itemWidth: number;

	// ↓↓↓ Carousel slide
	slidesCount: number;
	slideGutter: number;
	slideWidth: number;

	// ↓↓↓ Carousel sliding
	slidingItemsCount: number;
	slidingWidth: number;
	slidingOffset: number;

	// ↓↓↓ Carousel items container
	itemsContainerWidth: number;
}

export enum SlidingDirection {
	previous = 'previous',
	next = 'next',
}
