<div *ngIf="viewModel$ | async as $" class="home home__page" padding="children:segment">
	<!-- page segment -->
	<div class="home__favorites favorites">
		<div class="favorites__caption u-caption-box">
			<h1 class="u-headline-primary" margin="none">Travelers' Favorites</h1>
			<h2 class="u-headline-secondary">Take a look at our travelers’ recommended trips.</h2>
			<div class="u-headline-divider"></div>
		</div>
		<div class="favorites__body">
			<yslm-carousel [config]="$.carouselConfig">
				<ng-container *ngFor="let trip of $.trips">
					<trip-card
						*carouselItem
						[slug]="trip.slug"
						[title]="trip.title"
						[detail]="trip.detail"
						[coverImage]="trip.coverImage"
						[minFare]="trip.minFare"
					></trip-card>
				</ng-container>
			</yslm-carousel>
		</div>
	</div>

	<!-- page segment -->
	<div class="home__features features">
		<div class="features__caption u-caption-box">
			<span class="u-headline-primary">Organize your customized trip</span>
		</div>
		<div class="features__body">
			<div class="feature">
				<svg-use class="feature__icon" name="tarboosh"></svg-use>
				<div class="feature__title u-headline-tertiary">Culture</div>
				<p class="feature__detail">Discover Moroccan traditions and customs</p>
			</div>
			<div class="feature">
				<svg-use class="feature__icon" name="bab-boujloud"></svg-use>
				<div class="feature__title u-headline-tertiary">Monuments</div>
				<p class="feature__detail">Visit ancient monuments and historic sites</p>
			</div>
			<div class="feature">
				<svg-use class="feature__icon" name="slipper"></svg-use>
				<div class="feature__title u-headline-tertiary">Handcrafting</div>
				<p class="feature__detail">Discover authentic and contemporary handicrafts</p>
			</div>
			<div class="feature">
				<svg-use class="feature__icon" name="tajine"></svg-use>
				<div class="feature__title u-headline-tertiary">Gastronomy</div>
				<p class="feature__detail">Taste gourmet dishes and regional specialties</p>
			</div>
		</div>
	</div>

	<!-- page segment -->
	<div class="home__about about">
		<div class="about__caption u-caption-box">
			<span class="u-headline-primary">What is it about?</span>
			<p class="u-headline-secondary">Experience an unforgettable adventure.</p>
			<div class="u-headline-divider"></div>
		</div>
		<div class="about__body">
			<div class="about__video">
				<video
					src="{{ '/assets/videos/agency.mp4' }}"
					poster="{{ '/assets/videos/posters/agency.png' }}"
					fit-cover
					controls
				></video>
			</div>
		</div>
	</div>
</div>
