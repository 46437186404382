import { Pipe, PipeTransform } from '@angular/core';

import { find } from '@yslm/utility';
import type { ArrayIteratee, ObjectIteratee, ObjectKeyValues } from '@yslm/utility';

@Pipe({ name: 'find' })
export class FindPipe implements PipeTransform {
	transform<T>(vector: T[], predicate: ArrayIteratee<T, boolean>): T;
	transform<T extends Record<string, any>>(vector: T[], keyValues: ObjectKeyValues<T>): T;

	transform<T extends Record<string, any>>(items: T, predicate: ObjectIteratee<T, boolean>): [key: keyof T, value: T[keyof T]];
	transform<T extends Record<string, any>>(items: T, keyValues: ObjectKeyValues<T>): [key: keyof T, value: T[keyof T]];

	transform(items, predicateOrKeyValues) {
		return find(items, predicateOrKeyValues);
	}
}

