import { Component } from '@angular/core';

@Component({
	selector: 'i-people',
	template: `
		<svg>
			<symbol id="person">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M2362 5099 c-253 -34 -527 -151 -724 -307 -358 -286 -559 -697 -559
					-1147 0 -244 38 -420 138 -634 256 -552 862 -899 1469 -842 703 66 1253 589
					1345 1278 15 113 6 383 -16 483 -65 295 -194 530 -409 745 -216 215 -451 343
					-746 405 -121 25 -377 35 -498 19z"
					/>
					<path
						d="M1290 2208 c-231 -134 -395 -262 -581 -456 -194 -201 -328 -395 -455
					-656 -148 -306 -226 -617 -248 -988 l-6 -98 2555 0 2555 0 0 94 c0 237 -65
					556 -167 816 -132 337 -311 609 -573 871 -190 190 -381 333 -607 454 l-51 27
					-79 -67 c-214 -183 -484 -314 -768 -372 -139 -28 -493 -25 -632 5 -272 59
					-512 173 -718 342 -39 32 -77 65 -84 74 -7 9 -19 16 -25 16 -6 -1 -58 -28
					-116 -62z"
					/>
				</g>
			</symbol>

			<symbol id="tourist">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M2 3914 c4 -156 9 -229 17 -232 6 -2 11 -27 11 -58 0 -30 5 -54 10
					-54 6 0 10 -20 10 -45 0 -25 5 -45 10 -45 6 0 10 -20 10 -45 0 -27 5 -45 11
					-45 8 0 8 -4 0 -14 -8 -10 -9 -16 -1 -21 5 -3 10 -24 10 -46 0 -26 5 -42 15
					-45 10 -4 15 -20 15 -50 0 -24 5 -44 10 -44 6 0 10 -16 10 -35 0 -19 5 -35 10
					-35 6 0 10 -18 10 -40 0 -22 5 -40 10 -40 6 0 10 -16 10 -35 0 -19 5 -35 10
					-35 6 0 10 -14 10 -31 0 -34 30 -132 43 -137 4 -2 7 -19 7 -38 0 -19 5 -34 10
					-34 6 0 10 -16 10 -35 0 -19 5 -35 10 -35 6 0 10 -9 10 -20 0 -11 5 -20 10
					-20 6 0 10 -7 10 -15 0 -19 75 -95 95 -95 8 0 15 -4 15 -10 0 -5 16 -10 35
					-10 19 0 35 -4 35 -10 0 -6 66 -10 179 -10 152 0 184 -3 210 -17 l31 -18 0
					-487 c0 -319 3 -488 10 -488 6 0 10 -83 10 -230 l0 -230 745 0 745 0 1 408 c1
					364 2 404 15 374 13 -30 37 -60 105 -129 13 -13 34 -23 51 -23 15 0 28 -4 28
					-10 0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 16 10 35 10 19 0 35 5 35 10 0 6 7
					10 15 10 9 0 18 7 21 15 4 8 12 15 20 15 11 0 14 -21 14 -100 l0 -100 30 0
					c17 0 30 5 30 10 0 6 13 10 29 10 17 0 33 5 36 10 3 6 17 10 30 10 13 0 27 5
					30 10 3 6 15 10 26 10 11 0 22 7 25 15 4 9 19 15 35 15 16 0 29 5 29 10 0 6
					11 10 25 10 14 0 25 5 25 10 0 6 9 10 19 10 11 0 21 6 24 13 4 9 7 9 18 0 7
					-7 23 -13 35 -13 13 0 26 -5 29 -10 3 -5 22 -10 41 -10 19 0 34 -4 34 -10 0
					-5 7 -10 16 -10 8 0 22 -7 30 -15 9 -8 26 -15 40 -15 13 0 24 -4 24 -10 0 -5
					14 -10 30 -10 17 0 30 -4 30 -10 0 -5 16 -10 35 -10 19 0 35 -4 35 -10 0 -5
					14 -10 30 -10 17 0 30 -4 30 -10 0 -5 9 -10 21 -10 11 0 27 -7 35 -15 9 -8 26
					-15 39 -15 13 0 27 -4 30 -10 3 -5 17 -10 31 -10 13 0 24 -4 24 -10 0 -5 9
					-10 20 -10 11 0 20 5 20 10 0 6 25 10 55 10 30 0 55 5 55 10 0 6 23 10 50 10
					36 0 52 4 56 15 5 11 22 15 70 15 36 0 64 4 64 10 0 6 25 10 55 10 30 0 55 5
					55 10 0 6 22 10 49 10 28 0 53 5 56 10 3 6 26 10 51 10 24 0 44 5 44 10 0 6
					25 10 55 10 42 0 55 3 55 15 0 12 13 15 55 15 l55 0 0 55 c0 56 8 67 30 40 7
					-8 19 -15 26 -15 8 0 14 -4 14 -10 0 -5 13 -10 28 -10 15 0 34 -6 40 -12 10
					-10 17 -10 32 0 11 6 31 12 45 12 14 0 25 4 25 9 0 5 9 12 19 16 11 3 23 15
					26 26 4 10 15 19 26 19 12 0 19 7 19 20 0 11 5 20 10 20 6 0 10 11 10 25 0 14
					5 25 10 25 6 0 10 42 10 105 0 63 -4 105 -10 105 -5 0 -10 7 -10 15 0 8 -4 15
					-8 15 -5 0 -15 12 -23 28 -17 34 -34 52 -48 52 -6 0 -11 7 -11 15 0 8 -4 15
					-10 15 -5 0 -10 7 -10 16 0 9 -13 28 -28 42 -16 15 -31 35 -35 45 -4 11 -16
					21 -27 24 -11 3 -20 13 -20 22 0 9 -9 23 -20 31 -11 8 -20 20 -20 26 0 7 -16
					29 -35 48 -19 20 -35 43 -35 51 0 8 -4 15 -10 15 -6 0 -10 55 -10 145 0 138
					-1 145 -20 145 -11 0 -20 -4 -20 -10 0 -5 -20 -10 -45 -10 -25 0 -45 -4 -45
					-10 0 -5 -18 -10 -40 -10 -27 0 -43 -5 -46 -15 -5 -11 -21 -15 -60 -15 -30 0
					-54 -4 -54 -10 0 -5 -18 -10 -39 -10 -22 0 -43 -4 -46 -10 -3 -5 -28 -10 -56
					-10 -27 0 -49 -4 -49 -10 0 -5 -16 -10 -35 -10 -19 0 -35 -4 -35 -10 0 -5 -22
					-10 -49 -10 -31 0 -53 -5 -61 -15 -8 -10 -30 -15 -61 -15 -27 0 -49 -4 -49
					-10 0 -5 -20 -10 -45 -10 -25 0 -45 -4 -45 -10 0 -5 -16 -10 -35 -10 -19 0
					-35 5 -35 10 0 6 -13 10 -30 10 -16 0 -30 5 -30 10 0 6 -11 10 -25 10 -14 0
					-25 5 -25 11 0 19 -28 39 -54 39 -14 0 -26 5 -26 10 0 6 -10 10 -23 10 -12 0
					-29 9 -37 20 -9 13 -26 20 -47 20 -23 0 -33 5 -33 15 0 8 -8 15 -18 15 -10 0
					-24 9 -32 20 -14 20 -70 29 -70 11 0 -5 -17 -11 -37 -14 -68 -10 -124 -24
					-136 -36 -6 -6 -23 -11 -38 -11 -15 0 -31 -4 -34 -10 -3 -5 -19 -10 -36 -10
					-16 0 -29 -4 -29 -10 0 -5 -20 -10 -45 -10 -25 0 -45 -4 -45 -9 0 -9 -63 -21
					-108 -21 -23 0 -23 -2 -20 -75 2 -53 0 -75 -9 -75 -6 0 -15 -9 -18 -20 -3 -11
					-11 -20 -16 -20 -5 0 -9 -9 -9 -19 0 -11 -9 -23 -20 -26 -11 -3 -20 -14 -20
					-23 0 -10 -11 -26 -25 -36 -14 -10 -25 -24 -25 -30 0 -6 -9 -18 -20 -26 -11
					-8 -20 -18 -20 -23 0 -4 -16 -23 -35 -43 -19 -19 -35 -38 -35 -43 0 -4 -13
					-22 -30 -39 -16 -18 -30 -38 -30 -45 0 -7 -11 -21 -25 -31 -14 -10 -25 -27
					-25 -37 0 -11 -4 -19 -10 -19 -6 0 -10 50 -10 130 0 80 -4 130 -10 130 -6 0
					-10 48 -10 124 0 73 -4 127 -10 131 -5 3 -10 42 -10 86 0 46 -4 79 -10 79 -5
					0 -10 19 -10 43 0 24 -7 52 -15 63 -8 10 -15 25 -15 33 0 7 -8 21 -18 30 -10
					9 -21 24 -25 34 -3 9 -13 17 -22 17 -8 0 -15 5 -15 10 0 6 -9 10 -21 10 -11 0
					-27 7 -35 15 -9 8 -26 15 -39 15 -13 0 -27 5 -30 10 -3 6 -35 10 -70 10 -35 0
					-67 5 -70 10 -3 6 -24 10 -46 10 -37 0 -39 -2 -39 -30 0 -16 5 -30 10 -30 6 0
					10 -7 10 -15 0 -8 5 -15 10 -15 6 0 10 -13 10 -30 0 -16 5 -30 10 -30 6 0 10
					-135 10 -385 l0 -385 31 0 c59 0 59 -1 59 -310 l0 -281 -25 -24 -24 -25 -402
					0 -402 0 -23 28 c-24 27 -24 27 -24 305 0 306 -1 303 61 309 l34 3 5 395 c5
					385 6 396 28 433 12 21 22 49 22 63 l0 26 -67 -7 c-162 -18 -523 -7 -523 16 0
					5 -9 9 -21 9 -28 0 -49 23 -49 54 0 14 -4 26 -10 26 -5 0 -10 11 -10 25 0 14
					-4 25 -10 25 -5 0 -10 16 -10 35 0 19 -4 35 -10 35 -5 0 -10 9 -10 20 0 11 -7
					20 -15 20 -11 0 -15 11 -15 39 0 22 -4 43 -10 46 -5 3 -10 15 -10 26 0 10 -4
					19 -10 19 -5 0 -10 16 -10 35 0 19 -4 35 -10 35 -5 0 -10 11 -10 24 0 14 -4
					28 -10 31 -5 3 -10 17 -10 30 0 13 -7 30 -15 39 -8 8 -15 26 -15 40 0 14 -4
					28 -10 31 -5 3 -10 19 -10 36 0 16 -4 29 -10 29 -5 0 -10 16 -10 35 0 19 -4
					35 -10 35 -5 0 -10 14 -10 30 0 17 -4 30 -9 30 -5 0 -12 20 -15 45 -4 25 -11
					45 -17 45 -5 0 -9 13 -9 29 0 17 -4 33 -10 36 -5 3 -10 19 -10 36 0 16 -4 29
					-10 29 -5 0 -10 6 -10 14 0 19 -22 59 -45 82 -15 16 -37 20 -133 23 l-115 3 5
					-228z m3388 -1344 c0 -5 9 -10 19 -10 10 0 21 -7 25 -15 3 -8 15 -15 26 -15
					11 0 20 -3 20 -7 0 -11 50 -33 72 -33 10 0 18 -4 18 -10 0 -5 9 -10 20 -10 11
					0 20 -4 20 -10 0 -5 9 -10 19 -10 10 0 21 -7 25 -15 3 -8 15 -15 26 -15 11 0
					20 -4 20 -10 0 -5 9 -10 20 -10 11 0 20 -4 20 -10 0 -6 32 -10 75 -10 43 0 75
					4 75 10 0 6 20 10 45 10 25 0 45 5 45 10 0 6 20 10 45 10 33 0 45 4 45 15 0
					12 13 15 55 15 30 0 55 5 55 10 0 6 25 10 55 10 30 0 55 5 55 10 0 6 18 10 39
					10 22 0 43 5 46 10 3 6 24 10 46 10 21 0 39 5 39 10 0 6 20 10 45 10 33 0 45
					4 45 15 0 10 11 15 35 15 l35 0 0 -190 c0 -183 -1 -190 -20 -190 -11 0 -20 5
					-20 10 0 6 -5 10 -10 10 -10 0 -22 8 -55 38 -7 6 -20 12 -29 12 -8 0 -18 5
					-21 10 -3 6 -15 10 -26 10 -10 0 -19 5 -19 10 0 6 -9 10 -20 10 -11 0 -20 5
					-20 10 0 6 -9 10 -21 10 -11 0 -27 7 -35 15 -9 8 -26 15 -39 15 -13 0 -26 4
					-29 8 -18 30 -146 -27 -146 -65 0 -7 -4 -13 -10 -13 -6 0 -10 -26 -10 -57 1
					-57 16 -93 39 -93 6 0 11 -5 11 -11 0 -16 59 -71 95 -89 17 -8 36 -23 43 -32
					7 -10 28 -25 47 -34 19 -9 35 -20 35 -25 0 -5 8 -9 18 -9 10 0 24 -9 32 -20 8
					-11 20 -20 27 -20 7 0 23 -11 37 -25 14 -14 30 -25 37 -25 6 0 19 -8 28 -18 9
					-10 31 -25 49 -33 30 -13 32 -17 32 -66 l0 -51 -65 -7 c-36 -4 -65 -11 -65
					-16 0 -5 -20 -9 -45 -9 -25 0 -45 -4 -45 -10 0 -5 -25 -10 -55 -10 -30 0 -55
					-4 -55 -10 0 -5 -24 -10 -54 -10 -30 0 -56 -4 -58 -10 -1 -5 -29 -10 -61 -11
					-32 -1 -60 -5 -62 -9 -3 -4 -30 -11 -60 -15 -30 -3 -55 -11 -55 -16 0 -16
					-120 -11 -136 6 -9 8 -24 15 -34 15 -10 0 -22 5 -25 10 -3 6 -22 10 -41 10
					-19 0 -34 5 -34 10 0 6 -9 10 -20 10 -11 0 -20 5 -20 10 0 6 -16 10 -35 10
					-19 0 -35 5 -35 10 0 6 -13 10 -29 10 -16 0 -31 3 -33 8 -5 12 -77 42 -103 42
					-13 0 -27 5 -30 10 -3 6 -15 10 -26 10 -10 0 -19 5 -19 10 0 6 -13 10 -30 10
					-16 0 -30 5 -30 10 0 6 -20 10 -45 10 -25 0 -45 -4 -45 -10 0 -5 -13 -10 -29
					-10 -17 0 -33 -4 -36 -10 -3 -5 -15 -10 -26 -10 -10 0 -19 -4 -19 -10 0 -5 -9
					-10 -19 -10 -21 0 -84 -30 -89 -42 -2 -5 -8 -8 -13 -8 -5 0 -9 32 -9 70 0 58
					3 70 16 70 9 0 24 8 33 18 9 9 30 24 46 32 17 8 40 24 52 35 40 36 52 45 63
					45 5 0 10 5 10 10 0 6 8 10 18 10 10 0 24 9 32 20 8 11 22 20 31 20 10 0 26
					11 37 25 11 14 26 25 34 25 7 0 20 9 28 20 8 11 19 20 25 20 12 0 55 45 55 58
					0 4 9 14 20 22 17 12 20 26 20 82 0 39 -4 68 -10 68 -5 0 -10 5 -10 11 0 25
					-63 62 -105 62 -32 0 -115 -25 -115 -34 0 -5 -9 -9 -20 -9 -11 0 -20 -4 -20
					-10 0 -5 -9 -10 -20 -10 -11 0 -20 -4 -20 -10 0 -5 -11 -10 -25 -10 -14 0 -25
					-4 -25 -10 0 -5 -9 -10 -20 -10 -11 0 -20 -3 -20 -7 0 -11 -58 -43 -76 -43 -8
					0 -14 -4 -14 -10 0 -5 -4 -10 -10 -10 -6 0 -10 55 -10 145 0 138 1 145 20 145
					11 0 20 5 20 10 0 6 16 10 35 10 19 0 35 5 35 10 0 6 18 10 40 10 22 0 40 5
					40 10 0 6 16 10 35 10 24 0 35 5 35 15 0 12 13 15 55 15 30 0 55 5 55 10 0 6
					20 10 45 10 25 0 45 -4 45 -10z"
					/>
					<path
						d="M3658 4133 c-16 -2 -28 -9 -28 -14 0 -5 -9 -9 -20 -9 -11 0 -20 -4
					-20 -10 0 -5 -9 -10 -21 -10 -20 0 -53 -29 -94 -83 -11 -15 -24 -27 -28 -27
					-13 0 -37 -27 -37 -42 -1 -29 -27 -48 -69 -48 -22 0 -41 -4 -41 -10 0 -5 -20
					-10 -45 -10 -25 0 -45 -4 -45 -10 0 -5 -7 -10 -16 -10 -29 0 -64 -51 -64 -92
					0 -38 0 -38 40 -38 22 0 40 -4 40 -10 0 -5 24 -10 53 -10 29 0 57 -4 62 -9 12
					-12 115 -31 203 -38 34 -3 62 -9 62 -14 0 -5 25 -9 55 -9 30 0 55 -4 55 -10 0
					-5 25 -10 55 -10 30 0 55 -4 55 -10 0 -5 25 -10 55 -10 30 0 55 -4 55 -8 0
					-10 171 -42 223 -42 20 0 37 -4 37 -10 0 -5 25 -10 55 -10 30 0 55 -4 55 -10
					0 -6 26 -10 59 -10 33 0 63 -4 66 -10 3 -5 40 -10 81 -10 l74 0 0 40 c0 22 -4
					40 -10 40 -5 0 -10 9 -10 20 0 18 -60 90 -75 90 -3 0 -12 6 -21 13 -64 54 -74
					67 -74 95 0 17 -4 34 -10 37 -5 3 -10 15 -10 26 0 11 -7 22 -15 25 -8 4 -15
					14 -15 24 0 11 -4 22 -10 25 -5 3 -10 13 -10 21 0 19 -136 154 -155 154 -8 0
					-15 5 -15 10 0 6 -11 10 -24 10 -13 0 -26 6 -28 13 -4 9 -54 12 -204 14 -109
					0 -211 -1 -226 -4z"
					/>
					<path
						d="M1465 4060 c-3 -5 -15 -10 -26 -10 -11 0 -27 -6 -34 -12 -35 -32 -45
					-38 -60 -38 -17 0 -105 -83 -105 -100 0 -5 -5 -10 -11 -10 -16 0 -39 -26 -39
					-44 0 -9 -4 -16 -10 -16 -5 0 -10 -7 -10 -15 0 -8 -4 -15 -10 -15 -5 0 -10
					-13 -10 -30 0 -16 -4 -30 -10 -30 -5 0 -10 -18 -10 -41 0 -23 -7 -47 -15 -55
					-10 -10 -15 -35 -15 -75 0 -44 4 -61 15 -65 10 -4 15 -20 15 -50 0 -24 5 -44
					10 -44 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10 -11 10 -25 0 -14 5 -25 10
					-25 6 0 10 -7 10 -16 0 -15 23 -44 35 -44 12 0 35 -29 35 -45 0 -8 8 -19 18
					-22 9 -4 24 -15 33 -25 9 -10 21 -18 28 -18 6 0 11 -4 11 -10 0 -5 8 -10 19
					-10 10 0 24 -7 31 -15 7 -8 23 -15 36 -15 13 0 24 -4 24 -10 0 -5 25 -10 55
					-10 30 0 55 -4 55 -10 0 -6 26 -10 59 -10 33 0 63 5 66 10 3 6 24 10 46 10 21
					0 39 5 39 10 0 6 14 10 30 10 18 0 33 6 36 15 4 8 15 15 25 15 10 0 19 5 19
					10 0 6 5 10 11 10 9 0 86 67 113 98 6 7 21 24 34 37 12 13 22 33 22 45 0 11 5
					20 10 20 6 0 10 9 10 19 0 11 5 23 10 26 6 3 10 19 10 35 0 15 6 30 13 33 8 2
					12 34 12 112 0 78 -4 110 -12 113 -7 2 -13 15 -13 28 0 13 -4 24 -10 24 -5 0
					-10 14 -10 30 0 17 -4 30 -10 30 -5 0 -10 8 -10 18 0 9 -10 29 -23 44 -12 15
					-35 43 -51 63 -15 19 -33 35 -40 35 -6 0 -18 9 -26 20 -8 11 -20 20 -26 20 -7
					0 -14 3 -16 8 -6 13 -65 42 -87 42 -12 0 -21 5 -21 10 0 6 -58 10 -154 10 -93
					0 -157 -4 -161 -10z"
					/>
					<path
						d="M3336 3630 c-17 -17 -18 -31 -14 -127 3 -68 9 -108 16 -111 7 -2 12
					-11 12 -21 0 -22 23 -71 33 -71 4 0 7 -8 7 -18 0 -10 9 -24 20 -32 11 -8 20
					-21 20 -29 0 -17 55 -71 73 -71 7 0 21 -11 31 -25 10 -14 26 -25 35 -25 9 0
					23 -9 31 -20 9 -13 26 -20 47 -20 18 0 33 -4 33 -10 0 -5 18 -10 39 -10 22 0
					43 -4 46 -10 3 -5 33 -10 66 -10 33 0 59 4 59 10 0 6 20 10 45 10 25 0 45 5
					45 10 0 6 8 10 18 10 22 0 72 22 72 33 0 4 9 7 20 7 11 0 20 7 20 15 0 8 7 15
					15 15 8 0 15 5 15 10 0 6 7 10 16 10 20 0 44 24 44 44 0 9 5 16 10 16 6 0 10
					7 10 15 0 8 5 15 11 15 15 0 39 26 39 44 0 8 5 18 10 21 6 3 10 15 10 26 0 10
					5 19 10 19 6 0 10 9 10 19 0 11 5 23 10 26 6 3 10 22 10 40 0 28 -4 36 -22 39
					-41 9 -102 17 -160 23 -32 2 -58 9 -58 14 0 5 -25 9 -55 9 -30 0 -55 5 -55 10
					0 6 -25 10 -55 10 -30 0 -55 5 -55 10 0 6 -24 10 -54 10 -31 0 -56 5 -58 11
					-2 6 -36 14 -76 17 -40 2 -72 9 -72 13 0 5 -25 9 -55 9 -30 0 -55 5 -55 10 0
					6 -25 10 -55 10 -30 0 -55 5 -55 10 0 17 -34 10 -54 -10z"
					/>
					<path
						d="M1520 2980 c0 -6 -27 -10 -60 -10 -51 0 -65 -4 -85 -25 -14 -13 -25
					-33 -25 -45 0 -11 -7 -20 -15 -20 -8 0 -15 -9 -15 -20 0 -11 -4 -20 -10 -20
					-7 0 -10 -142 -10 -405 l0 -405 84 0 c86 0 96 5 96 47 0 35 26 43 134 43 88 0
					106 -3 106 -15 0 -8 5 -15 10 -15 6 0 10 -13 10 -30 l0 -30 90 0 90 0 0 390
					c0 338 -2 391 -15 396 -8 4 -15 17 -15 30 0 25 -15 47 -64 95 -22 22 -39 29
					-67 29 -22 0 -39 5 -39 10 0 6 -40 10 -100 10 -60 0 -100 -4 -100 -10z"
					/>
					<path
						d="M3450 2933 c-25 -2 -49 -8 -54 -14 -6 -5 -18 -9 -28 -9 -10 0 -18 -7
					-18 -15 0 -9 -9 -15 -23 -15 -12 0 -29 -9 -37 -20 -8 -11 -22 -20 -32 -20 -27
					0 -25 -56 2 -65 25 -8 80 3 80 16 0 20 21 11 65 -28 7 -7 23 -13 34 -13 12 0
					21 -4 21 -10 0 -5 9 -10 20 -10 11 0 20 -4 20 -10 0 -5 9 -10 20 -10 11 0 20
					-3 20 -7 0 -5 19 -17 43 -28 23 -11 44 -22 47 -25 3 -3 29 -16 57 -30 29 -14
					53 -28 53 -32 0 -12 150 -10 150 2 0 6 15 10 34 10 19 0 38 5 41 10 3 6 24 10
					46 10 21 0 39 5 39 10 0 6 20 10 44 10 25 0 48 5 51 10 3 6 18 10 33 10 15 0
					40 7 56 15 15 8 44 15 62 15 19 0 34 5 34 10 0 6 18 10 41 10 22 0 47 6 55 14
					16 17 19 66 4 66 -5 0 -10 6 -10 13 0 7 -12 21 -27 32 -14 11 -32 26 -39 33
					-7 6 -19 12 -27 12 -8 0 -17 7 -21 15 -3 8 -14 15 -24 15 -10 0 -24 6 -30 13
					-10 9 -102 12 -385 13 -205 1 -392 -1 -417 -3z"
					/>
					<path
						d="M1480 2022 c0 -4 -10 -12 -22 -17 -14 -6 -24 -21 -26 -37 l-3 -28
					-95 0 c-56 0 -94 -4 -94 -10 0 -5 -5 -10 -11 -10 -7 0 -18 -8 -25 -17 -19 -26
					-19 -420 0 -445 12 -17 43 -18 390 -18 314 0 380 2 392 14 11 12 14 58 14 237
					0 199 -2 223 -17 235 -12 9 -51 13 -120 14 l-103 0 0 31 c0 53 -15 59 -154 59
					-69 0 -126 -3 -126 -8z m170 -182 c0 -5 9 -10 20 -10 12 0 36 -16 55 -35 34
					-33 35 -37 35 -110 0 -43 -4 -75 -10 -75 -5 0 -10 -7 -10 -16 0 -18 -23 -44
					-40 -44 -6 0 -13 -7 -16 -15 -8 -19 -127 -21 -150 -2 -19 16 -75 72 -91 91
					-15 18 -17 96 -3 96 6 0 10 6 10 14 0 26 35 76 53 76 9 0 17 5 17 10 0 6 9 10
					20 10 11 0 20 5 20 10 0 6 20 10 45 10 25 0 45 -4 45 -10z"
					/>
					<path
						d="M1513 1795 c-7 -8 -13 -19 -13 -25 0 -5 -4 -10 -10 -10 -5 0 -10 -9
					-10 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -18 -10 -40 0 -22 5 -40 10 -40 6 0 10
					-8 10 -18 0 -21 29 -52 48 -52 7 0 12 -4 12 -10 0 -6 28 -10 65 -10 37 0 65 4
					65 10 0 6 5 10 10 10 6 0 22 11 35 25 22 21 25 33 25 90 0 69 -10 89 -57 114
					-32 16 -155 14 -170 -4z"
					/>
					<path
						d="M3195 1543 c-6 -5 -9 -109 -7 -285 l3 -278 659 0 660 0 0 229 c0 127
					-4 232 -9 235 -13 8 -115 -13 -119 -24 -2 -6 -28 -10 -58 -10 -30 0 -54 -4
					-54 -10 0 -5 -20 -10 -45 -10 -25 0 -45 -4 -45 -10 0 -5 -25 -10 -55 -10 -30
					0 -55 -4 -55 -10 0 -5 -22 -10 -48 -10 -26 0 -53 -5 -58 -10 -15 -15 -142 -16
					-157 -1 -6 6 -21 11 -33 11 -13 0 -26 5 -29 10 -3 6 -17 10 -31 10 -13 0 -24
					5 -24 10 0 6 -13 10 -30 10 -16 0 -30 5 -30 10 0 6 -14 10 -31 10 -17 0 -37 7
					-45 15 -9 8 -24 15 -34 15 -10 0 -22 5 -25 10 -3 6 -19 10 -36 10 -16 0 -29 5
					-29 10 0 6 -11 10 -24 10 -14 0 -28 5 -31 10 -3 6 -17 10 -31 10 -13 0 -24 5
					-24 10 0 6 -13 10 -29 10 -16 0 -31 4 -33 9 -4 13 -51 23 -63 14z"
					/>
				</g>
			</symbol>
		</svg>
	`,
})
export class PeopleIcons {}
