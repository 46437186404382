import { Component } from '@angular/core';

@Component({
	selector: 'i-country-morocco',
	template: `
		<svg>
			<symbol id="bab-boujloud">
				<g>
					<path
						d="M139.9,329.6c-3.5,0.2-6.3,0.3-9.9,0.5c0,60.7,0,121.1,0,181.9c-9.7,0-18.6,0-28.2,0c0-37.1,0-74,0-111.2
						c-3.5-0.3-6.3-0.5-9.5-0.7c0-8.5,0.9-16.8-0.3-24.8c-0.9-6-3.8-12.1-7.1-17.2c-8.6-13-24.9-13.2-34.3-0.7
						c-7.7,10.3-10.1,22-8.6,34.7c0.3,2.5,0.7,5,1.1,8.1c-3.8,0.2-7.2,0.3-11.3,0.5c0,37.1,0,74.1,0,111.4c-9.7,0-18.7,0-27.9,0
						c0-170.4,0-340.6,0-511.4c3.2,0,6.1-0.3,9,0.1c1.5,0.3,3.4,1.7,4,3.1c1.8,4.5,2.8,9.4,4.8,13.9c0.7,1.6,3.1,3.4,4.9,3.6
						c10.6,0.9,10.6,0.7,13.8-9.2c0.8-2.6,1.4-5.4,2.6-7.8c0.8-1.5,2.7-3.3,4.2-3.5c1.1-0.2,3.1,1.9,3.7,3.3c1.7,4.2,3.1,8.5,4.2,12.9
						c0.8,3.2,2.3,4.4,5.7,4.5C74.1,21.9,74.1,22,78.1,9.5c0.7-2.1,1.1-4.3,2.1-6.1c0.7-1.2,2.4-2.6,3.7-2.6c1.3,0,3.1,1.4,3.6,2.7
						c1.8,4.6,2.8,9.5,4.9,13.9c0.8,1.8,3.3,3.8,5.2,3.9c10.5,0.8,10.5,0.6,13.7-9.3c0.9-2.8,1.6-5.7,2.8-8.4c0.6-1.3,2.4-2.9,3.5-2.8
						c1.5,0.2,3.5,1.6,4.1,3c1.9,4.5,2.9,9.4,4.8,13.9c0.7,1.6,3.1,3.5,4.8,3.6c10.8,0.9,10.8,0.8,14.1-9.6c0.8-2.4,1.2-5,2.4-7.2
						c0.9-1.6,2.8-3.6,4.1-3.5c1.4,0.1,3.3,2.2,4,3.8c1.7,4.2,2.5,8.7,4.4,12.8c0.8,1.8,3.4,3.7,5.3,3.8c10.5,0.8,10.5,0.6,13.8-9.4
						c0.7-2.2,1.1-4.7,2.2-6.7c1-1.8,2.8-3.1,4.2-4.6c1.5,1.6,3.6,3,4.5,4.9c1.6,3.6,2.8,7.4,3.6,11.2c0.9,3.8,2.9,4.8,6.6,4.8
						c12.5,0,12.5,0.1,16.3-11.7c0.7-2.1,1-4.3,2.1-6.1c0.9-1.4,2.6-2.8,4.1-2.9c1.1-0.1,3,1.6,3.5,2.9c1.7,4.4,3.1,8.9,4.3,13.5
						c0.8,3.1,2.4,4.4,5.8,4.4c10.4,0.1,10.4,0.3,13.7-9.8c0.9-2.8,1.5-5.7,2.8-8.4c0.6-1.2,2.4-2.6,3.7-2.6c1.2,0,3.1,1.4,3.6,2.6
						c1.5,3.4,2.8,7,3.5,10.6c1.1,5.8,4,7.7,10.1,7.6c5.8-0.1,8.5-1.9,9.7-7.4c0.7-3.6,2-7.2,3.5-10.6c0.5-1.3,2.4-3,3.4-2.9
						c1.5,0.1,3.5,1.5,4,2.9c1.8,4.3,3.3,8.9,4.5,13.4c0.8,3.2,2.4,4.3,5.8,4.4c10.3,0.1,10.3,0.3,13.5-9.8c0.9-2.8,1.6-5.7,2.9-8.4
						c0.6-1.2,2.4-2.6,3.7-2.6c1.3,0,3.2,1.4,3.7,2.6c1.5,3.4,2.9,7,3.5,10.6c1,6,4,7.8,10.1,7.6c5.3-0.2,8.5-1.1,9.4-6.9
						c0.6-3.6,2-7.2,3.5-10.6c0.6-1.5,2.6-3.5,3.7-3.3c1.6,0.2,3.6,1.9,4.2,3.5c1.8,4.3,2.7,9.1,4.6,13.4c0.7,1.6,3.1,3.5,4.8,3.6
						c10.8,0.9,10.8,0.8,14.2-9.6c0.8-2.4,1.2-5,2.5-7.2c0.9-1.6,2.8-3.6,4.1-3.5c1.4,0.1,3.3,2.1,4,3.8c1.7,4.2,2.5,8.7,4.4,12.8
						c0.8,1.8,3.4,3.7,5.3,3.8c10.5,0.8,10.5,0.6,13.7-9.4c0.6-1.9,0.9-3.9,1.9-5.6c1.2-2.1,2.7-4.9,4.6-5.5c4-1.2,3.8,2.9,4.7,5.2
						c1.4,3.7,2.7,7.4,3.6,11.2c0.8,3.3,2.6,4.2,5.9,4.3c10.2,0.1,10.2,0.2,13.3-9.4c0.8-2.4,1.2-5.1,2.5-7.2c1-1.7,2.7-3.6,4.4-4
						c0.9-0.2,3.1,2.2,3.8,3.8c1.7,4,3.1,8.1,4.1,12.3c0.9,3.7,2.8,4.5,6.3,4.5c10,0,10,0.1,12.9-9.1c4.3-13.4,4.6-13.6,19-11.5
						c0.2,0,0.3,0.2,0.7,0.6c0,169.8,0,339.8,0,510.3c-9.2,0-18.3,0-27.8,0c0-37,0-73.8,0-111.1c-3.2-0.2-6.1-0.5-8.3-0.6
						c-0.4-8.6,0-17.1-1.4-25.2c-0.9-5.6-3.5-11.3-6.6-16.2c-9.1-14.2-26.1-14.1-35.7-0.2c-6.7,9.8-9,20.8-7.8,32.6
						c0.3,2.9,0.7,5.8,1.2,9c-3.9,0.2-7.3,0.4-11.2,0.6c0,37.1,0,73.9,0,111.2c-9.6,0-18.7,0-28.2,0c0-60.5,0-120.8,0-181.8
						c-4.2,0-8,0-12,0c0-12.4,0.7-24.2-0.1-35.9c-3.3-43.8-17.9-82.8-52.6-111.7c-42.9-35.7-101.1-30.6-138,11.4
						c-30.5,34.7-42.5,75.6-40.4,121.1C139.2,319.8,139.6,324.5,139.9,329.6z M479.9,71c-149.5,0-298.6,0-447.7,0
						c0,81.8,0,163.2,0,244.6c29,0,57.5,0,86.4,0c-0.3-3.8-0.5-7.1-0.8-10.4c-3.3-40.1,4.9-77.6,25.9-112c19.8-32.4,47.1-55.9,84.7-64.3
						c45.1-10.1,83.7,4,115.5,36.7c30.4,31.3,44.7,69.9,47.5,113c0.8,12.3,0.1,24.6,0.1,37.3c29.2,0,58.6,0,88.4,0
						C479.9,234,479.9,152.7,479.9,71z"
					/>
					<path
						d="M465.8,301.9c-6.7,0-13.1,0-20.3,0c0-65.1,0-130.2,0-195.7c-126.6,0-252.4,0-378.8,0c0,65.2,0,130.1,0,195.5
						c-7.1,0-13.7,0-20.7,0c0-72,0-144.1,0-216.6c139.8,0,279.6,0,419.9,0C465.8,157.3,465.8,229.4,465.8,301.9z"
					/>
					<path
						d="M80.6,224.4c0-8,0-15.6,0-23.3c0-12.8-0.1-25.6,0-38.4c0.1-8.9,6.1-15.1,14-15.1c8,0,14,6.2,14.1,15
						c0.1,20.4,0,40.9,0,61.7C99.4,224.4,90.5,224.4,80.6,224.4z"
					/>
					<path
						d="M430.8,224.5c-9,0-17.8,0-27.2,0c-0.1-1.7-0.3-3.4-0.3-5.1c0-18.5,0-37,0-55.6c0-9.8,5.6-16.3,14-16.2
						c8.1,0,13.9,6.4,14,15.8c0.1,19.1,0,38.2,0,57.3C431.3,221.9,431,223.1,430.8,224.5z"
					/>
				</g>
			</symbol>

			<symbol id="slipper">
				<g>
					<path
						d="M2.1,104.7c9.8,16.4,25.6,20.6,42.8,21.7c11.5,0.7,23.1,0.9,34.7,1.2c1.4,0,3.2-0.4,4.3-1.3c7.2-5.7,15.7-6.8,24.4-6.8
						c2.2,0,4.4-0.2,6.5,0.2c14,2.7,26.3-2.4,38.1-8.9c33-18.1,65.9-36.4,98.8-54.8c3.8-2.1,6-1.9,9,1.2
						c53.7,55.9,105.3,113.7,152.8,174.9c23.3,30.1,45.4,61.2,62.9,95.1c15.3,29.6,25.7,61.1,33.4,93.4c1.5,6.4,1.3,13.2-3.8,18.2
						c-1.9,1.8-5.6,3.2-8.1,2.8c-46.4-8.2-91.9-20.2-136.1-36.8c-34.6-13-68.3-28-100.4-46.2c-24.8-14.1-48.2-30.2-70.4-48.1
						c-2.1-1.7-3.8-3.9-5.4-6.1c-18.8-24.9-36.1-51-59.5-72c-10.8-9.6-23.1-17.5-34.6-26.3c-24.8-18.9-49.5-37.9-74.1-56.9
						C2.6,138.1,0.2,122.2,2.1,104.7z M275.5,263c0.5-0.5,1-0.9,1.4-1.4c-1.3-1.5-2.4-3.2-3.9-4.4c-6.1-4.9-8.9-11.4-9.8-18.8
						c-1.1-8.6-1.7-17.2-2.8-25.7c-1.3-9.9-1.9-20-4.4-29.6c-4-15.1-17.8-23.8-32.8-21.4c-11.4,1.9-20.2,8.1-24.1,19.2
						c-3.8,10.8-0.5,20.5,7.6,28.4c1.4,1.3,4.5,2.4,5.9,1.7c7-3.4,7.3-12.8,0.8-17.1c-1.1-0.8-2.3-1.5-3.4-2.4
						c-5.9-4.7-6.9-9.3-3.4-14.7c3.8-5.9,10.6-8.3,16.2-4.3c5.1,3.7,10.6,8.4,12.8,13.9c4.6,11.4,7,23.6,10.5,35.4
						c2.1,7.1,4.2,14.3,7,21.2C257.5,253.2,265.6,259.2,275.5,263z M297,244.3c2.4,2.4,4.6,4.3,6.4,6.5c11.3,13.5,22.4,27.2,33.8,40.7
						c2.3,2.7,5.5,4.7,8.3,7.1c0.5-0.5,0.9-1,1.4-1.4c-16.8-20.6-25.6-46.5-44.2-66.6c-5.3,5.7-10.4,10.6-10.2,18.9
						C293.8,247.9,295,246.6,297,244.3z"
					/>
					<path
						class="st0"
						d="M243.9,53.8C218.4,68,193,82.3,167.4,96.4c-8.1,4.5-16.5,8.2-24.6,12.7c-8.5,4.7-17.4,6.3-27.1,5.1
						c-11.2-1.4-22.4-1.2-31.9,6.6c-0.7,0.6-1.8,0.8-2.7,0.8c-15.2-0.9-30.5-1.2-45.6-3c-10.1-1.2-18.9-6.4-25.1-15
						c-2.8-3.8-2.9-7.1,0.2-11.1c6.4-8.2,15-13.1,24.3-17c24.3-10.2,50-14.7,75.9-18.2c37.8-5.1,75.8-4.7,113.9-4.5
						c6.2,0,12.5-0.2,18.7-0.3C243.5,52.9,243.7,53.4,243.9,53.8z"
					/>
					<path
						class="st1"
						d="M490.4,455.9c-8.2,4.5-17.2,6.9-26.3,4.9c-47.7-10.5-94.5-24.7-139.8-42.9c-26.9-10.8-53.1-23.5-78.6-37.2
						c-23.4-12.6-44.1-29.2-59.2-51.8c-0.3-0.4-0.4-1-0.3-2.1C276.4,396.1,380,433.5,490.4,455.9z"
					/>
				</g>
			</symbol>

			<symbol id="tajine">
				<g>
					<path
						d="M499.1,300.6c-14.4-15.4-32-25.8-51.2-33.5c-14.4-5.8-28.8-10.8-37.6-24.8c-0.3-0.4-0.8-0.7-1.1-1.1
					c-16.5-18.1-33.2-36-49.5-54.2c-18.9-21.1-37.5-42.4-56.2-63.7c-5.3-6-10.5-12.1-16-18.5c9-5.3,11.4-13.2,10.8-22.6
					c-0.3-4.7-0.1-9.4-0.1-14.1c0-9-5-16.3-13.4-19.3c-17.3-6.2-35-6.2-52.5-1.7c-13.6,3.5-18.4,10.5-18.4,24.4c0,4,0.3,8.1,0,12.1
					c-0.6,9,2.5,16.1,10.9,21.2c-4.4,4.9-8.4,9.4-12.2,14.1c-20.7,23.7-41.3,47.5-62.3,71c-18.5,20.6-37.3,40.8-56.1,61.1
					c-2.8,3.1-5.8,6.3-9.4,8.1c-5.3,2.7-11.3,4.1-16.8,6.4c-20,8.4-39.1,18.4-54.1,34.7c-15.8,17.2-19.2,39.3-3.9,58.7
					c13.9,17.6,32.9,28.1,52.9,37c5.9,2.6,13.3,3.9,17.5,8.2c26.7,27.9,59.9,44,96.5,53.6c56.6,14.8,113.2,13.9,169.2-3
					c31.1-9.4,59.5-24.1,82.8-47.5c3.3-3.4,7.9-5.9,12.4-7.8c21.2-9.2,41.6-19.2,57.8-36.4C516.3,344.5,516.3,319,499.1,300.6z
					M476,332.1c-11.9,9-25.6,14.4-39.6,19c-26.2,8.5-53.1,13.4-80.4,17c-42.5,5.6-85.2,7.1-128,5.7c-45.9-1.5-91.3-6-135.8-17.9
					c-19.8-5.2-39.3-11.5-56-23.9c-5.7-4.2-10.8-9.2-12.6-16.4c-2.7-10.5,2.9-18.2,9.8-24.8c10.4-10,22.8-16.9,36-22.5
					c3.9-1.7,7.9-3.4,12.4-4.4c-3.2,3.6-6.3,7.3-9.6,10.9c-8,8.8-16.1,17.6-24.2,26.3c-5.9,6.4-6,12.8,0,19.1
					c8.6,8.8,19.8,12.8,31.1,16.6c26,8.7,53,12.8,80.2,16c40.6,4.7,81.3,6.1,122.1,4.9c45.4-1.3,90.6-4.9,134.9-16
					c14.3-3.6,28.5-7.7,41-15.9c2.2-1.5,4.3-3.2,6.2-5.1c6.6-6.7,6.8-12.9,0.5-19.8c-10.3-11.4-20.7-22.8-31.1-34.2
					c-0.6-0.6-1.1-1.2-1.6-1.8c0.3-0.5,0.5-0.9,0.7-1.4c5.4,2.4,10.9,4.6,16.3,7.2c11.9,5.8,23.4,12.2,32.6,22
					C494.8,307.7,491,320.7,476,332.1z"
					/>
				</g>
			</symbol>

			<symbol id="tarboosh">
				<g>
					<path
						d="M34.3,134.3c5.1,14.9,16.3,23.3,28.8,30.1c24.9,13.7,52.1,20.6,79.7,25.9c43.9,8.3,88.3,10.8,132.9,9.8
						c8.4-0.2,16.9-0.8,26.1-1.2c0.8,13.7,1.7,26.9,2.2,40.2c1.2,32.2,0.9,64.3-4.6,96.1c-1.8,10.6-5.3,21-8.3,32.4
						c3.7,1.9,8.4,4.5,13.2,7c1.2-2.4,2.3-4.6,3.5-6.9c8.6,5.6,9.2,5.5,11.5,0c5.7,1.2,11.3,3.6,16.5,3.1c6.8-0.7,5.3-8.7,8.4-13.9
						c2,1.7,3.6,3.4,5.6,4.6c3,1.7,6.1,3.2,9.3,4.5c2.6,1.1,4.3,0.2,5.5-2.8c2-5.4,4.3-5.8,9.2-2.4c6.9,4.9,11.4,4,14.9-3.6
						c1.9-4,3.8-5.4,7.9-2.9c4.3,2.6,8.4,3.2,12.1-1.5c1.9-2.4,6.7-3.7,7-5.9c0.4-2.6-3.3-5.5-4.2-8.6c-2.1-7.4-3.9-14.9-5.3-22.4
						c-7.1-40.7-14.1-81.4-21.1-122.1c-0.3-2-0.4-4-0.6-6.2c11.4-3.3,22.7-6.3,33.9-9.8c15.2-4.8,29.7-11.1,42.6-20.5
						c8.1-5.9,14.8-12.8,17.8-23c1.6,11.7,3.2,23.3,4.8,35c4.7,35.1,9.6,70.1,14.1,105.2c3.3,25.4,6.5,50.9,9,76.4
						c2.7,28.8-11.2,49.9-33.1,66.7c-25.9,19.9-55.6,31.6-86.7,40.2c-54.5,15-110,18.9-166.3,15.6c-40.6-2.4-80.3-9.1-118.8-22.4
						c-24.1-8.3-47.2-19-67.1-35.3c-13.1-10.6-24.3-23.7-28.3-41c-1.5-6.5-1.7-13.6-0.8-20.2C15,281.1,24.7,207.7,34.3,134.3z"
					/>
					<path
						d="M300.1,179c-14.2,0.4-27.8,1.2-41.4,1.2c-40.4,0.1-80.5-2.9-120.1-11.2c-25-5.2-49.7-11.7-71.8-25
						c-6.9-4.1-13.6-9.2-18.9-15.1c-10.6-12-9.7-25.6,1.6-37.1c11.9-12.2,27-19.1,42.6-24.9c36.5-13.5,74.5-19.1,113-22.2
						c48.3-3.9,96.4-2.5,144.3,4.7c29.5,4.4,58.5,10.8,85.7,23.5c11.2,5.2,22,11.2,30.1,21c9.8,11.9,9.7,24.5-0.6,36
						c-10.1,11.3-23.4,18.1-37.1,23.5c-12.3,4.8-25.1,8.3-37.8,12.3c-4.1,1.3-7.4,1.1-11.7-1.5c-13.5-8.1-27.7-15.3-41.6-22.7
						C320.3,133,304,124.7,288,116c-6.4-3.5-11.9-8.8-18.5-11.6c-4.8-2-10.7-1.9-16-1.8c-4.8,0.1-6.7,4-7.3,8.4c-0.7,4.7,1.5,8,5.7,9.6
						c3.8,1.4,7.8,2.9,11.7,3c14.7,0.4,23.1,7.6,27.5,21.5C294.5,156.1,298.8,166.8,300.1,179z"
					/>
				</g>
			</symbol>

			<symbol id="tea-pot">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M2510 4642 c-40 -20 -80 -83 -80 -127 0 -39 33 -111 58 -126 19 -12
					19 -14 -12 -44 -25 -26 -41 -33 -84 -37 -60 -6 -84 -24 -84 -62 0 -41 19 -56
					73 -56 27 0 49 -3 49 -6 0 -3 -7 -34 -16 -67 -12 -47 -26 -73 -59 -108 -70
					-75 -103 -151 -156 -366 -40 -166 -42 -169 -103 -189 -28 -9 -65 -29 -81 -45
					-24 -23 -40 -29 -74 -29 -35 0 -48 -6 -70 -29 -32 -34 -76 -158 -86 -243 l-7
					-58 -99 -30 c-98 -30 -99 -31 -92 -58 10 -42 31 -69 68 -86 30 -15 126 -16
					944 -14 l910 3 36 35 c28 27 35 41 33 64 -3 29 -8 32 -93 58 l-90 27 -18 93
					c-19 100 -52 183 -86 217 -14 14 -34 21 -60 21 -30 0 -46 7 -69 30 -17 17 -49
					34 -77 41 -63 16 -75 39 -119 215 -41 169 -88 275 -149 341 -30 32 -48 63 -55
					95 -21 83 -19 88 36 88 53 0 82 20 82 56 0 41 -41 64 -114 64 -32 0 -47 6 -63
					24 -11 13 -27 26 -34 29 -10 4 -8 10 10 22 27 21 61 89 61 125 0 43 -36 106
					-72 128 -43 27 -110 29 -158 4z"
					/>
					<path
						d="M4491 3049 c-191 -37 -345 -138 -478 -313 -42 -56 -89 -115 -103
					-132 -27 -31 -27 -31 -51 -13 -13 10 -34 19 -45 19 -37 0 -49 -27 -46 -98 l3
					-66 -47 -47 -47 -47 -37 27 c-20 14 -57 35 -81 45 -88 36 -84 28 -74 163 6 87
					8 93 29 93 57 0 88 67 48 103 -17 16 -99 17 -974 17 -526 0 -964 -3 -973 -6
					-21 -8 -35 -45 -28 -72 5 -22 42 -42 79 -42 70 0 82 -246 21 -425 -36 -105
					-82 -200 -145 -302 -57 -92 -57 -92 -98 -93 -56 0 -204 44 -279 83 -86 45
					-210 172 -259 267 -22 41 -79 176 -126 300 l-87 225 22 17 c12 10 25 30 28 45
					3 15 9 36 12 46 7 16 -15 17 -369 17 -207 0 -376 -3 -376 -6 0 -3 13 -17 30
					-31 16 -13 30 -31 30 -39 0 -16 50 -44 78 -44 54 0 218 -256 315 -490 54 -132
					75 -211 107 -400 17 -96 39 -202 50 -235 68 -202 219 -333 458 -395 78 -20
					215 -40 279 -40 l62 0 35 -76 c51 -109 121 -209 201 -287 80 -78 231 -183 326
					-226 551 -248 1227 -102 1556 338 32 42 60 80 64 85 4 5 22 -2 41 -15 40 -29
					102 -32 140 -7 68 45 112 175 106 314 l-4 91 86 6 c47 4 120 16 163 27 l78 21
					30 -26 c32 -27 83 -33 96 -12 4 6 10 29 13 50 5 35 9 40 65 65 304 135 560
					372 658 606 39 94 49 252 22 352 -31 115 -133 246 -274 352 l-76 58 22 33 c13
					19 23 42 23 52 0 25 -37 57 -79 69 -43 12 -158 11 -220 -1z m23 -320 c98 -36
					264 -173 314 -259 24 -39 27 -56 26 -125 -1 -93 -20 -143 -90 -242 -78 -109
					-248 -240 -429 -331 l-81 -40 -41 39 c-70 65 -113 48 -113 -45 l0 -45 -57 -13
					c-77 -17 -324 -18 -345 0 -9 7 -21 32 -28 57 -12 44 -12 45 20 82 52 59 92
					155 98 233 4 67 5 69 61 124 l57 56 32 -15 c56 -28 89 10 80 90 -3 31 2 43 41
					88 24 29 80 99 125 156 100 130 138 168 190 192 53 24 73 24 140 -2z"
					/>
				</g>
			</symbol>
		</svg>
	`,
})
export class CountryMoroccoIcons {}
