import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RxState, selectSlice } from '@rx-angular/state';
import { map } from 'rxjs/operators';

import { ViewTypeEnum } from 'src/app/app-routing.module';
import { AppContext } from 'src/app/app.context';

// @ Typings
enum LangEnum {
	AR,
	EN,
	FR,
}

// @ Component state
interface ComponentState {
	currentViewType: ViewTypeEnum;
	langOptions: Array<{
		identifier: LangEnum;
		value: string;
		imageSrc: string;
	}>;
}

@Component({
	selector: 'header-layout',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	providers: [RxState],
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
	// •) component state

	private readonly INITIAL_STATE: Partial<ComponentState> = {
		langOptions: [
			{
				identifier: LangEnum.AR,
				value: 'العربية',
				imageSrc: '/assets/images/lang/lang-ar.png',
			},
			{
				identifier: LangEnum.EN,
				value: 'English',
				imageSrc: '/assets/images/lang/lang-en.png',
			},
			{
				identifier: LangEnum.FR,
				value: 'Français',
				imageSrc: '/assets/images/lang/lang-fr.png',
			},
		],
	};

	// • view states

	readonly viewModel$ = this.componentState.select(
		selectSlice(['currentViewType', 'langOptions']),
		map(({ currentViewType, langOptions }) => ({
			isHomePage: currentViewType === ViewTypeEnum.HOME,
			langOptions,
		}))
	);

	// {*} Initialization

	constructor(private readonly componentState: RxState<ComponentState>, private readonly appContext: AppContext) {
		this.componentState.set(this.INITIAL_STATE);
	}

	ngOnInit(): void {
		this.connectStates();
	}

	private connectStates() {
		this.componentState.connect('currentViewType', this.appContext.viewType$);
	}
}
