import { isString } from '../type/primitive.utility';
import { isArray } from '../type/reference.utility';
import { isEmpty, isntEmpty } from './is-empty.utility';

/**
 * Checks whether the given argument is blank (contains empty values)
 *
 * @param param
 * @returns
 */
export function isBlank(param: any): boolean {
	const isArgEmpty = isEmpty(param);

	if (isString(param)) {
		// – checks whether the given string argument is empty, or contains only whitespace characters
		return isArgEmpty || param.match(/^\s*$/) !== null;
	}

	if (isArray(param)) {
		// – checks whether the given array argument is empty, or contains only empty values
		return isArgEmpty || param.filter(isntEmpty).length === 0;
	}

	// – checks whether the given argument is empty
	return isArgEmpty;
}
/** @function complement(isBlank) */
export function isntBlank<T>(param: T | undefined | null): param is T {
	return !isBlank(param);
}
