<svg
	class="svg-definitions__component u-hidden"
	version="1.1"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	viewBox="0 0 512.000000 512.000000"
	preserveAspectRatio="xMidYMid meet"
>
	<i-arithmetic-sign></i-arithmetic-sign>
	<i-arrow></i-arrow>
	<i-audio-and-video></i-audio-and-video>
	<i-communication></i-communication>
	<i-country-morocco></i-country-morocco>
	<i-currency></i-currency>
	<i-date-and-time></i-date-and-time>
	<i-interfaces></i-interfaces>
	<i-location></i-location>
	<i-people></i-people>
	<i-religion></i-religion>
	<i-social-network></i-social-network>

	<ng-content></ng-content>
</svg>
