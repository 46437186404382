import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EllipsisModule } from 'ngx-ellipsis';

import {
	AccordionComponent,
	CardItemComponent,
	CarouselComponent,
	CarouselItemComponent,
	CarouselItemDirective,
	ExpansionComponent,
	ExpansionContentComponent,
	ExpansionHeaderComponent,
	DropdownImgComponent,
	MultiRippleAnimation,
	MultiRippleDirective,
	SvgUseComponent,
	YslmSvgDefinitionsModule,
} from '.';
import { YslmDirectivesModule } from '../directives/yslm-directives.module';
import { YslmPipesModule } from '../pipes/yslm-pipes.module';


const declarations = [
	MultiRippleDirective,
	AccordionComponent,
	CardItemComponent,
	CarouselComponent,
	CarouselItemComponent,
	CarouselItemDirective,
	ExpansionComponent,
	ExpansionHeaderComponent,
	ExpansionContentComponent,
	DropdownImgComponent,
	SvgUseComponent,
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		EllipsisModule,
		YslmDirectivesModule,
		YslmPipesModule,
		YslmSvgDefinitionsModule,
	],
	exports: [...declarations, YslmSvgDefinitionsModule],
	declarations,
	providers: [MultiRippleAnimation],
})
export class YslmComponentsModule {}
