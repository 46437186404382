import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AnimationHelper } from './services/animation.helper';

@NgModule({
	imports: [CommonModule],
	providers: [AnimationHelper],
})
export class YslmAnimationsModule {}
