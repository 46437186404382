<div *ngIf="viewModel$ | async as $" class="trips trips-page" padding="page">
	<!-- page segment -->
	<div class="trips__caption u-caption-block" text-align="center" margin="b:3x">
		<h1 class="u-headline-primary">Discover our travel catalog</h1>
		<h2 class="u-headline-secondary">
			Our catalog proposes a list of recommendations established by our experiment. <br hiddenOnTabletLandscape hiddenOnTabletPortrait hiddenOnMobile />
			This list is not exhaustive, you can compose your trip by contacting us.
		</h2>
	</div>

	<!-- page segment -->
	<div class="trips__catalog">
		<trip-card
			*ngFor="let trip of $.trips"
			[slug]="trip.slug"
			[title]="trip.title"
			[detail]="trip.detail"
			[coverImage]="trip.coverImage"
			[minFare]="trip.minFare"
		></trip-card>
	</div>
</div>
