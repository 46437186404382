import { Component } from '@angular/core';

@Component({
	selector: 'i-arrow',
	template: `
		<svg>
			<symbol id="arrow-down">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M880 3472 c-41 -20 -71 -74 -63 -114 6 -31 1646 -1951 1691 -1980 34
					-23 70 -23 104 0 26 17 1669 1926 1688 1962 15 28 12 72 -6 99 -38 53 46 51
					-1731 51 -1549 0 -1650 -1 -1683 -18z"
					/>
				</g>
			</symbol>
			<symbol id="arrow-up">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M2508 3742 c-45 -29 -1685 -1949 -1691 -1980 -8 -40 22 -94 63 -114
					33 -17 134 -18 1683 -18 1777 0 1693 -2 1731 51 18 27 21 71 6 99 -19 36
					-1662 1945 -1688 1962 -15 10 -38 18 -52 18 -14 0 -37 -8 -52 -18z"
					/>
				</g>
			</symbol>

			<symbol id="cheveron-left">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M3411 4799 c-29 -5 -74 -21 -100 -34 -31 -17 -368 -346 -1023 -998
					-769 -766 -981 -982 -997 -1018 -51 -111 -49 -250 5 -350 17 -31 344 -364
					1002 -1021 1110 -1108 1012 -1023 1187 -1023 150 0 189 22 378 213 183 186
					201 219 201 362 0 169 51 106 -765 925 l-719 720 700 700 c386 385 714 720
					730 745 66 102 76 246 25 359 -22 47 -56 88 -183 213 -86 86 -176 165 -198
					176 -36 18 -149 44 -179 41 -5 -1 -34 -5 -64 -10z"
					/>
				</g>
			</symbol>
			<symbol id="cheveron-right">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M1562 4799 c-88 -15 -130 -44 -294 -207 -127 -125 -161 -166 -183
					-213 -51 -113 -41 -257 25 -359 16 -25 344 -360 730 -745 l700 -700 -719 -720
					c-816 -819 -765 -756 -765 -925 0 -143 18 -176 201 -362 189 -191 228 -213
					378 -213 175 0 77 -85 1187 1023 658 657 985 990 1002 1021 54 100 56 239 5
					350 -16 36 -228 252 -997 1018 -644 641 -992 981 -1022 997 -39 21 -152 48
					-185 45 -5 -1 -34 -5 -63 -10z"
					/>
				</g>
			</symbol>
		</svg>
	`,
})
export class ArrowIcons {}
