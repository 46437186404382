import { createAction } from '@ngrx/store';

import { Trip } from 'src/app/core/models';

enum ActionTypes {
	// ↓↓↓ GetAll
	getAllForHomePage = '[Home Page] (Trip) Get all {Request}',
	getAllForTripsPage = '[Trips Page] (Trip) Get all {Request}',
	getAllSuccess = '[Home Page] (Trip) Get all {Success}',
	getAllFailure = '[Home Page] (Trip) Get all {Failure}',

	// ↓↓↓ GetOne
	getOneForTripPage = '[Trip Page] (Trip) Get one {Request}',
	getOneSuccess = '[Trip API] (Trip) Get one {Request}',
	getOneFailure = '[Trip API] (Trip) Get one {Request}',
}

// ↓↓↓ GetAll
export const getAllForHomePage = createAction(ActionTypes.getAllForHomePage);
export const getAllForTripsPage = createAction(ActionTypes.getAllForTripsPage);
export const getAllSuccess = createAction(ActionTypes.getAllSuccess, (payload: { trips: Trip[] }) => ({ payload }));
export const getAllFailure = createAction(ActionTypes.getAllFailure, (payload: { error: string }) => ({ payload }));

// ↓↓↓ GetOne
export const getOneForTripPage = createAction(ActionTypes.getOneForTripPage, (payload: { slug: string }) => ({
	payload,
}));
export const getOneSuccess = createAction(ActionTypes.getOneSuccess, (payload: { trip: Trip }) => ({ payload }));
export const getOneFailure = createAction(ActionTypes.getOneFailure, (payload: { error: string }) => ({ payload }));
