import { Injectable } from '@angular/core';
import { RxState, selectSlice } from '@rx-angular/state';
import { ReplaySubject } from 'rxjs';
import { filter, mapTo, pluck, throttleTime } from 'rxjs/operators';

import { inRange, max, min } from '@yslm/utility';

import { deviceBreakpointBoundaries, deviceBreakpointKeys } from './media-query.consts';
import { DeviceBreakpointKey, MediaQueryOperator, ValueByDeviceBreakpoint } from './media-query.types';

interface ServiceState {
	deviceBreakpoint: DeviceBreakpointKey;
}

@Injectable()
export class MediaQueryHelper {
	// •) subjects

	private readonly windowResizedSubject$ = new ReplaySubject<void>(1);
	readonly windowResized$ = this.windowResizedSubject$.pipe(throttleTime(250));

	readonly deviceBreakpoint$ = this.serviceState.select(selectSlice(['deviceBreakpoint']), pluck('deviceBreakpoint'));
	readonly deviceBreakpointChanged$ = this.deviceBreakpoint$.pipe(mapTo(undefined));

	// {*} Initialization

	constructor(private readonly serviceState: RxState<ServiceState>) {
		// @todo implement
		// fromEvent(window, 'resize').pipe(map(_ => window.innerWidth < 480))

		// @todo remove
		// // – set initial state
		// this.serviceState.set({ deviceBreakpoint: this.inferCurrentDeviceBreakpoint() });

		// – trigger window resize initially
		this.triggerWindowResized();

		// – connect & hold states
		this.connectStates();
	}

	private connectStates() {
		// [connect event] on window resize: update current device breakpoint
		this.serviceState.connect(
			'deviceBreakpoint',
			this.windowResized$.pipe(
				mapTo({
					prevBreakpoint: this.serviceState.get('deviceBreakpoint'),
					nextBreakpoint: this.inferCurrentDeviceBreakpoint(),
				}),
				filter(({ prevBreakpoint, nextBreakpoint }) => prevBreakpoint !== nextBreakpoint),
				pluck('nextBreakpoint')
			)
		);
	}

	// {+} @public methods

	triggerWindowResized(): void {
		this.windowResizedSubject$.next();
	}

	private inferCurrentDeviceBreakpoint(): DeviceBreakpointKey {
		for (const breakpointKey of deviceBreakpointKeys) {
			if (this.doesWindowSizeMatchDeviceBreakpoint(breakpointKey)) {
				return breakpointKey;
			}
		}
	}

	doesWindowSizeMatchDeviceBreakpoint(
		breakpointKey: DeviceBreakpointKey,
		operator: MediaQueryOperator = 'only',
		breakpointKey2?: DeviceBreakpointKey // used when: operator === 'betweeen'
	) {
		const breakpoint = deviceBreakpointBoundaries[breakpointKey];
		const breakpoint2 = deviceBreakpointBoundaries[breakpointKey2];

		switch (operator) {
			case 'only':
				return window.matchMedia(`(min-width: ${breakpoint.min})`).matches &&
					window.matchMedia(`(max-width: ${breakpoint.max})`).matches
					? true
					: false;

			case 'up':
				return window.matchMedia(`(min-width: ${breakpoint.min})`).matches ? true : false;

			case 'down':
				return window.matchMedia(`(max-width: ${breakpoint.max})`).matches ? true : false;

			case 'between':
				const [minBoundary, maxBoundary] =
					parseFloat(breakpoint.min) < parseFloat(breakpoint2.max)
						? [breakpoint.min, breakpoint.max]
						: [breakpoint.max, breakpoint.min];

				return window.matchMedia(`(min-width: ${minBoundary})`).matches &&
					window.matchMedia(`(max-width: ${maxBoundary})`).matches
					? true
					: false;

			default:
				return false;
		}
	}

	inferDeviceBreakpointValue(
		valueByDeviceBreakpoint: ValueByDeviceBreakpoint,
		breakpointKey?: DeviceBreakpointKey
	): ValueByDeviceBreakpoint[DeviceBreakpointKey] {
		const valueByDeviceBreakpointKeys = Object.keys(valueByDeviceBreakpoint);

		// – check if `all` key is in `valueByDeviceBreakpointKeys`
		if (valueByDeviceBreakpointKeys.includes('all')) {
			return valueByDeviceBreakpoint.all;
		}

		// – check if `breakpointKey` is in `valueByDeviceBreakpointKeys`
		if (breakpointKey) {
			return valueByDeviceBreakpointKeys.includes(breakpointKey)
				? valueByDeviceBreakpoint[breakpointKey]
				: undefined;
		}

		// – check if current breakpointKey is in `valueByDeviceBreakpointKeys`
		const currentBreakpointKey = this.serviceState.get('deviceBreakpoint');
		if (valueByDeviceBreakpointKeys.includes(currentBreakpointKey)) {
			return valueByDeviceBreakpoint[currentBreakpointKey];
		}

		// – loop over all devices and check if current breakpointKey is within one of their boundaries
		const currentBreakpointBoundaries = deviceBreakpointBoundaries[currentBreakpointKey];
		const [currentBreakpointMin, currentBreakpointMax] = [
			parseFloat(currentBreakpointBoundaries.min),
			parseFloat(currentBreakpointBoundaries.max),
		];

		for (const [deviceKey, deviceBoundaries] of Object.entries(deviceBreakpointBoundaries)) {
			const [breakpointMin, breakpointMax] = [parseFloat(deviceBoundaries.min), parseFloat(deviceBoundaries.max)];

			const isCurrentBreakpointWithinDeviceBoundaries = inRange(
				[currentBreakpointMin, currentBreakpointMax],
				breakpointMin,
				breakpointMax,
				'[]'
			);

			if (isCurrentBreakpointWithinDeviceBoundaries && valueByDeviceBreakpointKeys.includes(deviceKey)) {
				return valueByDeviceBreakpoint[deviceKey];
			}
		}

		return undefined;
	}
}
