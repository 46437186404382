import { Component } from '@angular/core';

@Component({
	selector: 'i-social-network',
	template: `
		<svg>
			<symbol id="social-network-facebook">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M665 5101 c-296 -74 -533 -300 -636 -606 l-24 -70 -3 -1840 c-2
					-1810 -2 -1841 18 -1920 77 -308 334 -566 644 -645 77 -19 110 -20 988 -20
					l908 0 0 970 0 970 -310 0 -310 0 0 420 0 420 308 0 309 0 6 238 c4 142 13
					267 22 312 84 424 376 757 746 851 77 20 118 22 477 26 l392 5 0 -426 0 -426
					-355 0 c-394 0 -391 0 -440 -65 -46 -60 -55 -110 -55 -322 l0 -193 425 0 425
					0 0 -420 0 -420 -425 0 -425 0 0 -970 0 -970 513 0 c482 0 519 1 593 20 310
					79 567 337 644 645 20 79 20 110 18 1920 l-3 1840 -24 70 c-83 249 -245 434
					-474 544 -181 88 -14 81 -2064 80 -1699 0 -1823 -2 -1888 -18z"
					/>
				</g>
			</symbol>

			<symbol id="social-network-instagram">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M1165 5104 c-182 -33 -395 -119 -547 -223 -318 -215 -536 -556 -603
					-940 -22 -125 -22 -2637 0 -2762 24 -138 62 -257 124 -384 74 -150 154 -264
					271 -381 188 -189 400 -309 674 -382 l101 -27 1315 -3 c976 -2 1338 0 1405 9
					441 58 845 342 1055 741 60 113 92 199 128 332 l27 101 3 1334 c2 1145 0 1346
					-12 1420 -56 324 -218 616 -463 833 -188 167 -431 284 -682 329 -99 18 -173
					19 -1410 18 -1084 -1 -1320 -3 -1386 -15z m2780 -473 c176 -50 315 -134 441
					-263 122 -125 190 -241 242 -416 l27 -87 3 -1264 c3 -1367 4 -1338 -50 -1495
					-48 -139 -132 -269 -246 -378 -127 -121 -251 -191 -422 -239 l-85 -24 -1295 0
					-1295 0 -85 24 c-166 46 -293 117 -414 231 -146 138 -225 272 -283 480 -17 61
					-18 141 -18 1360 l0 1295 23 85 c49 177 132 318 264 446 91 89 164 139 272
					190 66 30 190 67 261 77 17 2 604 4 1305 3 l1275 -2 80 -23z"
					/>
					<path
						d="M3836 4254 c-224 -69 -311 -342 -168 -529 121 -160 362 -175 506 -32
					61 62 88 126 94 222 3 64 0 91 -17 136 -63 165 -245 255 -415 203z"
					/>
					<path
						d="M2325 3865 c-520 -96 -925 -482 -1056 -1005 -21 -84 -24 -118 -24
					-300 0 -180 3 -216 23 -295 117 -466 432 -812 875 -960 134 -45 263 -65 417
					-65 154 0 283 20 417 65 402 134 703 436 837 836 55 165 69 265 63 464 -7 230
					-40 363 -140 560 -177 346 -485 587 -877 686 -113 28 -414 36 -535 14z m453
					-475 c306 -87 525 -307 614 -617 32 -112 32 -314 0 -426 -47 -164 -119 -287
					-238 -402 -335 -328 -866 -323 -1199 10 -110 109 -182 234 -227 392 -32 112
					-32 314 0 426 97 340 355 574 700 637 78 15 268 4 350 -20z"
					/>
				</g>
			</symbol>

			<symbol id="social-network-twitter">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M3380 4633 c-69 -11 -185 -46 -256 -76 -112 -47 -210 -113 -308 -207
					-228 -219 -339 -501 -322 -814 3 -67 9 -134 13 -150 l6 -29 -94 6 c-261 19
					-597 99 -859 205 -425 172 -821 453 -1123 797 -39 44 -74 82 -79 83 -15 6 -87
					-161 -115 -263 -23 -89 -26 -118 -26 -265 0 -146 3 -176 26 -265 30 -112 105
					-272 167 -355 51 -69 140 -161 204 -212 l49 -39 -54 6 c-94 11 -221 46 -313
					86 -49 22 -92 39 -94 39 -3 0 -2 -41 2 -90 14 -181 83 -379 187 -532 126 -185
					353 -347 573 -408 l60 -17 -60 -11 c-107 -20 -198 -24 -293 -13 -51 7 -96 10
					-98 7 -3 -2 11 -42 30 -89 138 -340 470 -591 840 -636 43 -5 80 -11 82 -13 8
					-7 -195 -138 -297 -192 -354 -188 -819 -278 -1190 -231 -21 3 -38 2 -38 -3 0
					-16 216 -136 380 -211 383 -176 787 -261 1233 -261 505 0 942 97 1349 301 770
					384 1332 1100 1552 1977 54 216 77 386 83 629 l6 213 86 69 c111 88 279 262
					364 375 61 81 76 106 62 106 -2 0 -37 -13 -77 -29 -111 -44 -222 -77 -338
					-101 -58 -11 -116 -23 -130 -26 -18 -4 -6 9 40 44 81 61 193 178 243 255 44
					67 127 236 127 261 0 12 -21 5 -92 -34 -155 -82 -344 -152 -525 -195 l-52 -12
					-33 35 c-121 128 -325 234 -530 276 -72 15 -300 20 -368 9z"
					/>
				</g>
			</symbol>

			<symbol id="social-network-whatsapp">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M2285 5105 c-950 -124 -1749 -770 -2059 -1664 -140 -402 -172 -839
					-95 -1271 46 -258 155 -552 291 -790 l26 -45 -226 -663 c-124 -364 -222 -665
					-219 -669 4 -3 314 92 689 211 376 119 689 216 696 216 7 0 44 -16 83 -35 628
					-317 1413 -352 2063 -93 737 293 1295 912 1501 1662 67 246 80 349 80 656 0
					300 -9 385 -65 610 -249 1000 -1099 1748 -2130 1875 -152 18 -492 18 -635 0z
					m495 -395 c338 -31 640 -130 923 -303 154 -94 258 -177 393 -311 340 -339 545
					-757 603 -1226 25 -194 9 -508 -35 -700 -89 -384 -278 -723 -564 -1011 -172
					-172 -349 -299 -575 -412 -422 -210 -938 -266 -1415 -152 -189 45 -435 146
					-593 243 -37 23 -70 42 -74 42 -3 0 -183 -56 -399 -125 -216 -69 -394 -125
					-397 -125 -3 0 54 173 125 383 l130 384 -60 90 c-236 360 -354 778 -339 1202
					13 374 106 692 293 1001 416 688 1198 1090 1984 1020z"
					/>
					<path
						d="M1688 3756 c-45 -16 -83 -47 -147 -121 -177 -202 -211 -494 -91 -784
					41 -101 78 -165 183 -316 135 -195 227 -306 377 -455 279 -280 518 -421 955
					-564 207 -67 316 -81 440 -57 172 35 369 159 425 270 28 57 50 145 57 229 5
					72 4 80 -18 105 -26 29 -478 249 -553 269 -70 18 -85 9 -188 -119 -122 -152
					-149 -180 -180 -188 -18 -5 -56 7 -136 44 -313 143 -531 330 -725 619 -59 89
					-73 123 -58 150 5 9 39 51 75 92 67 76 126 176 126 213 0 23 -202 515 -232
					565 -11 18 -29 37 -40 43 -33 18 -226 22 -270 5z"
					/>
				</g>
			</symbol>

			<symbol id="social-network-youtube">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M1895 5109 c-326 -9 -659 -27 -750 -39 -38 -5 -119 -14 -180 -20
					-370 -37 -529 -105 -669 -289 -179 -235 -226 -465 -278 -1376 -16 -276 -16
					-1373 0 -1660 48 -859 94 -1102 252 -1335 80 -117 220 -223 337 -256 94 -27
					249 -53 398 -69 77 -8 183 -19 235 -25 254 -26 585 -35 1355 -35 769 0 1098 9
					1350 35 582 61 658 80 801 194 27 23 66 59 85 81 18 22 37 42 40 45 4 3 17 23
					30 45 132 231 160 350 209 880 13 147 13 2433 0 2563 -42 404 -57 492 -106
					639 -54 161 -144 296 -256 387 -141 113 -236 138 -758 196 -373 41 -1386 60
					-2095 39z m215 -1534 c20 -18 230 -135 241 -135 5 0 9 -3 9 -7 0 -9 27 -25
					140 -85 41 -22 77 -44 80 -49 3 -4 35 -22 70 -40 36 -17 89 -47 118 -65 29
					-19 56 -34 61 -34 5 0 16 -6 23 -12 16 -15 38 -28 138 -82 41 -22 100 -56 130
					-76 30 -19 69 -42 85 -50 17 -8 64 -35 105 -61 41 -25 111 -65 155 -88 44 -24
					87 -49 96 -57 8 -8 21 -14 27 -14 7 0 12 -4 12 -8 0 -4 10 -8 22 -9 11 -1 24
					-6 27 -11 8 -13 127 -84 178 -106 50 -22 47 -31 -27 -69 -27 -14 -80 -44 -119
					-66 -38 -23 -74 -41 -80 -41 -6 0 -11 -3 -11 -7 0 -9 -25 -24 -140 -86 -41
					-22 -88 -50 -105 -61 -16 -12 -39 -26 -50 -32 -138 -75 -181 -99 -185 -105 -3
					-4 -41 -26 -85 -49 -44 -24 -103 -58 -131 -76 -28 -19 -54 -34 -57 -34 -3 0
					-30 -14 -59 -32 -29 -17 -87 -49 -128 -70 -41 -22 -77 -43 -80 -48 -3 -5 -41
					-28 -85 -51 -44 -23 -103 -56 -132 -75 -29 -18 -74 -45 -100 -59 -68 -36 -111
					-61 -125 -73 -16 -13 -105 -62 -112 -62 -3 0 -6 481 -6 1069 l0 1069 43 -20
					c23 -11 49 -26 57 -33z"
					/>
				</g>
			</symbol>
		</svg>
	`,
})
export class SocialNetworkIcons {}
