import { AnimationEvent } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Inject, InjectionToken, Input, OnInit, Output } from '@angular/core';
import { RxState, selectSlice } from '@rx-angular/state';
import { filter, map, mapTo, pluck, takeUntil } from 'rxjs/operators';

import { ProjectableComponent, YslmTheme } from '@yslm/common/typings';
import { isBoolean } from '@yslm/utility';

import { expansionTriggers } from './expansion.animations';

// @ Component props
interface ComponentProps {
	// – inputs
	isOpen: boolean;
	// - meta inputs
	theme?: YslmTheme;
}

// @ Component state
interface ComponentState {
	// – flags
	isProjected: boolean;
	isOpen: boolean;

	// – styles
	theme?: YslmTheme;
}

// @ Injection tokens
const ComponentPropsToken = new InjectionToken<ComponentProps>('ComponentProps');
const ComponentStateToken = new InjectionToken<ComponentState>('ComponentState');

@Component({
	selector: 'yslm-expansion',
	templateUrl: 'expansion.component.html',
	styleUrls: ['expansion.component.scss'],
	animations: [expansionTriggers.contentToggle],
	providers: [
		{ provide: ComponentPropsToken, useClass: RxState },
		{ provide: ComponentStateToken, useClass: RxState },
	],
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionComponent implements OnInit, ProjectableComponent {
	// •) component state

	// • state initialization
	private readonly INITIAL_STATE: ComponentState = {
		// – flags
		isProjected: false,
		isOpen: false,

		// – styles
		theme: null,
	};

	// • state selections
	private readonly stateSelections = {
		projected$: this.componentState
			.select(selectSlice(['isProjected']), pluck('isProjected'))
			.pipe(filter(isProjected => isProjected === true)),

		toggled$: this.componentState.select(selectSlice(['isOpen']), pluck('isOpen')),
		expanded$: this.componentState.select(selectSlice(['isOpen']), pluck('isOpen')).pipe(
			filter(isOpen => isOpen === true),
			mapTo(undefined)
		),
		collapsed$: this.componentState.select(selectSlice(['isOpen']), pluck('isOpen')).pipe(
			filter(isOpen => isOpen === false),
			mapTo(undefined)
		),
	};

	// • view states
	readonly viewModel$ = this.componentState.select(selectSlice(['isOpen', 'theme'])).pipe(
		map(({ isOpen, theme }) => ({
			isOpen,
			styles: {
				theme,
			},
			animations: {
				contentToggleState: isOpen ? 'expanded' : 'collapsed',
			},
		}))
	);

	// •) event emitters

	readonly toggleClicked = new EventEmitter<void>();
	readonly toggleAnimationDone = new EventEmitter<AnimationEvent>();

	// •) inputs & output

	@Input() set isOpen(value: boolean) {
		if (!isBoolean(value)) {
			throw new TypeError('Expected `value` input to be a boolean');
		}

		this.componentProps.set({ isOpen: value });
	}

	@Input() set theme(value: string) {
		this.componentProps.set({ theme: value as YslmTheme });
	}

	@Output() readonly toggled = new EventEmitter<void>();
	@Output() readonly toggleDone = new EventEmitter<void>();

	@Output() readonly expanded = new EventEmitter<void>();
	@Output() readonly expandDone = new EventEmitter<void>();

	@Output() readonly collapsed = new EventEmitter<void>();
	@Output() readonly collapseDone = new EventEmitter<void>();

	// •) content projection

	readonly projectionApi = {
		initialize: () => this.componentState.set({ isProjected: true }),

		// – state
		state$: this.componentState.select(selectSlice(['isOpen'])),
		state: () => ({ isOpen: this.componentState.get('isOpen') }),

		// – event emitters
		toggleClicked$: this.toggleClicked.asObservable(),

		// – outputs
		expandDone$: this.expandDone.asObservable(),
		collapseDone$: this.collapseDone.asObservable(),

		// – actions
		toggle: (is?: boolean) => this.toggle(is),
		scrollIntoView: () => this.elementRef.nativeElement.scrollIntoView({ behavior: 'smooth' }),
	};

	// {*} Initialization

	constructor(
		private readonly elementRef: ElementRef,
		@Inject(ComponentPropsToken) private readonly componentProps: RxState<ComponentProps>,
		@Inject(ComponentStateToken) private readonly componentState: RxState<ComponentState>
	) {
		this.componentState.set(this.INITIAL_STATE);
	}

	ngOnInit(): void {
		this.connectProps();
		this.holdStates();
	}

	private connectProps(): void {
		// [input] connect input values
		this.componentState.connect('isOpen', this.componentProps.select(selectSlice(['isOpen']), pluck('isOpen')));
		// [meta input] connect meta input values
		this.componentState.connect('theme', this.componentProps.select(selectSlice(['theme']), pluck('theme')));
	}

	private holdStates(): void {
		// [hold event] on toggle clicked: toggle `isOpen` status, unless expansion is projected.
		this.componentState.hold(this.toggleClicked.pipe(takeUntil(this.stateSelections.projected$)), () => this.toggle());

		// [output] on animation start/done: emit event
		this.componentState.hold(this.toggleAnimationDone, event => {
			const fromCollapsed = event.fromState === 'void' || event.fromState === 'collapsed';
			const toExpanded = event.toState === 'expanded';
			if (fromCollapsed && toExpanded) {
				this.expandDone.emit();
				this.toggleDone.emit();
			}

			const fromExpanded = event.fromState === 'expanded';
			const toCollapsed = event.toState === 'void' || event.toState === 'collapsed';
			if (fromExpanded && toCollapsed) {
				this.collapseDone.emit();
				this.toggleDone.emit();
			}
		});

		// [output] emit on toggle status update
		this.componentState.hold(this.stateSelections.toggled$, () => this.toggled.emit());
		this.componentState.hold(this.stateSelections.expanded$, () => this.expanded.emit());
		this.componentState.hold(this.stateSelections.collapsed$, () => this.collapsed.emit());
	}

	// {*} Component actions

	private toggle(isOpen?: boolean): void {
		this.componentState.set('isOpen', prevState => {
			if (isBoolean(isOpen)) {
				return isOpen;
			}

			return !prevState.isOpen;
		});
	}
}
