import { Primitive } from '../typings';

/**
 * @param {*} param
 * @returns {boolean}
 */
export function isPrimitive(param: any): param is Primitive {
	return (
		isUndefined(param) ||
		isNull(param) ||
		isBoolean(param) ||
		isNumber(param) ||
		isString(param) ||
		isSymbol(param)
	);
}

// {*} utils

/**
 * @param {*} param
 * @returns {boolean}
 */
export function isUndefined(param: any): param is undefined {
	return typeof param === 'undefined';
}
/** @function complement(isUndefined) */
export function isntUndefined(param: any): param is undefined {
	return !isUndefined(param);
}

/**
 *
 */
export function isNull(param: any): param is null {
	return param === null;
}
/** @function complement(isNull) */
export function isntNull(param: any): param is undefined {
	return !isNull(param);
}

/**
 *
 */
export function isNil(param: any): param is undefined | null {
	return typeof param === 'undefined' || param === null;
}
/** @function complement(isNil) */
export function isntNil(param: any): param is undefined {
	return !isNil(param);
}

/**
 * @param {*} param
 * @returns {boolean}
 */
function isBooleanObject(param: any): param is boolean {
	return Object.prototype.toString.call(param) === '[object Boolean]';
}
/**
 * @param {*} param
 * @returns {boolean}
 */
export function isBoolean(param: any): param is boolean {
	return typeof param === 'boolean' || isBooleanObject(param);
}

/**
 * @param {*} param
 * @returns {boolean}
 */
function isNumberObject(param: any): param is number {
	return Object.prototype.toString.call(param) === '[object Number]';
}
/**
 * @param {*} param
 * @returns {boolean}
 */
export function isNumber(param: any): param is number {
	// include numerical exceptions (`NaN` & `Infinity`)
	return typeof param === 'number' || isNumberObject(param);
}
/**
 * checks whether the given value is a numeric number or not.
 * Both `NaN` and `Infinity` are considered "non-numeric" values.
 *
 * @param {*} param
 * @returns {boolean}
 */
export function isNumeric(param: any): param is number {
	// exclude numerical exceptions (`NaN` & `Infinity`)
	return isNumber(param) && !isNaN(param) && isFinite(param);
}

/**
 * @param {*} param
 * @returns {boolean}
 */
function isStringObject(param: any): param is string {
	return Object.prototype.toString.call(param) === '[object String]';
}
/**
 * @param {*} param
 * @returns {boolean}
 */
export function isString(param: any): param is string {
	return typeof param === 'string' || isStringObject(param);
}

/**
 * @param {*} param
 * @returns {boolean}
 */
function isSymbolObject(param: any): param is symbol {
	return Object.prototype.toString.call(param) === '[object Symbol]';
}
/**
 * @param {*} param
 * @returns {boolean}
 */
export function isSymbol(param: any): param is symbol {
	return typeof param === 'symbol' || isSymbolObject(param);
}
