<div class="about about-page" padding="page">
	<h1 class="u-headline-primary" text-align="center">Tarboosh agency in a nutshell</h1>
	<div class="details">
		<p>
			Tarboosh is a tourist transport agency, headquartered in Fes, Morocco. It provides private and personalized
			transport throughout the Moroccan territory, from north to south, and from east to west. <br />Our goal is to
			satisfy our customers, to ensure their safety and comfort and to ensure the smooth running of their trip.
		</p>
		<p>
			We have a wide range of comfortable vehicles, we put at your disposal a professional and experienced staff, and
			we offer a lot of rich activities and very diversified services, to ensure that your stay be pleasant and
			unforgettable.
		</p>

		<p>Among our activities and services, you can find:</p>

		<ul>
			<li>
				<span>Tourist transport:</span>
				<ul>
					<li>Excursions and organized tours.</li>
					<li>Transfers from one point to another (airport, train, hotel, ...).</li>
				</ul>
			</li>

			<li>
				Guided tours on foot or by car, from different parts of the Kingdom, with the accompaniment of one of our
				experts and tour guides.
			</li>

			<li>
				<span>Gastronomy:</span>
				<ul>
					<li>Excursions and organized tours.</li>
					<li>
						visit & taste (a special visit to take a gastronomic tour, to taste different traditional dishes of
						region).
					</li>
				</ul>
			</li>
		</ul>

		<p>
			On the other hand, the agency works in collaboration with many experts and quality partners, which allows it to
			provide advice in the various other services of the region. tourism experience: catering, accommodation,
			festivals, gastronomy, dinanderies, tapestry, customs ...
		</p>
		<p>
			Feel free to consult our catalog of services and contact us on our phone, via Whatsapp or via our email and do
			not hesitate to visit us directly within our premises. We will be very happy to welcome you, to accompany you
			and to advise you during your tourist stays in Morocco.
		</p>
	</div>
</div>
