import { AnimationFactory, AnimationMetadata, AnimationPlayer, animate, keyframes, style } from '@angular/animations';
import { Injectable } from '@angular/core';

import { AnimationHelper, CustomAnimation } from '@yslm/animations';
import { HTMLElementStyles } from '@yslm/common/typings';

interface AnimationFactoryParams extends AnimationMetadata {
	duration?: number;
}
interface AnimationWrapperStyles extends HTMLElementStyles {
	top: number;
	left: number;
	size: number;
	color?: string;
}

@Injectable()
export class MultiRippleAnimation implements CustomAnimation {
	name = 'ripple';
	constructor(public animationHelper: AnimationHelper) {}

	build(animationFactoryParams?: AnimationFactoryParams): AnimationFactory {
		const animation = animate(
			`${animationFactoryParams ? animationFactoryParams.duration : 750}ms cubic-bezier(0, 0, 0.2, 1)`,
			keyframes([
				style({ transform: 'scale(0, 0)', opacity: '0.25' }),
				style({ transform: 'scale(2, 2)', opacity: '0' }),
			])
		);
		return this.animationHelper.build(animation);
	}

	play(animationFactory: AnimationFactory, animationNodeEl: HTMLElement): AnimationPlayer {
		return this.animationHelper.play(animationFactory, animationNodeEl);
	}

	createNode(parentContainerEl: HTMLElement, wrapperStylingParams: AnimationWrapperStyles): HTMLElement {
		const animationNode = this.animationHelper.createAnimationNode(parentContainerEl, {
			position: 'absolute',
			top: `${wrapperStylingParams.top}px`,
			left: `${wrapperStylingParams.left}px`,
			height: `${wrapperStylingParams.size}px`,
			width: `${wrapperStylingParams.size}px`,
			borderRadius: '50%',
			backgroundColor: 'white',
		});

		return animationNode;
	}

	removeNode(parentContainerEl: HTMLElement, animationNode: HTMLElement): void {
		this.animationHelper.removeAnimationNode(parentContainerEl, animationNode);
	}
}
