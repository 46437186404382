<div *ngIf="viewModel$ | async as $" class="footer footer__component" text-align="center">
	<div class="footer__top">
		<div class="contact-us">
			<div class="heading u-font-bold" margin="b:x2">Contact us:</div>
			<div class="details">
				<div class="details__phone">
					<svg-use name="phone" margin="r:x2"></svg-use>
					<span>=</span>
					<svg-use name="social-network-whatsapp" margin="l|r:x2"></svg-use>
					<span>=</span>
					<span margin="l:x2">(+212) 6 63 68 88 47</span>
				</div>
				<div class="details__email" margin="t:x3">
					<svg-use name="email"></svg-use>
					<span margin="l:x2">contact@tarbooshtravel.com</span>
				</div>
			</div>
		</div>

		<div class="follow-us">
			<div class="heading u-font-bold" margin="b:x2">Follow us on social networks:</div>
			<div class="details">
				<a href="https://www.facebook.com/" margin="r:1x">
					<svg-use name="social-network-facebook"></svg-use>
				</a>
				<a href="https://www.facebook.com/">
					<svg-use name="social-network-instagram"></svg-use>
				</a>
			</div>
		</div>

		<div class="find-us">
			<div class="heading u-font-bold" margin="b:x2">Where to find us:</div>
			<div class="details">
				<svg-use name="location" margin="r:1x"></svg-use>
				<span>Fes, Morocco</span>
			</div>
		</div>
	</div>

	<div class="footer__divider"></div>

	<div class="footer__bottom">
		<span>Tarboosh Travel © {{ $.currentYear }} - All rights reserved.</span>
	</div>
</div>
