import { Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

export class AppInjector {
	private static injectorSubject = new ReplaySubject<Injector>(1);

	static set(injector: Injector) {
		this.injectorSubject.next(injector);
	}

	static get$(): Observable<Injector> {
		return this.injectorSubject.asObservable();
	}
}
