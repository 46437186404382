import { Injectable } from '@angular/core';
import { RxState } from '@rx-angular/state';

import { ViewTypeEnum } from 'src/app/app-routing.module';

interface AppContextState {
	// flags
	stateLoading: boolean;

	// view metadata
	viewType: ViewTypeEnum;
}

@Injectable()
export class AppContext extends RxState<AppContextState> {
	readonly viewType$ = this.select('viewType');

	setViewType(viewType: ViewTypeEnum): void {
		this.set({ viewType });
	}
}
