import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutPage, HomePage, NotFoundPage, TripPage, TripsPage } from 'src/app/content/pages';

export enum ViewTypeEnum {
	NOT_FOUND = 'not-found',
	HOME = 'home',
	ABOUT = 'about',
	CONTACT = 'contact',
	TRIPS = 'trips',
	TRIP = 'trip',
}

export const routerConfig: Routes = [
	{
		path: '',
		component: HomePage,
		pathMatch: 'full',
		data: { viewType: ViewTypeEnum.HOME },
	},
	{
		path: 'about',
		component: AboutPage,
		data: { viewType: ViewTypeEnum.ABOUT },
	},
	{
		path: 'contact',
		component: AboutPage,
		data: { viewType: ViewTypeEnum.CONTACT },
	},
	{
		path: 'trips',
		component: TripsPage,
		data: { viewType: ViewTypeEnum.TRIPS },
	},
	{
		path: 'trips/:slug',
		component: TripPage,
		data: { viewType: ViewTypeEnum.TRIP },
	},
	{
		path: 'not-found',
		component: NotFoundPage,
		data: { viewType: ViewTypeEnum.NOT_FOUND },
	},
	{
		path: '**',
		redirectTo: 'not-found',
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routerConfig, { scrollPositionRestoration: 'enabled' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
