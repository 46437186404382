import { has as ldashHas } from 'lodash-es';

/**
 *
 *
 * @param record
 * @param path
 * @returns
 */
export function has<T extends Record<string, any>>(record: T, path: string | string[]) {
	// return Object.prototype.hasOwnProperty.call(record, key);
	return ldashHas(record, path);
}
