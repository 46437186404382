import { Pipe, PipeTransform } from '@angular/core';

import { isBlank, isEmpty, isExisty } from '@yslm/utility/lang';
import { isNil, isNull, isUndefined } from '@yslm/utility/type';

@Pipe({
	name: 'is',
})
export class IsPipe implements PipeTransform {
	transform(param: unknown, type: string): boolean {
		if (!type) return true;

		switch (type) {
			case 'undefined':
				return isUndefined(param);
			case 'null':
				return isNull(param);
			case 'nil':
				return isNil(param);
			case 'existy':
				return isExisty(param);
			case 'empty':
				return isEmpty(param);
			case 'blank':
				return isBlank(param);
			default:
				return false;
		}
	}
}
