import { animate, state, style, transition, trigger } from '@angular/animations';

export const expansionTriggers = {
	contentToggle: trigger('contentToggle', [
		state(
			'void, collapsed',
			style({ display: 'none', height: 0, paddingTop: 0, paddingBottom: 0, marginTop: 0, marginBottom: 0, opacity: 0 })
		),

		transition(
			'void => expanded, collapsed => expanded',
			[
				style({ display: '*' }),
				animate(
					'{{ expandTiming }}',
					style({ height: '*', paddingTop: '*', paddingBottom: '*', marginTop: '*', marginBottom: '*' })
				),
				animate('{{ fadeinTiming }}', style({ opacity: 1 })),
			],
			{ params: { expandTiming: '250ms ease-out', fadeinTiming: '500ms' } }
		),

		transition('expanded => void, expanded => collapsed', animate('{{ collapseTiming }}'), {
			params: { collapseTiming: '250ms ease-in' },
		}),
	]),
};
