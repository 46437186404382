import {
	AfterContentInit,
	Component,
	ContentChildren,
	EventEmitter,
	Inject,
	InjectionToken,
	Input,
	OnInit,
	Output,
	QueryList,
} from '@angular/core';
import { RxState, selectSlice } from '@rx-angular/state';
import { isNumber } from 'lodash';
import { combineLatestWith, filter, map, pluck, switchMap, take, tap } from 'rxjs/operators';

import { delayUntilNextDetectionCycle } from '@yslm/common/utils';
import { isBoolean } from '@yslm/utility';

import { ExpansionComponent } from '../expansion/expansion.component';

// @ Component props
interface ComponentProps {
	// – inputs
	openedIndex?: number;
	// – meta inputs
	scrollIntoOpened?: boolean;

	// – content projection
	expansions: ExpansionComponent[];
}

// @ Component state
interface ComponentState {
	// – selections
	openedIndex?: number;

	// – initialized content projection
	expansions: ExpansionComponent[];
}

// @ Injection tokens
const ComponentPropsToken = new InjectionToken<ComponentProps>('ComponentProps');
const ComponentStateToken = new InjectionToken<ComponentState>('ComponentState');

@Component({
	selector: 'yslm-accordion',
	templateUrl: './accordion.component.html',
	styleUrls: ['./accordion.component.scss'],
	providers: [
		{ provide: ComponentPropsToken, useClass: RxState },
		{ provide: ComponentStateToken, useClass: RxState },
	],
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent implements AfterContentInit {
	// •) component props

	// • props initialization
	private readonly INITIAL_PROPS: ComponentProps = {
		// – inputs
		openedIndex: undefined,
		// – meta inputs
		scrollIntoOpened: false,

		// – content projection
		expansions: undefined,
	};

	// • props selections
	private readonly propsSelections = {
		openedIndex$: this.componentProps.select(selectSlice(['openedIndex']), pluck('openedIndex')),
		expansionsWithMeta$: this.componentProps.select(
			selectSlice(['expansions', 'scrollIntoOpened']),
			map(({ expansions, scrollIntoOpened }) => ({
				expansions,
				metadata: { scrollIntoOpened },
			}))
		),
	};

	// •) component state

	// • state initialization
	private readonly INITIAL_STATE: ComponentState = {
		// – selections
		openedIndex: null,

		// – initialized content projection
		expansions: undefined,
	};

	// • state selections
	private readonly stateSelections = {
		openedIndex$: this.componentState.select(selectSlice(['openedIndex']), pluck('openedIndex')),
		expansions$: this.componentProps.select(selectSlice(['expansions']), pluck('expansions')),
	};

	// •) inputs & outputs

	@Input()
	set openedIndex(value: number) {
		if (!isNumber(value) || value < 0) {
			throw new TypeError('Expected `value` input to be a positive number');
		}

		this.componentProps.set({ openedIndex: value });
	}

	@Input()
	set scrollIntoOpened(value: boolean) {
		if (!isBoolean(value)) {
			throw new TypeError('Expected `value` input to be a boolean');
		}

		this.componentProps.set({ scrollIntoOpened: value });
	}

	@Output()
	readonly openedIndexChange = new EventEmitter<number>(); // hold openedIndex state property as emitter

	// •) content projection

	@ContentChildren(ExpansionComponent)
	set expansionsComponent(queryList: QueryList<ExpansionComponent>) {
		if (!queryList.length) return;
		this.componentProps.set({ expansions: queryList.toArray() });
	}

	// {*} Initialization

	constructor(
		@Inject(ComponentPropsToken) private readonly componentProps: RxState<ComponentProps>,
		@Inject(ComponentStateToken) private readonly componentState: RxState<ComponentState>
	) {
		this.componentProps.set(this.INITIAL_PROPS);
		this.componentState.set(this.INITIAL_STATE);
	}

	ngAfterContentInit(): void {
		this.connectProps();
		this.holdProps();
		this.holdStates();
	}

	private connectProps(): void {
		// [content projection] set `isProjected` flag & hold events, then update local state
		this.componentState.connect(
			'expansions',
			this.propsSelections.expansionsWithMeta$.pipe(
				map(({ expansions, metadata }) => {
					const { scrollIntoOpened } = metadata;

					expansions.forEach((expansion, index) => {
						// – [set flag] mark each `expansion` as content projected
						expansion.projectionApi.initialize();

						// – [hold event] on toggle click: toggle `expansion`
						this.componentState.hold(expansion.projectionApi.toggleClicked$, () =>
							this.toggleExpansion(expansion, index)
						);

						// – [hold event] on toggle click: on opened: scroll into opened `expansion`
						this.componentState.hold(
							expansion.projectionApi.toggleClicked$.pipe(
								switchMap(() =>
									expansion.projectionApi.expandDone$.pipe(
										filter(() => scrollIntoOpened === true),
										tap(() => expansion.projectionApi.scrollIntoView()),
										take(1)
									)
								)
							)
						);
					});

					return expansions;
				})
			)
		);
	}

	private holdProps(): void {
		// [input] open expansion at `openedIndex`
		this.componentState.hold(
			this.propsSelections.openedIndex$.pipe(
				combineLatestWith(this.stateSelections.expansions$),
				map(([openedIndex, expansions]) => {
					const expansionToOpen = expansions.find((_, index) => index === openedIndex);

					if (!expansionToOpen) return;
					this.openExpansion(expansionToOpen, openedIndex);
				})
			)
		);
	}

	private holdStates(): void {
		// [output] emit `openedIndex` change event
		this.componentState.hold(this.stateSelections.openedIndex$, openedIndex =>
			this.openedIndexChange.emit(openedIndex)
		);
	}

	// {*} Business logic

	private toggleExpansion(expansion: ExpansionComponent, index: number) {
		const { isOpen } = expansion.projectionApi.state();

		if (isOpen) {
			this.closeExpansion(expansion, index);
		} else {
			this.openExpansion(expansion, index);
		}
	}

	private openExpansion(expansion: ExpansionComponent, index: number) {
		const expansions = this.componentProps.get('expansions');

		// – close other expansions
		expansions.forEach((letExpansion, letIndex) => {
			if (letIndex === index) return;
			this.closeExpansion(letExpansion, letIndex);
		});

		// – open selected `expansion`
		expansion.projectionApi.toggle(true);

		// – update `openedIndex` state
		this.componentState.set({ openedIndex: index });
	}

	private closeExpansion(expansion: ExpansionComponent, index: number) {
		// – unset `openedIndex`, if `index` to close is the same as the one currently open
		this.componentState.set('openedIndex', prevState =>
			index === prevState.openedIndex ? null : prevState.openedIndex
		);

		// – close passed `expansion`
		expansion.projectionApi.toggle(false);
	}
}
