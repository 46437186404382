import { ActionReducerMap } from '@ngrx/store';

import { TripEffects, TripState, tripReducer, tripStateKey } from './trips';

export interface AppState {
	[tripStateKey]: TripState;
	// bookingsState: BookingsState,
}

export const appReducers: ActionReducerMap<AppState> = {
	[tripStateKey]: tripReducer,
	// [bookingsKey]: bookingsReducer,
};

export const appEffects = [
	TripEffects,
	// BookingEffects,
];
