<div class="page page__not-found" padding="page">
	<div class="u-headline-primary" text-align="center">Page not found !</div>

	<div class="detail">
		We are sorry, the page you requested could not be found. <br />
		Please go back to the homepage or contact us at contact@tarbooshtravel.com
		<br />

		<div class="control-buttons" margin="T:2x">
			<button inset theme="primary" animation="raised ripple" routerLink="/" >GO BACK HOME</button>
		</div>
	</div>
</div>
