import { isNull, isNumber, isString, isUndefined } from '../type/primitive.utility';
import { isArray, isObject } from '../type/reference.utility';

/**
 * Checks whether the given argument is empty
 *
 * @param param
 * @returns
 */
export function isEmpty(param: any): boolean {
	if (isNumber(param)) {
		return isNaN(param);
	}

	if (isString(param)) {
		return param === '';
	}

	if (isArray(param)) {
		return param.length === 0;
	}

	if (isObject(param)) {
		return Object.keys(param).length === 0;
	}

	return isUndefined(param) || isNull(param);
}
/** @function complement(isEmpty) */
export function isntEmpty<T>(param: T | undefined | null): param is T {
	return !isntEmpty(param);
}
