import { Component } from '@angular/core';

@Component({
	selector: 'i-arithmetic-sign',
	template: `
		<svg>
			<symbol id="minus-circle">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M2370 5114 c-290 -36 -432 -66 -621 -128 -825 -274 -1463 -965 -1673
					-1812 -52 -213 -70 -370 -70 -614 0 -310 35 -529 128 -811 177 -534 538 -1004
					1011 -1319 314 -210 652 -339 1046 -401 207 -33 533 -33 737 -1 310 49 555
					129 817 266 583 304 1034 833 1241 1455 48 146 79 275 106 443 32 204 32 530
					-1 737 -48 309 -128 554 -265 816 -236 453 -621 840 -1068 1074 -374 196 -735
					289 -1158 296 -107 2 -211 1 -230 -1z m410 -349 c200 -21 310 -45 492 -106
					562 -188 1037 -612 1295 -1157 184 -388 250 -836 187 -1262 -123 -831 -703
					-1522 -1498 -1784 -540 -179 -1128 -144 -1638 97 -468 221 -844 598 -1065
					1066 -105 221 -171 461 -198 720 -27 251 -11 483 50 746 130 554 500 1058
					1005 1370 403 248 900 361 1370 310z"
					/>
					<path
						d="M1123 2711 c-128 -59 -125 -252 6 -305 38 -15 163 -16 1431 -16 1558
					0 1444 -6 1501 73 41 57 41 137 0 194 -57 79 58 73 -1504 73 -1342 0 -1393 -1
					-1434 -19z"
					/>
				</g>
			</symbol>
			<symbol id="plus-circle">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M2370 5114 c-290 -36 -432 -66 -621 -128 -825 -274 -1463 -965 -1673
					-1812 -52 -213 -70 -370 -70 -614 0 -310 35 -529 128 -811 177 -534 538 -1004
					1011 -1319 314 -210 651 -339 1046 -401 207 -33 533 -33 737 -1 311 50 555
					129 817 266 583 304 1034 833 1241 1455 48 146 79 275 106 443 32 204 32 530
					-1 737 -48 309 -128 554 -265 816 -236 453 -621 840 -1068 1074 -374 196 -735
					289 -1158 296 -107 2 -211 1 -230 -1z m410 -349 c200 -21 310 -45 492 -106
					562 -188 1037 -612 1295 -1157 184 -388 250 -836 187 -1262 -123 -831 -703
					-1522 -1498 -1784 -540 -179 -1128 -144 -1638 97 -468 221 -844 598 -1065
					1066 -105 221 -171 461 -198 720 -27 251 -11 483 50 746 130 554 500 1058
					1005 1370 403 248 900 361 1370 310z"
					/>
					<path
						d="M2467 4065 c-70 -48 -67 -15 -67 -710 l0 -625 -615 0 c-692 0 -672 2
					-726 -73 -62 -85 -26 -212 70 -251 37 -14 108 -16 656 -16 l615 0 0 -625 c0
					-539 2 -630 15 -655 42 -81 165 -107 242 -51 75 54 73 34 73 721 l0 610 610 0
					c687 0 667 -2 721 73 62 85 26 212 -70 251 -37 14 -108 16 -651 16 l-610 0 0
					610 c0 687 2 667 -73 721 -55 40 -135 41 -190 4z"
					/>
				</g>
			</symbol>
		</svg>
	`,
})
export class ArithmeticSignIcons {}
