<div class="card-item card-item__component">
	<div class="img-box">
		<img class="card-item__img u-img-responsive" src="{{ imgSrc }}" />
	</div>

	<div class="card-item__content">
		<div class="u-headline-tertiary">{{ title }}</div>
		<p
			class="card-item__detail"
			ellipsis
			ellipsis-word-boundaries=" \n"
			ellipsis-resize-detection="resize-observer"
			[ellipsis-content]="detail"
			style="height: 115px"
		></p>
		<div class="card-item__extra">
			<ng-content></ng-content>
		</div>
	</div>

	<div class="card-item__controls">
		<button theme="action" inset stretch ripple-animation class="card-item__button" [routerLink]="btnLink">
			{{ btnText }}
		</button>
	</div>
</div>
