import { AnimationMetadataType, AnimationPlayer } from '@angular/animations';
import {
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	QueryList,
	Renderer2,
	TemplateRef,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { selectSlice } from '@rx-angular/state';
import { Subject, combineLatest } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { TimerRef } from '@yslm/common/typings';
import { MediaQueryHelper } from '@yslm/helpers/media-query';
import { isNil, isNumeric } from '@yslm/utility/type';

import { CarouselSlideAnimation } from './carousel.animations';
import { SlidingDirection } from './carousel.model';
import { CarouselService } from './carousel.service';
import { ComponentStateManagement } from './carousel.state';
import { ComponentInputs } from './carousel.state';
import { ComponentState } from './carousel.state';
import { CarouselItemDirective } from './directives/carousel-item.directive';

@Component({
	selector: 'yslm-carousel',
	templateUrl: 'carousel.component.html',
	styleUrls: ['carousel.component.scss'],
	providers: [ComponentStateManagement, CarouselService, CarouselSlideAnimation],
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit {
	// •) enumerations

	readonly SlidingDirection = SlidingDirection;

	// •) component state

	private readonly INITIAL_STATE: ComponentState = {
		// – inputs
		itemsCountPerSlideBreakpoints: { all: 1 },
		itemGutterBreakpoints: { all: 0 },
		slidingItemsCount: 1,
		slidingDuration: 750,
		hasBorders: false,
		hasControls: true,
		loop: true,
		loopInterval: 5000,
		loopDelay: 2500,

		// – properties
		itemGutter: undefined,
		itemsCountPerSlide: undefined,
		currentSlideIndex: 0,
		carouselDimensions: undefined,
		loopRef: undefined,

		// – view children & content projection
		componentContainer: undefined,
		itemsSlidingWindow: undefined,
		itemsContainer: undefined,
		viewItemsRef: undefined,
		contentItemsRef: undefined,
	};

	// • view states

	private readonly state$ = this.componentState.state$;
	private readonly stateSelections = this.componentState.stateSelections;

	readonly viewModel$ = this.state$.pipe(selectSlice(['contentItemsRef', 'hasControls']));

	// •) subjects

	carouselInitialized$ = new Subject<void>();

	// •) event emitters

	slideClickedEmitter$ = new EventEmitter<SlidingDirection>();

	// •) outputs & inputs

	@Input()
	set config(componentInputs: ComponentInputs) {
		const { itemsCountPerSlideBreakpoints, slidingItemsCount } = componentInputs;
		const itemsCountPerSlide = this.mediaQueryHelper.inferDeviceBreakpointValue(itemsCountPerSlideBreakpoints);

		// inputs onChange: validate & save
		if (slidingItemsCount > itemsCountPerSlide) {
			componentInputs = {
				...componentInputs,
				slidingItemsCount: itemsCountPerSlide,
			};
		}

		this.componentState.set(componentInputs);
	}

	// •) view children & content projection

	@ViewChild('componentContainer')
	set componentContainer(elementRef: ElementRef<HTMLElement>) {
		this.componentState.set({ componentContainer: elementRef });
	}
	@ViewChild('itemsSlidingWindow')
	set itemsSlidingWindow(elementRef: ElementRef<HTMLElement>) {
		this.componentState.set({ itemsSlidingWindow: elementRef });
	}
	@ViewChild('itemsContainer')
	set itemsContainer(elementRef: ElementRef<HTMLElement>) {
		this.componentState.set({ itemsContainer: elementRef });
	}

	@ViewChildren('viewItemRef', { read: ElementRef })
	set viewItemsRef(queryList: QueryList<ElementRef<HTMLElement>>) {
		this.componentState.set({ viewItemsRef: queryList.length > 0 ? queryList : undefined });
	}
	@ContentChildren(CarouselItemDirective, { read: TemplateRef })
	set contentItemsRef(queryList: QueryList<TemplateRef<any>>) {
		this.componentState.set({ contentItemsRef: queryList.length > 0 ? queryList : undefined });
	}

	// {*} Initialization

	constructor(
		private componentState: ComponentStateManagement,
		private mediaQueryHelper: MediaQueryHelper,
		private carouselService: CarouselService,
		private carouselSlideAnimation: CarouselSlideAnimation,
		private renderer: Renderer2
	) {
		this.componentState.set(this.INITIAL_STATE);
	}

	ngOnInit(): void {
		this.connectStates();
		this.holdStates();
	}

	private connectStates() {
		/**
		 * @todo
		 * ```
		 * const subscription = this.mediaQueryHelper.windowResized$.subscribe(() => {
		 *  this.slideTo(0);
		 *  this.initCarousel();
		 * });
		 * ```
		 */

		// transform breakpointProps
		this.componentState.connect(
			combineLatest([this.stateSelections.breakpointProps$, this.mediaQueryHelper.deviceBreakpoint$]).pipe(
				map(([breakpointProps, currentBreakpointKey]) => ({
					breakpointProps,
					currentBreakpointKey,
				}))
			),
			(state, { breakpointProps, currentBreakpointKey }) => {
				const { itemsCountPerSlideBreakpoints, itemGutterBreakpoints } = breakpointProps;

				return {
					itemsCountPerSlide: this.mediaQueryHelper.inferDeviceBreakpointValue(
						itemsCountPerSlideBreakpoints,
						currentBreakpointKey
					),
					itemGutter: this.mediaQueryHelper.inferDeviceBreakpointValue(itemGutterBreakpoints, currentBreakpointKey),
				};
			}
		);

		// compute carouselDimensions
		this.componentState.connect(
			combineLatest([
				this.stateSelections.viewReferences$,
				this.stateSelections.contentReferences$,
				this.stateSelections.carouselConfig$,
			]),
			(state, [viewReferences, contentReferences, config]) => {
				const { currentSlideIndex } = state;
				const { componentContainer } = viewReferences;
				const { contentItemsRef } = contentReferences;
				const { itemGutter, itemsCountPerSlide, slidingItemsCount, hasBorders } = config;

				return {
					carouselDimensions: this.carouselService.computeDimensions({
						componentContainer,
						carouselItemsCount: contentItemsRef.length,
						itemGutter,
						itemsCountPerSlide,
						slidingItemsCount,
						currentSlideIndex,
						hasBorders,
					}),
				};
			}
		);

		// loop on autoplay
		// this.componentState.connect(this.carouselInitialized$, (state) => {
		// 	const { loop, loopInterval, loopDelay } = state;
		// 	this.setLoopOnAutoplay(loop, loopInterval, loopDelay);
		// });

		// Hold event handlers
		/**
		 * @todo must check if initialized before displaying controls
		 */
		this.componentState.connect(
			this.slideClickedEmitter$.pipe(
				withLatestFrom(this.stateSelections.contentReferences$, this.stateSelections.carouselDimensions$)
			),
			(state, [slidingDirection, contentReferences, dimensions]) => {
				const { currentSlideIndex, loopRef } = state;
				const { carouselItemsCount, itemsCountPerSlide, slidesCount } = dimensions;
				const { contentItemsRef } = contentReferences;
				if (carouselItemsCount <= itemsCountPerSlide) return;

				this.unsetLoopOnAutoplay(loopRef);
				void this.slide(slidingDirection, currentSlideIndex, slidesCount);

				return { contentItemsRef };
			}
		);
	}

	private holdStates() {
		// initialize carousel
		this.componentState.hold(
			combineLatest([this.stateSelections.viewReferences$, this.stateSelections.carouselDimensions$]),
			([viewReferences, dimensions]) => {
				const { viewItemsRef, itemsSlidingWindow, itemsContainer } = viewReferences;
				const { hasBorders, itemGutter, itemWidth, slideGutter, slideWidth, itemsContainerWidth } = dimensions;

				this.initItemsWidth(viewItemsRef, itemWidth);
				this.initItemsGutter(viewItemsRef, itemGutter, hasBorders);
				this.initSlidingWindowWidth(itemsSlidingWindow, slideGutter, slideWidth);
				this.initItemsContainerWidth(itemsContainer, itemsContainerWidth);

				this.carouselInitialized$.next();
			}
		);
	}

	// {+} @public methods

	// ↓↓↓ play animation

	slide(direction: SlidingDirection, currentSlideIndex: number, slidesCount: number): Promise<void> {
		let nextSlideIndex: number;

		if (direction === SlidingDirection.next) {
			nextSlideIndex = (currentSlideIndex + 1) % slidesCount;
		} else if (direction === SlidingDirection.previous) {
			nextSlideIndex = (currentSlideIndex - 1 + slidesCount) % slidesCount;
		}

		return this.slideTo(nextSlideIndex);
	}

	slideTo(index: number, metadata?: { duration?: number }): Promise<void> {
		const { duration } = metadata ?? {};

		this.componentState.set({ currentSlideIndex: index });
		return new Promise<void>(resolve => this.playSlidingAnimation({ duration }).onDone(() => resolve()));
	}

	playSlidingAnimation(metadata: { duration?: number } = {}): AnimationPlayer {
		const { duration } = metadata;
		const slidingOffset =
			this.componentState.get().currentSlideIndex * this.componentState.get().carouselDimensions.slidingWidth;

		const animationFactory = this.carouselSlideAnimation.build({
			type: AnimationMetadataType.Style,
			offset: slidingOffset,
			duration: isNumeric(duration) ? duration : this.componentState.get().slidingDuration,
		});

		return this.carouselSlideAnimation.play(animationFactory, this.componentState.get().itemsContainer.nativeElement);
	}

	// async slideOld(contentItemsRef: QueryList<TemplateRef<any>>, direction: SlidingDirection) {
	// 	if (direction === SlidingDirection.next) {
	// 		await this.slideTo(direction);
	// 		this.circleCarouselSlides(contentItemsRef, SlidingDirection.next);
	// 	} else if (direction === SlidingDirection.previous) {
	// 		this.circleCarouselSlides(contentItemsRef, SlidingDirection.previous);
	// 		await this.slideTo(direction);
	// 	}
	// }

	// private circleCarouselSlides(contentItemsRef: QueryList<TemplateRef<any>>, direction: SlidingDirection) {
	// 	const itemsTmplArray = contentItemsRef.toArray();

	// 	if (direction === SlidingDirection.next) {
	// 		itemsTmplArray.push(itemsTmplArray.shift());
	// 		this.slideTo(SlidingDirection.previous, { duration: 0 });
	// 	} else if (direction === SlidingDirection.previous) {
	// 		itemsTmplArray.unshift(itemsTmplArray.pop());
	// 		this.slideTo(SlidingDirection.next, { duration: 0 });
	// 	}

	// 	contentItemsRef.reset(itemsTmplArray);
	// 	this.componentState.set({ contentItemsRef });
	// }

	// {-} @private methods

	// ↓↓↓ initialize dimensions

	private initItemsWidth(viewItemsRef: QueryList<ElementRef>, itemWidth: number) {
		viewItemsRef.forEach((itemElRef: ElementRef) => {
			this.renderer.setStyle(itemElRef.nativeElement, 'display', 'flex');
			this.renderer.setStyle(itemElRef.nativeElement, 'minWidth', `${itemWidth}px`);
			this.renderer.setStyle(itemElRef.nativeElement, 'maxWidth', `${itemWidth}px`);
		});
	}

	private initItemsGutter(viewItemsRef: QueryList<ElementRef>, itemGutter: number, hasBorders?: boolean) {
		viewItemsRef.forEach((itemElRef: ElementRef) => {
			if (hasBorders) {
				this.renderer.setStyle(itemElRef.nativeElement, 'marginLeft', `${itemGutter / 2}px`);
				this.renderer.setStyle(itemElRef.nativeElement, 'marginRight', `${itemGutter / 2}px`);
			} else {
				if (itemElRef !== viewItemsRef.first) {
					this.renderer.setStyle(itemElRef.nativeElement, 'marginLeft', `${itemGutter / 2}px`);
				}
				if (itemElRef !== viewItemsRef.last) {
					this.renderer.setStyle(itemElRef.nativeElement, 'marginRight', `${itemGutter / 2}px`);
				}
			}
		});
	}

	private initSlidingWindowWidth(itemsSlidingWindow: ElementRef<HTMLElement>, slideGutter: number, slideWidth: number) {
		this.renderer.setStyle(itemsSlidingWindow.nativeElement, 'width', `${slideWidth + slideGutter}px`);
	}

	private initItemsContainerWidth(itemsContainer: ElementRef<HTMLElement>, itemsContainerWidth: number) {
		this.renderer.setStyle(itemsContainer.nativeElement, 'width', `${itemsContainerWidth}px`);
	}

	// ↓↓↓ play loop animation

	// @todo
	// private setLoopOnAutoplay(loop: boolean, loopInterval: number, loopDelay: number, prevLoopRef?: TimerRef) {
	// 	if (!loop) return;
	// 	this.unsetLoopOnAutoplay(prevLoopRef);
	// 	setTimeout(() => {
	// 		const intervalRef = setInterval(
	// 			() => this.slide(this.componentState.get('contentItemsRef'), SlidingDirection.next),
	// 			loopInterval
	// 		);
	// 		this.componentState.set({ loopRef: intervalRef });
	// 	}, loopDelay);
	// }

	// @todo
	private unsetLoopOnAutoplay(loopRef: TimerRef) {
		if (isNil(loopRef)) return;

		clearInterval(loopRef);
		this.componentState.set({ loopRef: null });
	}
}
