import { NgModule } from '@angular/core';
import { RxState } from '@rx-angular/state';

import { HTMLElementHelper } from './html-element';
import { MediaQueryHelper } from './media-query';

@NgModule({
	providers: [RxState, HTMLElementHelper, MediaQueryHelper],
})
export class YslmHelpersModule {}
