<div *ngIf="viewModel$ | async as $" class="trip trip__page" padding="page">
	<!-- page segment -->
	<div class="page__headline">
		<h1 class="u-headline-primary" text-align="center">{{ $.title }} {{ $.type }}</h1>
	</div>

	<!-- page segment -->
	<div *ngIf="$.images | isnt:'empty'" class="trip__media" margin="t|b:3x">
		<div class="media__images">
			<yslm-carousel [config]="$.carouselConfig">
				<ng-container *ngFor="let imageUrl of $.images">
					<img *carouselItem src="{{ imageUrl }}" alt="" />
				</ng-container>
			</yslm-carousel>
		</div>
	</div>

	<!-- page segment -->
	<div class="trip__options">
		<div *ngIf="$.isThereAnyTypedOption" margin="l:2x, b:3x">
			<button
				*ngFor="let metadata of  $.optionsTypesMetadata"
				attr.theme="{{ metadata.theme }}"
				animation="active hovered raised ripple"
				margin="r:x1:!last"
				(click)="handleOptionsTypeSelect(metadata.type)"
			>
				{{ metadata.type | titlecase }} {{ $.title }} {{ $.type }}
			</button>
		</div>

		<yslm-accordion [openedIndex]="0" [scrollIntoOpened]="true">
			<yslm-expansion
				*ngFor="let option of $.options"
				class="trip__collapsable trip__option"
				margin="b:2x:!last"
				[theme]="$.optionsTypesMetadata | find:{ type: $.selectedOptionsType } | get:'theme' | fallback:'neutral'"
			>
				<yslm-expansion-header>
					<div *ngIf="option.trajectories | isnt:'empty'">
						<span class="u-font-bold">Trajectory:</span>
						{{ option.trajectories | join:' / OR / ' }}
					</div>
					<div *ngIf="option.duration">
						<span class="u-font-bold">Duration:</span>
						{{ option.duration }}
					</div>
				</yslm-expansion-header>

				<yslm-expansion-content>
					<div class="trip__option" margin="l:1x, r:1x">
						<!-- Program -->
						<div class="option__programs" *ngIf="option.programs | isnt:'empty'">
							<div class="u-heading">Program:</div>
							<div *ngFor="let program of option.programs" class="trip__program" margin="b:1x:!last, l:1x">
								<div *ngIf="program.dayNumber | ofType:'number'" class="u-font-bold">
									• Day {{ program.dayNumber }}:
								</div>
								<div class="trip__stops-categories">
									<trip-stops-category
										*ngFor="let stopsCategory of program.stopsCategories"
										[selectedOptionsType]="$.selectedOptionsType"
										[stopsCategory]="stopsCategory"
										margin="b:1xn:!last"
									></trip-stops-category>
								</div>
							</div>
						</div>

						<!-- Pricing -->
						<div class="option__pricing" margin="t:2x">
							<div class="pricing__heading u-heading">Pricing:</div>
							<trip-pricing
								[tripType]="$.type"
								[selectedOptionsType]="$.selectedOptionsType"
								[pricings]="option.pricings"
							></trip-pricing>
						</div>

						<!-- Clusivity -->
						<div class="option__clusivity" margin="t:1x">
							<div *ngIf="option.included | isnt:'empty'">
								<svg-use name="plus-circle" margin="r:x2"></svg-use>
								<span class="u-font-bold">Included in the price:</span>
								{{ option.included | join: ', ' }}
							</div>

							<div *ngIf="option.excluded  | isnt:'empty'" margin="t:x3">
								<svg-use name="minus-circle" margin="r:x2"></svg-use>
								<span class="u-font-bold">Excluded from the price:</span>
								{{ option.excluded | join: ', ' }}
							</div>
						</div>
					</div>
				</yslm-expansion-content>
			</yslm-expansion>
		</yslm-accordion>

		<!-- <button theme="action" animation="raised ripple" stretch margin="t:3x"> -->
		<button theme="action" stretch margin="t:3x">
			For a different trajectory, contact us, we will send you a quotation within a few hours
		</button>
	</div>
</div>
