import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RxState, selectSlice } from '@rx-angular/state';
import { map } from 'rxjs/operators';

import { YslmCarouselConfig } from '@yslm/common/components';
import { Trip } from 'src/app/core/models';
import { AppState } from 'src/app/core/store';
import { TripActions, TripSelectors } from 'src/app/core/store/trips';

// @ Component state
interface ComponentState {
	// – configs
	carouselConfig: YslmCarouselConfig;

	// – store states
	trips: Trip[];
}

@Component({
	selector: 'home-page',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	providers: [RxState],
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePage implements OnInit {
	// •) component state

	private readonly INITIAL_STATE: ComponentState = {
		// – configs
		carouselConfig: {
			itemsCountPerSlideBreakpoints: {
				mobile: 1,
				tabletPortrait: 1,
				tabletLandscape: 2,
				desktopSmall: 3,
				desktopLarge: 3,
			},
			itemGutterBreakpoints: { all: 40 },
			loopInterval: 15000,
			hasBorders: true,
		},

		// – store states
		trips: undefined,
	};

	// • view states

	readonly viewModel$ = this.componentState.select(
		selectSlice(['carouselConfig', 'trips']),
		map(({ carouselConfig, trips }) => ({
			carouselConfig,
			trips: trips.map(trip => ({
				slug: trip.slug,
				type: trip.type,
				title: trip.title,
				detail: trip.detail,
				coverImage: trip.coverImage,
				minFare: trip.minFare(),
			})),
		}))
	);

	// {*} Initialization

	constructor(private readonly store: Store<AppState>, private readonly componentState: RxState<ComponentState>) {
		this.componentState.set(this.INITIAL_STATE);
	}

	ngOnInit(): void {
		// – dispatch initial actions
		this.store.dispatch(TripActions.getAllForHomePage());

		// – connect & hold streams
		this.connectStates();
	}

	private connectStates() {
		// – connect from the store
		this.componentState.connect('trips', this.store.select(TripSelectors.getTrips));
	}
}
