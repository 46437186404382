import { Pipe, PipeTransform } from '@angular/core';

import { signed } from '@yslm/utility/number';

@Pipe({ name: 'signed' })
export class SignedPipe implements PipeTransform {
	transform(...args: Parameters<typeof signed>): string {
		return signed(...args);
	}
}
