import { Pipe, PipeTransform } from '@angular/core';
import { Observable, isObservable } from 'rxjs';
import { delay } from 'rxjs/operators';

import { isNumber, isntUndefined } from '@yslm/utility';

@Pipe({
	name: 'delay',
})
export class DelayPipe implements PipeTransform {
	transform<T>(observable: Observable<T>, milliseconds?: number): Observable<T> {
		if (!isObservable(observable)) {
			throw new TypeError('Expected `observable` arg to be of type Observable');
		}

		if (isntUndefined(milliseconds) && !isNumber(milliseconds)) {
			throw new TypeError('Expected `milliseconds` arg to be a number');
		}

		return observable.pipe(delay(milliseconds ?? 0));
	}
}
