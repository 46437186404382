<div *ngIf="viewModel$ | async as $" class="expansion__component">
	<button
		class="expansion__header"
		attr.theme="{{ $.styles.theme }}"
		text-align="left"
		stretch-x
		animation="hovered raised"
		(click)="toggleClicked.emit()"
	>
		<ng-content select="yslm-expansion-header"></ng-content>
	</button>

	<div
		class="expansion__content"
		[@contentToggle]="$.animations.contentToggleState"
		(@contentToggle.done)="toggleAnimationDone.emit($event)"
	>
		<ng-content select="yslm-expansion-content"></ng-content>
	</div>
</div>
