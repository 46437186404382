import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { YslmAnimationsModule } from '@yslm/animations';
import { YslmCommonModule } from '@yslm/common';
import { YslmHelpersModule } from '@yslm/helpers';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppContext } from './app.context';
import { AppCommonModule } from './common/app-common.module';
import { AppContentModule } from './content/app-content.module';
import { AppCoreModule } from './core/app-core.module';

@NgModule({
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		HttpClientModule,

		YslmAnimationsModule,
		YslmCommonModule,
		YslmHelpersModule,

		AppRoutingModule,
		AppCommonModule,
		AppContentModule,
		AppCoreModule,
	],
	declarations: [AppComponent],
	providers: [AppContext],
	bootstrap: [AppComponent],
})
export class AppModule {}
