import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { YslmComponentsModule } from './components/yslm-components.module';
import { YslmDirectivesModule } from './directives/yslm-directives.module';
import { YslmPipesModule } from './pipes/yslm-pipes.module';

const declarations = [YslmComponentsModule, YslmDirectivesModule, YslmPipesModule];

@NgModule({
	imports: [CommonModule, ...declarations],
	exports: [...declarations],
})
export class YslmCommonModule {}
