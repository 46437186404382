import { get as ldashGet } from 'lodash-es';

import { isObject, isUndefined } from '@yslm/utility';

/**
 *
 *
 * @param record
 * @param path
 * @param fallbackValue
 * @returns
 */
export function get<T extends Record<string, any>>(record: T, path: string | string[], fallbackValue?: any) {
	if (isUndefined(record)) {
		return undefined;
	}

	if (!isObject(record)) {
		throw new TypeError("'record' is not an object.");
	}

	return ldashGet(record, path, fallbackValue);
}
