import { Component } from '@angular/core';

@Component({
	selector: 'i-audio-and-video',
	template: `
		<svg>
			<symbol id="play">
				<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
					<path
						d="M2320 5114 c-539 -64 -976 -245 -1380 -571 -308 -248 -588 -622 -744
					-995 -80 -192 -135 -385 -173 -613 -26 -158 -26 -592 0 -750 79 -476 258 -878
					552 -1243 85 -106 261 -282 367 -367 365 -294 767 -473 1243 -552 157 -26 592
					-26 750 0 561 93 1044 337 1431 724 394 394 638 874 731 1438 26 158 26 593 0
					750 -67 400 -189 716 -402 1035 -110 164 -193 267 -326 399 -327 328 -727 558
					-1168 672 -220 57 -334 71 -601 74 -135 2 -261 1 -280 -1z m480 -525 c361 -44
					691 -177 988 -398 112 -83 320 -291 403 -403 181 -244 311 -526 367 -803 64
					-310 56 -649 -23 -950 -160 -615 -631 -1140 -1235 -1379 -359 -141 -773 -175
					-1165 -94 -277 56 -559 186 -803 367 -112 83 -320 291 -403 403 -224 302 -356
					633 -400 1002 -15 128 -6 452 16 569 68 370 216 688 449 964 346 412 806 659
					1351 726 69 9 377 6 455 -4z"
					/>
					<path
						d="M2040 2560 c0 -991 2 -1151 14 -1147 23 9 1526 1139 1526 1147 0 8
					-1503 1138 -1526 1147 -12 4 -14 -156 -14 -1147z"
					/>
				</g>
			</symbol>
		</svg>
	`,
})
export class AudioAndVideoIcons {}
