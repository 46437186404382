import { min } from '@yslm/utility';

export enum TripType {
	excursion = 'excursion',
	tour = 'tour',
	transfer = 'transfer',
}
export enum TripOptionType {
	private = 'private',
	shared = 'shared',
}

export interface TripOptionPrice {
	persInterval: { min: number; max: number };
	fare: number;
	nightFare?: number;
}
export interface TripOptionSharedPrice {
	persThreshold: number; // people count threshold beyond which the price is reduced
	fare: number;
	extras: TripOptionPriceSupplements; // extra amounts due if people count threshold is not reached
}
export interface TripOptionPriceSupplements {
	remark: string;
	prices: TripOptionPrice[];
}

export interface TripPricing {
	// @Unique(['tripOption', 'type'])
	// tripOption: TripOption; // FK
	type?: TripOptionType; // equals `null` for untyped TripPricing
	perPers: boolean; // should the fees be applied to each person

	// @if (type === null || type === TripOptionType.private)
	prices?: TripOptionPrice[];
	// @if (type === TripOptionType.shared)
	sharedPrice?: TripOptionSharedPrice;


	additionals: TripOptionPriceSupplements[]; // additional amounts to pay for additional services
}

// • Trip programs

interface TripStopMetadata {
	leftMargin?: boolean;
	numbered?: boolean;
	separator?: string;
	linebreak?: boolean;
}
export interface TripStop {
	// @Unique(['tripStopsCategory', 'type', 'rank'])
	// @ManyToOne(() => TripStopsCategory)
	rank: number;
	type?: TripOptionType;
	label: string;
	detail?: string;
	remark?: string;
	subStops?: string;
	subStopsMetadata?: TripStopMetadata;
}
export interface TripStopsCategory {
	// @Unique(['tripProgram', 'label'])
	// @ManyToOne(() => TripProgram)
	label?: string;
	detail?: string;
	remark?: string;
	stops: TripStop[];
	stopsMetadata?: TripStopMetadata;
}
export interface TripProgram {
	// @Unique(['tripOption', 'dayNumber'])
	// @ManyToOne(() => TripOption)
	dayNumber?: number;
	stopsCategories: TripStopsCategory[];
}

// • Trip options

export interface TripOption {
	// trip: Trip; // @ManyToOne(() => Trip)
	isTyped: boolean;
	trajectories: string[][];
	duration: string;
	programs: TripProgram[];
	pricings: TripPricing[];
	included: string[];
	excluded: string[];
}

// • Trips

export class Trip {
	id: number;
	type: TripType;
	slug: string;
	title: string;
	detail: string;
	coverImage: string; // @todo { src: string, alt: string }
	images: string[]; // @todo array<{ src: string, alt: string }>
	video?: string;
	options: TripOption[];

	constructor(trip: Trip) {
		Object.assign(this, trip);
	}

	/** computes trip options' minimum fare */
	minFare(): number {
		const optionsFares = this.options.flatMap(option => {
			const sharedPricing = option.pricings.find(pricing => pricing.type === TripOptionType.shared);

			if (sharedPricing) {
				return sharedPricing.sharedPrice.fare;
			}

			return option.pricings.flatMap(pricing => pricing.prices.map(price => price.fare));
		});

		return min(optionsFares);
	}
}
