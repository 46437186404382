import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RxState, selectSlice } from '@rx-angular/state';

// @ Component state
interface ComponentState {
	slug: string;
	title: string;
	detail: string;
	coverImage: string;
	minFare: number;
}

@Component({
	selector: 'trip-card',
	templateUrl: 'trip-card.component.html',
	styleUrls: ['trip-card.component.scss'],
	providers: [RxState],
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripCardComponent {
	// •) component state

	readonly viewModel$ = this.componentState.select(selectSlice(['coverImage', 'title', 'detail', 'slug', 'minFare']));

	// •) outputs & inputs

	/** @required */
	@Input() set slug(value: string) {
		this.componentState.set({ slug: value });
	}

	/** @required */
	@Input() set title(value: string) {
		this.componentState.set({ title: value });
	}

	/** @required */
	@Input() set detail(value: string) {
		this.componentState.set({ detail: value });
	}

	/** @required */
	@Input() set coverImage(value: string) {
		this.componentState.set({ coverImage: value });
	}

	/** @required */
	@Input() set minFare(value: number) {
		this.componentState.set({ minFare: value });
	}

	// {*} Initialization

	constructor(private readonly componentState: RxState<ComponentState>) {}
}
