import {
	AnimationFactory,
	AnimationMetadata,
	AnimationMetadataType,
	AnimationPlayer,
	animate,
	style,
	trigger,
	state,
	transition,
} from '@angular/animations';
import { Injectable } from '@angular/core';

import { AnimationHelper, CustomAnimation } from '@yslm/animations';

type AnimationFactoryParams = AnimationMetadata & {
	type: AnimationMetadataType.Style;
	offset: number;
	duration?: number;
};

@Injectable()
export class CarouselSlideAnimation implements CustomAnimation {
	name = 'slide';

	constructor(public animationHelper: AnimationHelper) {}

	build(factoryParams: AnimationFactoryParams): AnimationFactory {
		const animation = animate(
			`${factoryParams.duration ?? 750}ms ease-in`,
			style({ transform: `translateX(-${factoryParams.offset}px)` })
		);
		return this.animationHelper.build(animation);
	}

	play(animationFactory: AnimationFactory, animationNodeEl: HTMLElement): AnimationPlayer {
		return this.animationHelper.play(animationFactory, animationNodeEl);
	}
}

export const carouselTriggers = {
	slidingWindowTranslate: trigger('slidingWindowTranslate', [
		transition(':enter', style({ transform: 'translateX(0)' })),

		transition('* => next', animate('{{ duration }}ms ease-in', style({ transform: 'translateX(-{{offset}}px)' })), {
			params: { duration: 750 },
		}),
		transition('* => previous', animate('{{ duration }}ms ease-in', style({ transform: 'translateX({{offset}}px)' })), {
			params: { duration: 750 },
		}),
	]),
};
