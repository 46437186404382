import { DeviceBreakpointBoundaries } from './media-query.types';

export const deviceBreakpointKeys = ['mobile', 'tabletPortrait', 'tabletLandscape', 'tablet', 'desktopSmall', 'desktopLarge', 'desktop'] as const;
export const deviceBreakpointBoundaries: DeviceBreakpointBoundaries = {
	// – small devices
	mobile: {
		min: '0',
		max: '35.99em',
	},

	// – medium devices
	tabletPortrait: {
		min: '36em', // `576px / root(font-size : 16px)`
		max: '47.99em',
	},
	tabletLandscape: {
		min: '48em', // `768px / root(font-size : 16px)`
		max: '61.99em',
	},
	tablet: {
		min: '36em', // `576px / root(font-size : 16px)`
		max: '61.99em',
	},

	// – large devices
	desktopSmall: {
		min: '62em', // `992px / root(font-size : 16px)`
		max: '74.99em',
	},
	desktopLarge: {
		min: '75em', // `1200px / root(font-size : 16px)`
		max: '999em',
	},
	desktop: {
		min: '62em', // `992px / root(font-size : 16px)`
		max: '999em',
	},
};
