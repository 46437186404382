import { ElementRef, Injectable, QueryList, TemplateRef } from '@angular/core';
import { RxState, selectSlice } from '@rx-angular/state';
import { Observable } from 'rxjs';

import { RxStatefulService, TimerRef } from '@yslm/common/typings';
import { ValueByDeviceBreakpoint } from '@yslm/helpers';

import { CarouselDimensions } from './carousel.model';

// @ Component inputs
export interface ComponentInputs {
	itemsCountPerSlideBreakpoints?: ValueByDeviceBreakpoint;
	itemGutterBreakpoints?: ValueByDeviceBreakpoint;
	slidingItemsCount?: number;
	slidingDuration?: number;
	hasBorders?: boolean;
	hasControls?: boolean;
	loop?: boolean;
	loopInterval?: number;
	loopDelay?: number;
}

// @ Component state
export interface ComponentState extends ComponentInputs {
	// – properties
	itemsCountPerSlide: number;
	itemGutter: number;
	currentSlideIndex: number;
	carouselDimensions: CarouselDimensions;
	loopRef?: TimerRef;

	// – view children & content projection
	componentContainer: ElementRef<HTMLElement>;
	itemsSlidingWindow: ElementRef<HTMLElement>;
	itemsContainer: ElementRef<HTMLElement>;
	viewItemsRef: QueryList<ElementRef>;
	contentItemsRef: QueryList<TemplateRef<any>>;
}
export interface ViewModel {
	contentItemsRef: QueryList<TemplateRef<any>>;
	hasControls?: boolean;
}

@Injectable()
export class ComponentStateManagement extends RxState<ComponentState> implements RxStatefulService<ComponentState> {
	readonly state$ = this.select();
	readonly stateSelections = {
		breakpointProps$: this.state$.pipe(selectSlice(['itemsCountPerSlideBreakpoints', 'itemGutterBreakpoints'])),
		carouselConfig$: this.state$.pipe(
			selectSlice([
				'itemsCountPerSlide',
				'itemGutter',
				'slidingItemsCount',
				'slidingDuration',
				'hasBorders',
				'hasControls',
				'loop',
				'loopInterval',
				'loopDelay',
			])
		),
		carouselDimensions$: this.select('carouselDimensions'),
		hasBorders$: this.select('hasBorders'),

		// view children & content projection
		viewReferences$: this.state$.pipe(
			selectSlice(['componentContainer', 'itemsSlidingWindow', 'itemsContainer', 'viewItemsRef'])
		),
		contentReferences$: this.state$.pipe(selectSlice(['contentItemsRef'])),
	};

	readonly viewModel$: Observable<ViewModel> = this.state$.pipe(selectSlice(['contentItemsRef', 'hasControls']));
}
