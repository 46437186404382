import { Pipe, PipeTransform } from '@angular/core';

import * as TypesUtility from '@yslm/utility/type';

@Pipe({
	name: 'ofType',
})
export class OfTypePipe implements PipeTransform {
	transform(param: unknown, type: string): boolean {
		if (!TypesUtility.isString(type)) return true;

		switch (type) {
			case 'boolean':
				return TypesUtility.isBoolean(param);
			case 'number':
				return TypesUtility.isNumber(param);
			case 'string':
				return TypesUtility.isString(param);
			case 'symbol':
				return TypesUtility.isSymbol(param);
			case 'function':
				return TypesUtility.isFunction(param);
			case 'array':
				return TypesUtility.isArray(param);
			case 'object':
				return TypesUtility.isObject(param);
			default:
				return false;
		}
	}
}
