import { Component, Input } from '@angular/core';

@Component({
	selector: 'svg-use',
	templateUrl: './svg-use.component.html',
	styleUrls: ['./svg-use.component.scss'],
})
export class SvgUseComponent {
	@Input() name: string;

	get absUrl() {
		return window.location.href;
	}
}
