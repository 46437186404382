<div
	*ngIf="viewModel$ | async as $"
	class="carousel carousel__component"
	[ngClass]="{ 'has-controls': $.hasControls }"
	#componentContainer
>
	<div class="items-sliding-window" #itemsSlidingWindow>
		<div class="items-container" #itemsContainer>
			<div #viewItemRef *ngFor="let itemTemplateRef of $.contentItemsRef">
				<ng-container *ngTemplateOutlet="itemTemplateRef"></ng-container>
			</div>
		</div>
	</div>

	<div *ngIf="$.hasControls" class="carousel__controls">
		<button control control-prev (click)="slideClickedEmitter$.next(SlidingDirection.previous)">
			<svg-use name="cheveron-left"></svg-use>
		</button>
		<button control control-next (click)="slideClickedEmitter$.next(SlidingDirection.next)">
			<svg-use name="cheveron-right"></svg-use>
		</button>
	</div>
</div>
