import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Trip } from 'src/app/core/models';

/** API endpoints */
const endpoints = {
	tripsUri: 'api/data/trips.json',
};

@Injectable()
export class TripsApiService {
	private readonly endpoindsCache = new Map<string, any>();

	constructor(private http: HttpClient) {}

	getTrips(): Observable<Trip[]> {
		const tripsFromCache = this.endpoindsCache.get(endpoints.tripsUri);

		if (tripsFromCache) {
			return of(tripsFromCache);
		}

		return this.http.get<Trip[]>(endpoints.tripsUri).pipe(
			map(trips => trips.map(trip => new Trip(trip))),
			tap(trips => this.endpoindsCache.set(endpoints.tripsUri, trips))
		);
	}

	getTripById(id: number): Observable<Trip> {
		return this.getTrips().pipe(map((trips: Trip[]) => trips.find(trip => trip.id === id)));
	}

	getTripBySlug(slug: string): Observable<Trip> {
		return this.getTrips().pipe(map((trips: Trip[]) => trips.find(trip => trip.slug === slug)));
	}
}
