<ng-container *ngIf="viewModel$ | async as $">
	<!-- @todo viewType -->
	<!-- <div class="app__container {{ $.currentRoutePath ? $.currentRoutePath + '__context' : 'home__context' }}"> -->
	<div class="app__container">
		<header-layout></header-layout>
		<router-outlet class="app__content"></router-outlet>
		<footer-layout></footer-layout>
	</div>

	<svg-definitions></svg-definitions>
</ng-container>
