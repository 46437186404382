<div *ngIf="viewModel$ | async as $" class="trip-card trip-card__component">
	<yslm-card-item
		[imgSrc]="$.coverImage"
		[title]="$.title"
		[detail]="$.detail"
		[btnLink]="['/trips', $.slug]"
		btnText="Consult the details"
	>
		<div class="trip-card__price" margin="b:1x">
			<svg-use name="euro" class="icon" margin="r:1x"></svg-use>
			<span>From {{ $.minFare }} € / pers</span>
		</div>
	</yslm-card-item>
</div>
